import { merge, assign, omit, map, filter } from 'lodash-es';
import {
  FETCH_STUDENTEXAM_SUCCESS,
  FETCH_EXAM_SUCCESS,
  POST_EXAMQUESTION_START,
  POST_EXAMQUESTION_SUCCESS,
  POST_EXAMQUESTION_ERROR,
  UPDATE_EXAMQUESTION_START,
  UPDATE_EXAMQUESTION_SUCCESS,
  UPDATE_EXAMQUESTION_ERROR,
  DELETE_EXAMQUESTION_START,
  DELETE_EXAMQUESTION_SUCCESS,
  DELETE_EXAMQUESTION_ERROR,
  DELETE_STUDENT_EXAM_ANSWER_SUCCESS,
  DELETE_EXAMANSWER_SUCCESS,
  POST_STUDENT_EXAM_ANSWER_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function examQuestions(state = initialState.examQuestions, action) {
  switch (action.type) {
    case POST_EXAMQUESTION_START:
    case UPDATE_EXAMQUESTION_START:
    case DELETE_EXAMQUESTION_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case DELETE_EXAMQUESTION_ERROR:
    case POST_EXAMQUESTION_ERROR:
    case UPDATE_EXAMQUESTION_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_EXAM_SUCCESS:
    case FETCH_STUDENTEXAM_SUCCESS:
    case UPDATE_EXAMQUESTION_SUCCESS:
    case POST_EXAMQUESTION_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.examQuestions,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case POST_STUDENT_EXAM_ANSWER_SUCCESS:
      return assign({}, state, {
        data: {
          ...state.data,
          [action.data.examQuestionId]: {
            ...state.data[action.data.examQuestionId],
            studentExamAnswers: [
              ...state.data[action.data.examQuestionId].studentExamAnswers,
              ...map(action.data.studentExamAnswers, 'id'),
            ],
          },
        },
      });

    case DELETE_EXAMANSWER_SUCCESS:
      return assign({}, state, {
        data: {
          ...state.data,
          [action.data.examQuestionId]: {
            ...state.data[action.data.examQuestionId],
            examAnswers: filter(
              state.data[action.data.examQuestionId].examAnswers,
              (x) => x !== action.data.answerId,
            ),
          },
        },
      });

    case DELETE_STUDENT_EXAM_ANSWER_SUCCESS:
      return assign({}, state, {
        data: {
          ...state.data,
          [action.data.examQuestionId]: {
            ...state.data[action.data.examQuestionId],
            studentExamAnswers: filter(
              state.data[action.data.examQuestionId].studentExamAnswers,
              (x) => x !== action.data.studentExamAnswer.id,
            ),
          },
        },
      });
    case DELETE_EXAMQUESTION_SUCCESS:
      return {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      };

    default:
      return state;
  }
}

export default examQuestions;
