import { merge, omit, assign, map, without, mergeWith } from 'lodash-es';
import { normalize } from 'normalizr';
import * as schema from '../../api/mappers/schema';
import {
  FETCH_SCHOOLS_START,
  FETCH_SCHOOLS_SUCCESS,
  FETCH_SCHOOLS_ERROR,
  FETCH_SCHOOL_BY_ID_START,
  FETCH_SCHOOL_BY_ID_SUCCESS,
  FETCH_SCHOOL_BY_ID_ERROR,
  DELETE_SCHOOL_START,
  DELETE_SCHOOL_SUCCESS,
  DELETE_SCHOOL_ERROR,
  LOGIN_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_STUDENT_BY_ID_SUCCESS,
  ADD_CLASSGROUP_SUCCESS,
  ADD_SCHOOL_START,
  ADD_SCHOOL_SUCCESS,
  ADD_SCHOOL_ERROR,
  FETCH_STUDENTS_BY_SCHOOL_SUCCESS,
  FETCH_TEACHERS_SUCCESS,
  FETCH_TEACHER_BY_ID_SUCCESS,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_CLASSGROUP_SUCCESS,
  DELETE_TEACHER_SUCCESS,
  FETCH_CLASSGROUPS_SUCCESS,
  UPDATE_SCHOOL_START,
  UPDATE_SCHOOL_SUCCESS,
  UPDATE_SCHOOL_ERROR,
  DELETE_SCHOOLADMIN_SUCCESS,
  ADD_SCHOOLADMIN_SUCCESS,
} from '../types';

import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function schools(state = initialState.schools, action) {
  switch (action.type) {
    case FETCH_SCHOOLS_START:
    case FETCH_SCHOOL_BY_ID_START:
    case DELETE_SCHOOL_START:
    case ADD_SCHOOL_START:
    case UPDATE_SCHOOL_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_SCHOOLS_ERROR:
    case FETCH_SCHOOL_BY_ID_ERROR:
    case DELETE_SCHOOL_ERROR:
    case ADD_SCHOOL_ERROR:
    case UPDATE_SCHOOL_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case DELETE_SCHOOL_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case FETCH_SCHOOLS_SUCCESS:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case FETCH_STUDENT_BY_ID_SUCCESS:
    case ADD_CLASSGROUP_SUCCESS:
    case ADD_SCHOOL_SUCCESS:
    case FETCH_STUDENTS_BY_SCHOOL_SUCCESS:
    case FETCH_TEACHER_BY_ID_SUCCESS:
    case UPDATE_TEACHER_SUCCESS:
    case FETCH_SCHOOL_BY_ID_SUCCESS:
    case FETCH_CLASSGROUPS_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.schools,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_TEACHERS_SUCCESS:
      return mergeWith(
        {},
        state,
        {
          data: action.data.entities.schools,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
        (a, b) => (b === null ? a : undefined),
      );

    case ADD_SCHOOLADMIN_SUCCESS: {
      const newData = state.data;
      newData[action.data.id].schoolAdmins.push(action.data.result);
      return merge({}, state, {
        data: newData,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case DELETE_SCHOOLADMIN_SUCCESS: {
      const newData = state.data;
      newData[action.data.schoolId].schoolAdmins = without(
        newData[action.data.schoolId].schoolAdmins,
        action.data.schoolAdminId,
      );
      return merge({}, state, {
        data: newData,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case LOGIN_SUCCES:
    case UPDATE_CLASSGROUP_SUCCESS:
    case UPDATE_SCHOOL_SUCCESS: {
      return assign({}, state, {
        data: action.data.entities.schools,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case GET_LOGGEDIN_USER_FROM_JWT_SUCCES: {
      if (action.data.entities.schools) {
        return assign({}, state, {
          data: action.data.entities.schools,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return assign({}, state, {
        data: {},
        state: stateTypes.INITIAL_DATA,
        error: {},
      });
    }

    case DELETE_TEACHER_SUCCESS: {
      if (action.data.schoolId) {
        const newState = normalize(
          map(state.data, (school) => {
            if (school.id === action.data.schoolId) {
              return {
                ...school,
                schoolAdmins: without(school.schoolAdmins, action.data.teacherId),
              };
            }
            return school;
          }),
          [schema.school],
        );
        return assign(
          {},
          {
            data: newState.entities.schools,
            state: stateTypes.LOADED_SUCCESSFUL,
            error: action.error,
          },
        );
      }
      return state;
    }

    default:
      return state;
  }
}

export default schools;
