import { merge, omit, assign, head, values } from 'lodash-es';
import {
  FETCH_STUDENTS_BY_SCHOOL_START,
  FETCH_STUDENTS_BY_SCHOOL_SUCCESS,
  FETCH_STUDENTS_BY_SCHOOL_ERROR,
  DELETE_STUDENT_START,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_ERROR,
  ADD_STUDENT_START,
  ADD_STUDENT_SUCCESS,
  ADD_STUDENT_ERROR,
  UPDATE_STUDENT_START,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_ERROR,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_STUDENT_BY_ID_START,
  FETCH_STUDENT_BY_ID_ERROR,
  FETCH_STUDENT_BY_ID_SUCCESS,
  UPDATE_CLASSGROUP_SUCCESS,
  FETCH_CLASSGROUPS_SUCCESS,
  FETCH_STUDENTEXAM_SUCCESS,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPEXAM_SUCCESS,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
  FETCH_CLASS_EXERCISES_START,
  FETCH_CLASS_EXERCISES_ERROR,
  FETCH_CLASS_EXERCISES_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';
import { SWITCH_ACTIVE_ROLE_SUCCESS } from '../types/admin';

function students(state = initialState.students, action) {
  switch (action.type) {
    case FETCH_STUDENTS_BY_SCHOOL_START:
    case DELETE_STUDENT_START:
    case FETCH_STUDENT_BY_ID_START:
    case ADD_STUDENT_START:
    case UPDATE_STUDENT_START:
    case FETCH_CLASS_EXERCISES_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_STUDENTS_BY_SCHOOL_ERROR:
    case DELETE_STUDENT_ERROR:
    case FETCH_STUDENT_BY_ID_ERROR:
    case UPDATE_STUDENT_ERROR:
    case ADD_STUDENT_ERROR:
    case FETCH_CLASS_EXERCISES_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_STUDENT_BY_ID_SUCCESS:
    case FETCH_STUDENTS_BY_SCHOOL_SUCCESS:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case UPDATE_CLASSGROUP_SUCCESS:
    case FETCH_CLASSGROUPS_SUCCESS:
    case FETCH_STUDENTEXAM_SUCCESS:
    case UPDATE_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_CLASSGROUPEXAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.students,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_CLASS_EXERCISES_SUCCESS:
      return assign({}, state, {
        data: action.data.entities.students,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case ADD_STUDENT_SUCCESS:
      action.data.entities.students[action.data.result].classGroups =
        action.data.selectedClassGroups;
      return merge({}, state, {
        data: action.data.entities.students,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case UPDATE_STUDENT_SUCCESS: {
      return assign({}, state, {
        data: {
          ...state.data,
          [action.data.result]: head(values(action.data.entities.students)),
        },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }
    case SWITCH_ACTIVE_ROLE_SUCCESS: {
      if (action.data.newRole === 'ADMIN') {
        return assign({}, state, {
          data: {},
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return merge({}, state, {
        data: state.data,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case DELETE_STUDENT_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data.userId),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    default:
      return state;
  }
}

export default students;
