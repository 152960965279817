import { merge, omit, assign, pickBy, forEach, concat } from 'lodash-es';
import { normalize } from 'normalizr';
import * as schema from '../../api/mappers/schema';
import {
  FETCH_TEACHERS_START,
  FETCH_TEACHERS_SUCCESS,
  FETCH_TEACHERS_ERROR,
  DELETE_TEACHER_START,
  DELETE_TEACHER_SUCCESS,
  DELETE_TEACHER_ERROR,
  DELETE_CLASSGROUP_SUCCESS,
  ADD_TEACHER_START,
  ADD_TEACHER_SUCCESS,
  ADD_TEACHER_ERROR,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_TEACHER_BY_ID_START,
  FETCH_TEACHER_BY_ID_ERROR,
  FETCH_TEACHER_BY_ID_SUCCESS,
  UPDATE_CLASSGROUP_SUCCESS,
  FETCH_SCHOOLS_SUCCESS,
  FETCH_SCHOOL_BY_ID_SUCCESS,
  FETCH_CLASSGROUPS_SUCCESS,
  CLEAR_CLASSGROUPS,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
  UPDATE_TEACHER_START,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_TEACHER_ERROR,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function teachers(state = initialState.teachers, action) {
  switch (action.type) {
    case FETCH_TEACHERS_START:
    case DELETE_TEACHER_START:
    case FETCH_TEACHER_BY_ID_START:
    case UPDATE_TEACHER_START:
    case ADD_TEACHER_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_TEACHERS_ERROR:
    case DELETE_TEACHER_ERROR:
    case FETCH_TEACHER_BY_ID_ERROR:
    case ADD_TEACHER_ERROR:
    case UPDATE_TEACHER_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_TEACHERS_SUCCESS:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case FETCH_TEACHER_BY_ID_SUCCESS:
    case UPDATE_CLASSGROUP_SUCCESS:
    case FETCH_SCHOOLS_SUCCESS:
    case FETCH_SCHOOL_BY_ID_SUCCESS:
    case FETCH_CLASSGROUPS_SUCCESS:
    case UPDATE_CLASSGROUPPROGRAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.teachers,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case ADD_TEACHER_SUCCESS:
      action.data.entities.teachers[action.data.result].classGroupPrograms =
        action.data.selectedClassGroupPrograms;
      return merge({}, state, {
        data: action.data.entities.teachers,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case UPDATE_TEACHER_SUCCESS:
      return assign(
        {},
        {
          data: {
            ...state.data,
            [action.data.result]: action.data.entities.teachers[action.data.result],
          },
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
      );

    case CLEAR_CLASSGROUPS:
      return assign({}, state, {
        data: {},
        state: stateTypes.INITIAL_DATA,
        error: {},
      });

    case DELETE_CLASSGROUP_SUCCESS: {
      // this reducer checks for each teacher in the store
      // if there are still classGroupPrograms in his object that are
      // linked to the classGroup we just deleted
      let updatedTeachers = [];
      forEach(state.data, (teacher) => {
        const updatedTeacher = assign({}, teacher, {
          classGroupPrograms: pickBy(teacher.classGroupPrograms, (classGroupProgram) => {
            return classGroupProgram !== action.data;
          }),
        });
        updatedTeachers = concat(updatedTeachers, [updatedTeacher]);
      });
      const newData = normalize(updatedTeachers, [schema.teacher]);
      return assign({}, state, {
        data: newData.entities.teachers,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case DELETE_TEACHER_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data.teacherId),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    default:
      return state;
  }
}

export default teachers;
