import jwtDecode from 'jwt-decode';
import { keys, includes, values, head } from 'lodash-es';
import * as userRoles from '../constants/roles';
import { getTokenFromLocalStorage, getUser as getUserFromStorage } from '../api/storage';
import { store } from '../redux/store/store';
import {
  getLoggedInUserFromJWTStart,
  getLoggedInUserFromJWTSuccess,
  getLoggedInUserFromJWTError,
  switchActiveRoleSuccess,
  logOut,
} from '../redux/actions/auth';
import UserApi from '../api/users';
import tokenExpired from '../api/api/tokenExpired';
import { supportedLanguages, defaultLanguage } from '../translations/supportedLanguages';

export const isStudent = (state) => {
  return (
    state.loggedIn.data.role === userRoles.STUDENT_ROLE ||
    state.loggedIn.data.role === userRoles.WORKSHOP_STUDENT_ROLE
  );
};

export const isAdmin = (state) => {
  return state.loggedIn.data.role === userRoles.ADMIN_ROLE;
};

export const isTeacher = (state) => {
  return state.loggedIn.data.role === userRoles.TEACHER_ROLE;
};

export const isWorkshop = (state) => {
  return state.loggedIn.data.role === userRoles.WORKSHOP_STUDENT_ROLE;
};

export const isActiveSchoolAdmin = (state) => {
  return state.loggedIn.data.activeRole === userRoles.SCHOOL_ADMIN_ROLE;
};

export function getUser() {
  const token = getTokenFromLocalStorage();
  if (token && !includes(window.location.href, 'oauth')) {
    const decodedToken = jwtDecode(token);

    // Check: JWT token still ok?
    const timeInSeconds = new Date().getTime() / 1000;
    if (decodedToken) {
      if (decodedToken.exp < timeInSeconds) {
        store.dispatch(logOut());
      } else {
        store.dispatch(getLoggedInUserFromJWTStart());
        UserApi.getCurrentUser()
          .then((data) => {
            store.dispatch(getLoggedInUserFromJWTSuccess(data));

            const { role } = head(values(data.entities.loggedIn));

            if (
              window.location.hash.startsWith('#/schooladminprofile/') &&
              role === userRoles.SCHOOL_ADMIN_ROLE
            ) {
              store.dispatch(switchActiveRoleSuccess({ newRole: userRoles.SCHOOL_ADMIN_ROLE }));
            }
          })
          .catch((error) => {
            store.dispatch(getLoggedInUserFromJWTError(error));
          });
      }
    }
  }
}

export function isPasswordResetRequired() {
  const token = getTokenFromLocalStorage();
  if (token) {
    const decodedToken = jwtDecode(token);

    if (decodedToken) {
      return decodedToken.passwordResetRequired;
    }
  }
  return false;
}

export function isSchoolDisabled() {
  const token = getTokenFromLocalStorage();
  if (token) {
    const decodedToken = jwtDecode(token);

    if (decodedToken) {
      return decodedToken.schoolDisabled || false;
    }
  }
  return false;
}

export function checkTokenOrLogOutAndRefresh(userFromState, language) {
  const token = getTokenFromLocalStorage();
  if (token) {
    const decodedToken = jwtDecode(token);

    // Check: JWT token still ok?
    const timeInSeconds = new Date().getTime() / 1000;
    if (decodedToken && decodedToken.exp < timeInSeconds) {
      const idpFromStorage = getUserFromStorage().idp;
      tokenExpired(window.location.href, idpFromStorage, language);
      return false;
    }
  } else {
    const idpFromState = userFromState.idp;
    tokenExpired(window.location.href, idpFromState, language);
    return false;
  }
  return true;
}

export const getLanguage = (state) => {
  const token = JSON.parse(window.localStorage.getItem('jwtToken'));
  const languageOptions = keys(supportedLanguages);
  let chosenLanguage;
  if (token) {
    const decodedToken = jwtDecode(token);
    chosenLanguage = decodedToken.language;
  } else if (state && state.application) {
    chosenLanguage = state.application.language;
  }
  return includes(languageOptions, chosenLanguage) ? chosenLanguage : defaultLanguage;
};
