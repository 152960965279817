import { schema } from 'normalizr';

export const slide = new schema.Entity('slides');

export const singleSlide = new schema.Entity('singleSlide', {});

export const student = new schema.Entity('students');

export const teacher = new schema.Entity('teachers');

export const schoolAdmin = new schema.Entity('schoolAdmins');

export const curriculum = new schema.Entity('curricula');

export const lesson = new schema.Entity('lessons', {
  slides: [slide],
});

export const eduLesson = new schema.Entity('eduLessons');

export const singleLesson = new schema.Entity('lessons');

export const documentLinks = new schema.Entity('documentLinks');

export const program = new schema.Entity('programs');

export const document = new schema.Entity('documents');

export const exercise = new schema.Entity('exercises');

export const startVersion = new schema.Entity(
  'startVersions',
  {},
  { idAttribute: 'startVersionId' },
);

export const examAnswer = new schema.Entity('examAnswers');

export const studentExamAnswer = new schema.Entity('studentExamAnswers');

export const smartschoolImport = new schema.Entity('smartschoolImport');

export const schoolYear = new schema.Entity('schoolYear');

export const examQuestion = new schema.Entity('examQuestions', {
  examAnswers: [examAnswer],
  studentExamAnswers: [studentExamAnswer],
});

export const exam = new schema.Entity('exams', {
  program,
  examQuestions: [examQuestion],
});

export const studentExam = new schema.Entity('studentExams', {
  student,
  examQuestions: [examQuestion],
  exam,
  classGroupExam: new schema.Entity('classGroupExams'),
});

export const school = new schema.Entity('schools', {
  programs: [program],
  schoolAdmins: [schoolAdmin],
});

export const classGroupProgram = new schema.Entity('classGroupPrograms');

export const classGroup = new schema.Entity('classGroups', {
  school,
  students: [student],
  classGroupPrograms: [classGroupProgram],
});

export const classGroupLesson = new schema.Entity('classGroupLessons', {
  lesson: eduLesson,
  classGroup,
});

export const classGroupExam = new schema.Entity('classGroupExams', {
  studentExams: [studentExam],
  exam,
  classGroup,
});

export const loggedIn = new schema.Entity('loggedIn', {
  school,
  classGroups: [classGroup],
  classGroupPrograms: [classGroupProgram],
  classGroupProgramsHackroom: [classGroupProgram],
});

export const studentWithClassGroup = student.define({
  school,
  classGroups: [classGroup],
});

export const teacherWithClassGroup = teacher.define({
  school,
  classGroupPrograms: [classGroupProgram],
});

export const classGroupProgramWithClassGroup = classGroupProgram.define({
  program,
  classGroupLessons: [classGroupLesson],
  teachers: [teacher],
  students: [student],
  classGroupExams: [classGroupExam],
  classGroup,
});

export const programWithExam = program.define({
  curriculumPath: [curriculum],
  documentLinks,
  lessons: [eduLesson],
  exams: [exam],
});

export const studentExercise = student.define({
  exercises: [exercise],
});
