export const FETCH_LESSON_SLIDES_START = 'FETCH_LESSON_SLIDES_START';
export const FETCH_LESSON_SLIDES_SUCCESS = 'FETCH_LESSON_SLIDES_SUCCESS';
export const FETCH_LESSON_SLIDES_ERROR = 'FETCH_LESSON_SLIDES_ERROR';

export const INSERT_QUESTION_ANSWER_START = 'INSERT_QUESTION_ANSWER_START';
export const INSERT_QUESTION_ANSWER_SUCCESS = 'INSERT_QUESTION_ANSWER_SUCCESS';
export const INSERT_QUESTION_ANSWER_ERROR = 'INSERT_QUESTION_ANSWER_ERROR';

export const DELETE_QUESTION_ANSWER_START = 'DELETE_QUESTION_ANSWER_START';
export const DELETE_QUESTION_ANSWER_SUCCESS = 'DELETE_QUESTION_ANSWER_SUCCESS';
export const DELETE_QUESTION_ANSWER_DENIED = 'DELETE_QUESTION_ANSWER_DENIED';
