import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Responsive } from 'semantic-ui-react';
import FtrprfIcon from '../FtrprfIcon';
import './MessageExpiredTokenPage.scss';

const MessageExpiredTokenPage = ({ intl }) => {
  return (
    <div className="ErrorPage">
      <div className="ErrorPage__Message">
        <Grid divided="vertically">
          <Grid.Column
            className="ErrorPage__Message__IconWrapper"
            mobile={12}
            tablet={12}
            computer={6}
            textAlign="center"
          >
            <FtrprfIcon name="welcome-back" size="38rem" />
          </Grid.Column>
          <Responsive
            as={Grid.Column}
            className="ErrorPage__Message__Text__Left"
            mobile={20}
            tablet={20}
            computer={10}
            minWidth={Responsive.onlyTablet.maxWidth}
          >
            <h1>{intl.formatMessage({ id: 'oauth_refreshed' })}</h1>
          </Responsive>
          <Responsive
            as={Grid.Column}
            className="ErrorPage__Message__Text__Center"
            mobile={20}
            tablet={20}
            computer={10}
            maxWidth={Responsive.onlyTablet.maxWidth}
          >
            <h1>{intl.formatMessage({ id: 'oauth_refreshed' })}</h1>
          </Responsive>
        </Grid>
      </div>
    </div>
  );
};

export default injectIntl(MessageExpiredTokenPage);
