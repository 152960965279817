export default {
  INTRODUCTION: 'Inleiding',
  EXERCISE: 'Oefening',
  THEORY: 'Theorie',
  CLOSURE: 'Slot',
  INDIVIDUALLY: 'Individueel',
  CLASS: 'Klassikaal',
  MANDATORY: 'Verplicht',
  OPTIONAL: 'Facultatief',
};
