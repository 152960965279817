import { merge, assign, omit, map, without, values } from 'lodash-es';
import { normalize } from 'normalizr';
import * as schema from '../../api/mappers/schema';

import {
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_STUDENTEXAM_SUCCESS,
  FETCH_EXAM_START,
  FETCH_EXAM_SUCCESS,
  FETCH_EXAM_ERROR,
  UPDATE_EXAM_START,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_ERROR,
  UPDATE_EXAMS_START,
  UPDATE_EXAMS_SUCCESS,
  UPDATE_EXAMS_ERROR,
  POST_EXAM_START,
  POST_EXAM_SUCCESS,
  POST_EXAM_ERROR,
  FETCH_EXAMS_START,
  FETCH_EXAMS_SUCCESS,
  FETCH_EXAMS_ERROR,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPEXAM_SUCCESS,
  FETCH_PROGRAM_BY_ID_SUCCESS,
  DELETE_EXAM_START,
  DELETE_EXAM_SUCCESS,
  DELETE_EXAM_ERROR,
  DELETE_EXAMQUESTION_SUCCESS,
  COPY_EXAM_START,
  COPY_EXAM_SUCCESS,
  COPY_EXAM_ERROR,
  COPY_EXAMS_START,
  COPY_EXAMS_SUCCESS,
  COPY_EXAMS_ERROR,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function exams(state = initialState.exams, action) {
  let newData;
  switch (action.type) {
    case FETCH_EXAM_START:
    case FETCH_EXAMS_START:
    case UPDATE_EXAM_START:
    case UPDATE_EXAMS_START:
    case POST_EXAM_START:
    case DELETE_EXAM_START:
    case COPY_EXAM_START:
    case COPY_EXAMS_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_EXAM_ERROR:
    case FETCH_EXAMS_ERROR:
    case POST_EXAM_ERROR:
    case DELETE_EXAM_ERROR:
    case UPDATE_EXAM_ERROR:
    case UPDATE_EXAMS_ERROR:
    case COPY_EXAM_ERROR:
    case COPY_EXAMS_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case FETCH_STUDENTEXAM_SUCCESS:
    case FETCH_EXAM_SUCCESS:
    case FETCH_EXAMS_SUCCESS:
    case POST_EXAM_SUCCESS:
    case COPY_EXAM_SUCCESS:
    case FETCH_CLASSGROUPEXAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.exams,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_PROGRAM_BY_ID_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.exams,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case UPDATE_EXAM_SUCCESS:
      return {
        data: {
          ...state.data,
          [action.data.result]: action.data.entities.exams[action.data.result],
        },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      };

    case DELETE_EXAM_SUCCESS:
      return {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      };

    case UPDATE_EXAMS_SUCCESS:
      return {
        data: {
          ...state.data,
          ...Object.values(action.data).reduce((obj, exam) => {
            obj[exam.id] = {
              ...exam,
              program: exam.program.id,
            };
            return obj;
          }, {}),
        },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      };

    case COPY_EXAMS_SUCCESS:
      return {
        data: {
          ...state.data,
          ...Object.values(action.data).reduce((obj, exam) => {
            obj[exam.id] = {
              ...exam,
              program: exam.program.id,
            };
            return obj;
          }, {}),
        },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      };

    case DELETE_EXAMQUESTION_SUCCESS:
      // remove question id from array in objects where question is linked to exam
      newData = normalize(
        map(state.data, (examItem) => {
          return {
            ...examItem,
            examQuestions: without(values(examItem.examQuestions), action.data),
          };
        }),
        [schema.exam],
      );
      return {
        data: newData.entities.exams,
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      };

    default:
      return state;
  }
}

export default exams;
