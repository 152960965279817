import * as stateTypes from '../../constants/stateTypes';
import { defaultLanguage } from '../../translations/supportedLanguages';

export default {
  application: {
    language: defaultLanguage,
    bootstrapped: false,
    accordionPersistance: {
      lessons: [-1],
      exams: [-1],
      programs: [-1],
      hackroom: [-1],
    },
    modal: {
      type: null,
      props: {},
    },
  },
  loggedIn: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  lessons: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  eduLessons: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  students: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  teachers: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  schoolAdmins: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  slides: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  exercises: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  programs: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  classGroups: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  exams: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  examAnswers: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  examQuestions: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  studentExamAnswers: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  studentExams: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  classGroupExams: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  classGroupPrograms: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  classGroupLessons: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  smartSchoolGroups: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  smartSchoolConfig: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  smartSchoolImports: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  schools: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  curricula: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  documents: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  schoolYears: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  startVersions: {
    data: {},
    state: stateTypes.INITIAL_DATA,
    error: {},
  },
  locales: {},
  scratchGui: {},
};
