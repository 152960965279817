export const UPDATE_CLASSGROUPPROGRAM_START = 'UPDATE_CLASSGROUPPROGRAM_START';
export const UPDATE_CLASSGROUPPROGRAM_SUCCESS = 'UPDATE_CLASSGROUPPROGRAM_SUCCESS';
export const UPDATE_CLASSGROUPPROGRAM_ERROR = 'UPDATE_CLASSGROUPPROGRAM_ERROR';

export const FETCH_CLASSGROUPPROGRAM_START = 'FETCH_CLASSGROUPPROGRAM_START';
export const FETCH_CLASSGROUPPROGRAM_SUCCESS = 'FETCH_CLASSGROUPPROGRAM_SUCCESS';
export const FETCH_CLASSGROUPPROGRAM_ERROR = 'FETCH_CLASSGROUPPROGRAM_ERROR';

export const FETCH_CLASSGROUPPROGRAMS_START = 'FETCH_CLASSGROUPPROGRAMS_START';
export const FETCH_CLASSGROUPPROGRAMS_SUCCESS = 'FETCH_CLASSGROUPPROGRAMS_SUCCESS';
export const FETCH_CLASSGROUPPROGRAMS_ERROR = 'FETCH_CLASSGROUPPROGRAMS_ERROR';

export const UPDATE_CLASSGROUPPROGRAMS_START = 'UPDATE_CLASSGROUPPROGRAMS_START';
export const UPDATE_CLASSGROUPPROGRAMS_SUCCESS = 'UPDATE_CLASSGROUPPROGRAMS_SUCCESS';
export const UPDATE_CLASSGROUPPROGRAMS_ERROR = 'UPDATE_CLASSGROUPPROGRAMS_ERROR';
