/* eslint-disable */
import * as Sentry from '@sentry/react';

export const getUser = () => {
  const user = JSON.parse(
    window.localStorage.getItem(
      (process.env.APP_KEY && `${process.env.APP_KEY}.user`) || 'api.user',
    ),
  );

  Sentry.setUser(user);

  return user;
};

export const setUser = (newUser) => {
  Sentry.setUser(newUser);

  window.localStorage.setItem(
    (process.env.APP_KEY && `${process.env.APP_KEY}.user`) || 'api.user',
    JSON.stringify(newUser),
  );
};

export const getTokenFromLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem('jwtToken'));
};

export const setTokenToLocalStorage = (token) => {
  window.localStorage.setItem('jwtToken', JSON.stringify(token));
};

export const removeTokenFromLocalStorage = () => {
  window.localStorage.removeItem('jwtToken');
};

export const removeUser = () => {
  Object.keys(window.localStorage).forEach((key) => {
    if (/user/.test(key)) {
      window.localStorage.removeItem(key);
    }
  });
};

export const getSelectedClassGroup = () => {
  try {
    return JSON.parse(window.localStorage.getItem('selectedClassGroup'));
  } catch (e) {
    return undefined;
  }
};

export const setSelectedClassGroup = (selectedClassGroup) => {
  window.localStorage.setItem('selectedClassGroup', JSON.stringify(selectedClassGroup));
};

export const removeSelectedClassGroupFromLocalStorage = () => {
  window.localStorage.removeItem('selectedClassGroup');
};

export const setSelectedSchool = (selectedSchool) => {
  window.localStorage.setItem('selectedSchool', JSON.stringify(selectedSchool));
};

export const getSelectedSchool = () => {
  return JSON.parse(window.localStorage.getItem('selectedSchool'));
};

export const removeSelectedSchoolFromLocalStorage = () => {
  window.localStorage.removeItem('selectedSchool');
};

export const setSelectedView = (selectedView) => {
  window.localStorage.setItem('selectedView', JSON.stringify(selectedView));
};

export const getSelectedView = () => {
  return JSON.parse(window.localStorage.getItem('selectedView'));
};

export const setQuestionAnswers = (lessonId, version, questionId, value) => {
  const lessons = JSON.parse(window.localStorage.getItem('lessonQuestionAnswers')) || [];
  const lesson = lessons.find(({ lessonId: id, version: lessonVersion, userId }) => {
    return id === lessonId && lessonVersion === version && userId === getUser().id;
  });
  if (lesson) {
    lesson.questions[questionId] = value;
    window.localStorage.setItem('lessonQuestionAnswers', JSON.stringify([...lessons]));
  } else {
    window.localStorage.setItem(
      'lessonQuestionAnswers',
      JSON.stringify([
        ...lessons,
        {
          lessonId,
          userId: getUser().id,
          version,
          questions: {
            [questionId]: value,
          },
        },
      ]),
    );
  }
};

export const getQuestions = (lessonId, lessonVersion) => {
  const lessons = JSON.parse(window.localStorage.getItem('lessonQuestionAnswers')) || [];
  const lesson = lessons.find(
    ({ lessonId: id, version, userId }) =>
      id === lessonId && version === lessonVersion && userId === getUser().id,
  );
  return lesson && lesson.questions;
};

export const getQuestionAnswers = (lessonId, lessonVersion, questionId) => {
  const lessons = JSON.parse(window.localStorage.getItem('lessonQuestionAnswers')) || [];
  const lesson = lessons.find(
    ({ lessonId: id, version, userId }) =>
      id === lessonId && version === lessonVersion && userId === getUser().id,
  );
  return lesson && lesson.questions && lesson.questions[questionId];
};

export const removeQuestion = (lessonId, lessonVersion, questionId) => {
  const lessons = JSON.parse(window.localStorage.getItem('lessonQuestionAnswers')) || [];
  const lesson = lessons.find(
    ({ lessonId: id, version, userId }) =>
      id === lessonId && version === lessonVersion && userId === getUser().id,
  );
  delete lesson.questions[questionId];
  window.localStorage.setItem('lessonQuestionAnswers', JSON.stringify([...lessons]));
};
