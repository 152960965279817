/* eslint-disable max-len */
// ===========================================================================
// This file contains all translation id's for the application
// Naming convention: camelCase combined with snake_case when using a parameter"
// Example: helloWorld_Name: "Hello, world {name}" translates to "Hello, world Eric" when provided with the name parameter
//
// All translations should be lowercase for concistency and fallback messages when language is not found
// Case conversion should be done in code
//
// For more detailed examples visit https://formatjs.io/docs/react-intl
//
// Usage is possible in 2 ways
// 1. tr() function in localization/index.js can be used to return a component that can be used in JSX
// 2. Wrap component with the 'injectIntl()' gives it a 'intl' variable in the props,
//    this can be used as intl.formatMessage('helloWorld') and returns a string
// ===========================================================================

export default {
  // admin
  deleteAnswer: 'Delete answer',
  correctAnswer: 'Correct answer',
  allAnswersRequired: 'All answers required',
  question: 'Question',
  answers: 'Answers',
  errorsOccured: 'Errors occured',
  editQuestion: 'Edit question',
  addNewQuestion: 'Add new question',
  questionAndAnswersUpdated: 'Question and answers updated',
  questionAndAnswersAdded: 'Question and answers added',
  youNeedToFillInTheQuestion: 'You need to fill in the question',
  everyAnswerNeedsContent: 'Every answer needs content',
  youNeedAtleastAnswers_Count: 'You need at least {count} answers',
  youNeedAtleastCorrectAnswers_Count: 'You need at least {count} correct answer',
  amountOfAnswers: 'Amount of answers',
  amountOfCorrectAnswers: 'Amount of correct answers',
  actions: 'Actions',
  addQuestion: 'Add question',
  deleteQuestion: 'Delete question',
  areYouSureYouWantToDeleteThisQuestion: 'Are you sure you want to delete this question?',
  saveExamAndBackToOverview: 'Save exam and back to overview',
  addExamAndBackToOverview: 'Add exam and back to overview',
  saveExamAndMakeQuestion: 'Save exam and make question',
  addExamAndMakeQuestion: 'Add exam and make question',
  examTitle: 'Exam title',
  chooseLinkedProgram: 'Choose linked program',
  chooseLinkedSchoolYear: 'Choose linked schoolyear',
  filterOnSchoolyear: 'Filter on schoolyear',
  editExam: 'Edit exam',
  addExam: 'Add exam',
  copyExam: 'Copy exam',
  linkedProgram: 'Linked program',
  schoolYear: 'School year',
  maxScore: 'Max score',
  examInUse: 'Already in use',
  previewExam: 'Preview exam',
  deleteExam: 'Delete exam',
  cannotDeleteWhileLinkedToPrograms: 'Cannot delete while linked to programs',
  editQuestions: 'Edit questions',
  areYouSureYouWantToDeleteThisExam: 'Are you sure you want to delete this exam?',
  questionsOf: 'Questions of {name}',
  clickOnAnIconToCopyTheIconname: 'Click on an icon to copy the iconname',
  searchIcons: 'Search icons',
  search: 'Search',
  addCurriculum: 'Add curriculum',
  theNameOfTheNewCurriculum: 'The name of the new curriculum',
  theNameOfTheNewProgram: 'The name of the new program',
  addSchool: 'Add school',
  theNameOfTheNewSchool: 'The name of the new school',
  nameOfTheSchool: 'Name of the school',
  theUsernamePrefixOfTheNewSchool: 'The username prefix of the new school',
  firstNameOfTheSchoolAdmin: 'First name of the school admin',
  lastNameOfTheSchoolAdmin: 'Last name of the school admin',
  schooladminAddedToNewSchool: 'Schooladmin added to new school',
  usernameAndPassword_Username_Password: 'Username: {username} and password: {password}',
  usernameAndPassword_Username:
    "The user was created and can now sign in with SmartSchool.{br}{br}The username is: {username}{br}{br}Please check if this FTRPRF username is exactly the same as the one for SmartSchool, otherwise the user can't sign in.{br}{br}Or in coding terms: IF usernameFTRPRF is NOT equal to usernameSmartSchool THEN the user canNOT sign in.",
  ex: 'e.g. {name}',
  defaultPublishState: 'Default publish state',
  youCannotDeleteACurriculumThatHasPrograms: 'You cannot delete a curriculum that has programs',
  reorderPrograms: 'Reorder Programs',
  unusedCurricula: 'Unused curricula',
  unorderedPrograms: 'Unordered programs',
  orderedPrograms: 'Ordered programs',
  reorder: 'Reorder',
  areYouSureYouWantToDelete_Name: 'Are you sure you want to delete {name}?',
  programIsStillInUse_Name: 'Program {name} is still in use by one ore more classes',
  examIsStillInUse_Name: 'Exam {name} is still in use by one ore more classes',
  examIsStillInUseDescription:
    'Students have already used this exam. Please do not modify this exam any more it unless you are aware of the implications this has on the database!',
  examIsInUseAndCannotBeDeleted: 'This exam is in use and cannot be deleted.',
  examIsInUseSoThisQuestionCannotBeDeleted:
    'This exam is in use so this question cannot be deleted.',
  examIsInUseSoThisAnswerCannotBeDeleted: 'This exam is in use so this anwser cannot be deleted.',
  examIsInUseSoThisFieldCannotBeChanged: 'This exam is in use so this field cannot be changed.',
  succesfullyReorderedPrograms: 'Succesfully reordered programs',
  saveSchoolAdmin: 'Save school admin',
  addSchoolAdmin: 'Add school admin',
  schoolAdminAdded: 'School admin added',
  editSchoolAdmin: 'Edit School Admin',
  chooseThePrograms: 'Choose the programs',
  addProgramsToSchool: 'Add programs to school',
  deleteSchoolAdmin: 'Delete School Admin',
  details: 'Details',
  detailsOfSchool: 'Details of {school} ({prefix})',
  schoolAdmins: 'School Admins',
  schoolName: 'School name',
  areYouSureYouWantToDeleteTheProgram_Name:
    'Are you sure you want to delete the program {name}? Exams and lessons will not be accessible anymore.',
  areYouSureYouWantToChangeProgramToHackroom_Name:
    "Are you sure you want to change the type of program {name} to 'Standard'? There could be classes linked to this program, these links will be deleted!",
  usernamePrefix: 'usernamePrefix',
  idp: 'idp',
  stats: 'Statistics',
  nrOfPrograms: 'Amount of programs',
  nrOfTeachers: 'Amount of teachers',
  nrOfStudents: 'Amount of pupils',
  editSchool: 'Edit school',
  manageSchool: 'Manage school',
  deleteSchool: 'Delete school',
  icons: 'Icons',
  copiedToClipboard: 'Copied to clipboard',
  unableToWriteToClipboard: 'Unable to write to clipboard',
  nameOfProgram: 'Name of the program',
  nameOfDocument: 'Name of the document',
  currentAttachedDocument: 'Currently attached document',
  uploadAFile: 'Attach a document. Only these documents are supported: {docs}',
  noDocumentAttached: 'There is no document attached',
  thisLessonImmediatelyDisappearsFromAllClassListsWhereThisProgramIsUsedAreYouSureYouWantToDelete_Lesson:
    'This lesson immediately disappears from all class lists where this lesson is used, are you sure you want to delete {lesson}',
  addFile: 'Attach file',
  propagateStatusChanges: 'Propagate default lesson status',
  doYouWantToPropagateTheseStatusChanges:
    'Do you want to propagate the lesson status changes to all existing lessons? Note that in case the teacher already change the status of this lesson the value will be defauted back to your current choice.',
  downloadExamPdf: 'Download exam overview',
  cannotDownloadWhenNotAvailable: "You can't download a pdf of an exam that is unavailable",
  active: 'Active',
  activate: 'Activate',
  deactivate: 'Deactivate',
  areYouSureActivate:
    'Are you sure you want to activate this school? Users will be able to log in and use the platform.',
  areYouSureDeactivate:
    'Are you sure you want to deactivate this school? Users wont be able to log in and use the platform.',
  schoolYears: 'School years',
  startDate: 'Start date',
  endDate: 'End date',
  addSchoolYear: 'Add school year',
  editSchoolYearInfo: 'Edit this school year',
  deleteSchoolYearInfo: 'Delete this school year',
  updateSchoolYear: 'Update this school year',
  setActive: 'Set as active',
  startDateEmpty: 'Please add a start date.',
  endDateEmpty: 'Please add an end date.',
  areYouSureDeleteSchoolYear:
    'Are you sure you want to delete this school year? This action is only possible when nothing is linked to this school year.',
  areYouSureUpdateSchoolYear:
    'Are you sure you want to alter this school year? Changing the school year to active will deactivate another school year.',
  current: 'current',

  // schoolAdmin
  addClass: 'Add Class',
  giveClassName: 'Give class name',
  noProgramsAvailableForThisClass: 'No programs available for this class',
  saveProgram: 'Save program',
  chooseTheTeachers: 'Choose the teachers',
  teachers: 'Teachers',
  editProgramOfClass: 'Edit program of class',
  addProgramOfClass: 'Add program to class',
  class: 'Class',
  classGroupName: 'ClassGroup name',
  className: 'Class name',
  deleteStudent: 'Delete pupil',
  editProgram: 'Edit program',
  editPrograms: 'Edit programs for this class',
  deleteProgram: 'Delete program',
  numberOfStudents: 'Number of pupils',
  numberOfAssignedPrograms: 'Number of assigned programs',
  editClass: 'Edit class',
  deleteClass: 'Delete class',
  classGroups: 'Class groups',
  editClassGroups: 'Edit class groups',
  classesOf: 'Classes of {name}',
  smartSchoolURLShouldBeInTheFollowingFormat: 'SmartSchool URL should be in the following format',
  webservicePassword: 'Webservice Password',
  add: 'Add',
  editConfiguration: 'Edit configuration',
  addConfiguration: 'Add configuration',
  generaSettingsOf: 'General settings of {name}',
  importStudentsTeachersClasses: 'Import pupils/teachers/classes',
  disableSmartSchoolIntegration: 'Disable SmartSchool integration',
  enableSmartSchoolIntegration: 'Enable SmartSchool integration',
  deleteSmartSchoolIntegration: 'Delete SmartSchool integration',
  allStudentsAndTeachersWhoPreviouslySigned:
    "All pupils and teachers who previously signed in through SmartSchool won't be able to login to FTRPRF anymore. Are you sure?",
  notAllowedToDoSmartSchoolIntegration:
    "You can't execute the Smartschool integration because you are logged in with a Smartschool account.{br}Log in with a FTRPRF account to start the integration.",
  homeVersionOpen: 'Home version open',
  viewLesson: 'View Lesson',
  noProgramsAvailableYet: 'No programs available yet',
  programsOf: 'Programs of {name}',
  import: 'Import',
  andAllHisUnderlyingGroups: 'and all his underlying groups',
  parentnodeIsOfTypeStudent: 'Parentnode is of type pupil so this cannot be teacher',
  parentnodeIsOfTypeTeacher: 'Parentnode is of type teacher so this cannot be pupil',
  thereAreGroupsThatHaveNoCode:
    'There are groups that have no code on SmartSchool so we cannot import your SmartSchool,please add a code to following groups or classes',
  chooseGroupsYouWantToImport: 'Choose groups you want to import',
  addAllProgramsToAllImportedClasses: 'Add all programs to all imported classes',
  importClassgroups: 'Import classgroups',
  warningExistingTeachersStudentsClasses:
    'Warning: existing teachers, pupils and classes will be deleted. Do you want to proceed',
  editStudent: 'Edit pupil profile',
  addstudent: 'Add pupil',
  warningUseTheUsernameFromSmartschool:
    'Warning: please use the exact user name from Smartschool to integrate the account.',
  classes: 'Classes',
  chooseTheClasses: 'Choose the classes',
  studentAdded: 'Pupil added',
  studentsAdded: 'Pupils added',
  studentsOf: 'Pupils of {name}',
  saveTeacher: 'Save teacher',
  addTeacher: 'Add teacher',
  teacherAdded: 'Teacher added',
  teachersAdded: 'Teachers added',
  createdOn: 'Created on',
  schoolAdmin: 'SchoolAdmin',
  chooseTheClassGroupPrograms: 'Choose the classgroup programs',
  classgroupPrograms: 'Classgroup programs',
  newTeacher: 'New teacher',
  teachersOf: 'Teachers of {name}',
  deleteTeacher: 'Delete teacher',
  editTeacher: 'Edit teacher profile',
  editTeachers: 'Edit teachers',
  youNeedToChangeYourPasswordInYourProfile: 'You need to change your password in your profile',
  youCantDeleteYourself: "You can't delete yourself",
  general: 'General',
  generalSettingsOf: 'General settings of {name}',
  importUsersAs: 'Import users as',
  usersImportedAsTeacher: 'Users are imported as teachers',
  usersImportedAsStudent: 'Users are imported as pupils',
  SubgroupsMaybeImported: 'Users in subgroups may be imported',
  noUsersImported: 'Users are not imported',
  overviewOfImported: 'This is an overview of a previous import from Smartschool',
  inSmartSchoolTheFieldCodeShouldBeFilledInForThisGroup:
    'In smartSchool, the field "code" should be filled in for this group',
  andAllUnderlyingGroups: 'and all underlying groups',
  uniqueCode: 'Unique smartschool code',
  cannotImport: 'Cannot import',
  none: 'none',
  thisCouldTakeAFewMinutes: 'This could take a few minutes',
  noTeachersAssigned: 'no teachers assigned',
  cannotLinkTeacherToHackroom: 'Teachers are automatically linked to Hack Rooms',
  userData: 'User data',
  usernameAndPassword: 'Username: "{username}" and password: "{password}".',
  youCanAddProgramsLater: 'Programs can be assigned after creation of the teacher.',
  editLinkedClassgroupprograms: 'Edit assigned class group programs',
  editTeacherdetails: 'Edit teacher details',
  noProgramsForClassGroupLinked: 'No programs linked to this class. Edit to add programs.',
  statusImportInProgress: 'The import is still in progress, this could take a few minutes.',
  statusImportError: 'Something went wrong with the import.',
  statusImportSuccess: 'The import finished successfully.',
  importHistory: 'Import history',
  dataExport: 'Download data',
  downloadSubmittedExams: 'Download submitted exams',
  selectClassesForReportTitle: 'Select classes',
  selectClassesForReportDesc:
    "Select all classes that you want to be included in the report. When you select a class that does not have any submitted exams yet, it won't be included in the report.",
  selectClassesLabel: 'Select classes for report',
  selectAll: 'Select all',
  warningUpdateExistingTeachersStudentsClasses:
    'This will update the changed classgroups/teachers/students. Are you sure you want to continue?',
  updateLastSmartschoolImport: 'Update last import',
  update: 'Update',
  importIsUpdate: 'This import is an update from a previous import',
  importIsNotUpdate: 'This is a new import',
  externalId: 'Smartschool code',
  includeExamPdfs: 'Include exam files',

  // teacher
  thereAreNoLessonsInThisProgram: 'There are no lessons in this program',
  statusChanged: 'Status changed',
  changeStatus: 'Change status',
  view: 'View',
  lessonClosed: 'Lesson closed',
  classVersionPublished: 'Class version published',
  homeVersionPublished: 'Home version published',
  chooseStatus: 'Choose status',
  chooseVersion: 'Choose version',
  classVersion: 'Class version',
  homeVersion: 'Home version',
  projectionVersion: 'Projection version',
  preparationVersion: 'Preparation version',
  lessonsOf: 'Lessons of {name}',
  hackroomLessonsOf: 'Hack Room lessons of {name}',
  exam: 'Exam',
  resultsPublished: 'Results published',
  viewMode: 'View mode',
  submissionDate: 'Submission date',
  questionsAnswered: 'Questions answered',
  showingResultsOfClass: 'Results of class',
  openExam: 'View exam',
  openExamDisabled: "Pupil didn't submit",
  notSubmitted: 'Not submitted',
  examsOf: 'Exams of {name}',
  thereAreNoStudentsInThisClassgroup: 'There are no pupils in this classgroup',
  youAreNotAssignedToAnyClass: 'You are not assigned to any class. Contact the Admin',
  availabilityExamInfo: 'Are pupils allowed to see this exam?',
  resultsExamInfo: 'Can pupils see the results of this exam?',
  classOverviewInfo: 'Open overview of pupil exams',
  examOverviewInfo: 'Open overview of exam questions and anwsers',
  downloadMultipleDocuments: 'Download multiple files',
  selectMultipleDocuments: 'Select the lessons of which you want to download the files.',
  download: 'Download',
  file: 'File',
  filterStartExercise: 'Started from:',
  noStartExercise: 'No starting exercise',
  ownProject: 'Own Project',
  examClosed: 'Exam closed',
  examOpened: 'Exam opened',
  trialExam: 'Trial exam',
  trialExamInfo:
    'This opens the exam, but publishes the results at the same time. When pupils fill in this exam, they can immediately view their results.',
  publishResults: 'Publish results',
  areYouSureCloseResults: 'Are you sure?',
  areYouSureCloseResultsInfo:
    'Are you sure you want to hide the results while the exam is not available ? The pupils will not be able to see their scores or answers of this exam.',
  givenBy: 'Given by',
  willBeGivenBy: 'Will be given by',
  teachOn: 'Teach lesson on',
  teacherNotFound: 'Teacher not found',
  teacherSubmittedAnswersWarning: 'Your submitted answers will not be saved as a teacher',
  showResults: 'Show results',

  // student
  overview: 'Overview',
  submitExam: 'Submit exam',
  nextQuestion: 'Next question',
  viewSummary: 'View summary',
  youHaveNotSumbittedTheExam: 'You have not submitted the exam, are you sure you want to leave?',
  warning: 'Warning',
  notAllQuestionsAreAnswered: 'Not all questions are answered. Are you sure you want to proceed?',
  trialExamYouCanFillItInAndDirectlyViewTheResults:
    'You can fill in the exam and directly view the results.',
  resultsArePublishedViewYourResults: 'Results are published. Open the exam and view your results.',
  examIsOpenForEditing: 'Exam is open',
  submittedAt: 'Submitted at',
  thereAreNoExamsOpenForYou: 'There are no exams open for you',
  thereAreNoLessonsOpenForYou: 'There are no lessons open for you',
  scratchExercise: 'Scratch exercise',
  submit: 'Submit',
  submitted: 'Submitted',
  notYetSubmittedHeader: 'You still have unsubmitted answers',
  notYetSubmittedContent:
    'You still have unsubmitted answers, do you want to submit them now or remove them?',

  // editor
  thisIsAnExamplePresentationalComponent: 'This is an example presentational component',
  thisIsJustAPlaceholderForTheProjectPage:
    'This is just a placeholder for the project page, you should redirect the logged on user to the right component',

  // error
  somethingWentWrongWhileUpdatingAQuestion: 'Something went wrong while updating a question',
  somethingWentWrongWhileDeletingAQuestion: 'Something went wrong while deleting a question',
  somethingWentWrongWhileUpdatingAnAnswer: 'Something went wrong while updating an answer',
  somethingWentWrongWhileCreatingAnAnswer: 'Something went wrong while creating an answer',
  somethingWentWrongWhileCreatingAQuestion: 'Something went wrong while creating a question',
  thereAlreadyIsASchoolWithThisSchoolname:
    'There already is a school with this schoolname, please choose a unique schoolname',
  thereAlreadyIsASchoolWithThisUsernamePrefix:
    'There already is a school with this username prefix, please choose a unique username prefix',
  thereAlreadyIsASchooladminWithThisUsername:
    'There already is a schooladmin with this username, please choose a unique username',
  thestudioContentIdShouldOnlyContainNumbers: 'The studio id should only contain numbers',
  aLessonShouldAlwaysHaveATitle: 'A lesson should always have a title',
  aLessonShouldAlwaysBeLinkedToAnLPFLesson: 'A lesson should always be linked to an LPF-lesson',
  thereShouldBeDefaultPublishStatusSelected: 'There should be default publish status selected',
  thereAlreadyIsAUserWithThisUsername:
    'There already is a user with this username, please choose a unique username',
  selectAtLeastOneProgram: 'Select at least one program',
  thereAreNoMoreProgramsAvailable: 'There are no more programs available',
  thereAlreadyIsASchoolWithThisName:
    'There already is a school with this name, please choose a unique name',
  thereAlreadyIsAClassWithThisName:
    'There already is a class with this name, please choose a unique class name',
  thereIsAnUnknownError: 'There is an unknown error, please try again',
  somethingWentWrong: 'Something went wrong, please try again',
  somethingtWenWrongGoBack: 'Something went wrong, please go back and try again',
  thisSmartschoolURLIsAlreadyUsed:
    'This Smartschool URL is already used, please choose a unique on',
  somethingWentWrongWhileShowingTheExercises:
    'Something went wrong while showing the exercises, please try again.',
  theFileCannotBeOpened_Name: 'The file {name} cannot be opened',
  theSmartSchoolImportFailedBecauseThereWereDuplicatedClassgroupNames:
    'The SmartSchool import failed because there were duplicated classgroup names. Please make sure that all the classgroup names are unique.',
  theSmartSchoolImportFailedBecauseNoStudentsWereImported:
    'The SmartSchool import failed because there were no pupils selected.',
  theSmartSchoolImportFailedBecauseNoTeachersWereImported:
    'The SmartSchool import failed because there were no teachers selected.',
  smartschoolAddTeacherError:
    'You no longer need to add new teachers in the FTRPRF platform. When a user is added in Smartschool, it will be added in the FTRPRF platform by the next day.',
  smartschoolAddStudentError:
    'You no longer need to add new students in the FTRPRF platform. When a user is added in Smartschool, it will be added in the FTRPRF platform by the next day.',

  // errorpage
  oops: 'Oops',
  errorSomethingWentWrong: 'Something went wrong',
  goBack: 'Go back',
  toTheHomePage: 'to the home page',
  youAreNotAuthorizedForThisPage: 'you are not authorized for this page',
  pacmanDidNotUnderstandYourMessageEither: 'Pacman did not understand your message either',
  woot: 'Woot',

  // header
  headerAdminSchools: 'Schools',
  headerAdminPrograms: 'Programs',
  headerAdminExams: 'Exams',
  headerAdminSchoolYears: 'School years',
  headerSchoolAdminClasses: 'Classes',
  headerSchoolAdminStudents: 'Pupils',
  headerSchoolAdminStudentsTeachers: 'Teachers',
  headerSchoolAdminStudentsPrograms: 'Programs',
  headerSchoolAdminStudentsGeneral: 'General',
  headerSchoolAdminExercises: 'Exercises',
  headerTeacherStudents: 'Pupils',
  headerTeacherLessons: 'Lessons',
  headerTeacherExams: 'Exams',
  headerTeacherExercises: 'Exercises',
  headerStudentLessons: 'Lessons',
  headerStudentExams: 'Exams',
  headerStudentExercises: 'Exercises',
  headerHackroom: 'Hack Room',

  // shared
  FTRPRF: 'FTRPRF',
  yes: 'Yes',
  no: 'No',
  students: 'Pupils',
  schools: 'Schools',
  resetPassword: 'Reset password',
  delete: 'Delete',
  edit: 'Edit',
  program: 'Program',
  sureYouWantToDelete_Lesson: 'Sure you want to delete {lesson}?',
  lessons: 'Lessons',
  content: 'Content',
  lessonsHackroom: 'Hack Room',
  programName: 'Program name',
  deleteLesson: 'Delete lesson',
  editLesson: 'Edit lesson',
  addLesson: 'Add lesson',
  addContent: 'Add content',
  editContent: 'Edit content',
  deleteContent: 'Delete content',
  contentType: 'Content type',
  addProgram: 'Add program',
  programType: 'The program type:',
  standard: 'Standard',
  hackroom: 'Hack Room',
  back: 'Back',
  name: 'Name',
  exams: 'Exams',
  exercises: 'Exercises',
  availability: 'Availability',
  results: 'Results',
  programs: 'Programs',
  title: 'Title',
  sequence: 'Sequence',
  cancel: 'Cancel',
  save: 'Save',
  firstName: 'First name',
  lastName: 'Last name',
  username: 'Username',
  accept: 'Accept',
  eulaDecline: 'No, I do not accept',
  eulaAccept: 'Yes, I accept',
  endUserlicenseAgreement: 'End User License Agreement',
  endUserlicenseAgreementAccepted: 'End User License Agreement Accepted',
  changePassword: 'Change Password',
  changePasswordInfo: 'You will have to log back in after this.',
  changePasswordRequired: 'You must first change your password to have access.',
  admin: 'Admin',
  schooladmin: 'School Admin',
  schoolteacher: 'School Teacher',
  teacher: 'Teacher',
  student: 'Pupil',
  schoolstudent: 'pupil',
  role: 'Role',
  at: 'at',
  inTotal: 'in total',
  confirmwithYourOwnPasswordToChangeThis: 'Confirm with your own password to change this',
  yourPasswordIsIncorrect: 'Your password is incorrect',
  yourPassword: 'Your password',
  password: 'Password',
  confirm: 'Confirm',
  passwordReset: 'Password reset',
  theNewPasswordForIs_Name_Password: 'The new password for {name} is: {password}',
  forUserWithUsername_Name_Username: 'For user {name} with username {username}',
  resetStudentPassword: 'Reset password pupil',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  confirmNewPassword: 'Confirm new password',
  currentPasswordAndNewPasswordCannotBeEqual: 'Current password and new password cannot be equal',
  newPasswordAndConfirmPasswordAreNotEqual: 'New password and confirm password are not equal',
  wrongCurrentPassword: 'Wrong current password',
  revokeEULA: 'Revoke EULA',
  revokeEULAWillDisableAccess:
    'Revoking End User License Agreement will disable access to content until it is accepted again. Do you want to proceed',
  thereAreNoOpenForYou_Name: 'There are no {name} open for you',
  continueWithPreviouslyStartedExercise: 'Continue with previously started exercise',
  continueWhereYouLastEnded_Exercise:
    'Do you want to continue where you last ended with exercise: {exercise}, or do you want to start again?',
  ifYouStartAgainYourPreviousProgressWillBeSaved:
    'If you start again, your previous progress will be saved and you can do it always open via your profile page',
  startOver: 'Start over',
  continue: 'Continue',
  exercise: 'Exercise',
  myExercises: 'My exercises',
  addExercise: 'Add exercise',
  youHaveNoExercisesYet: 'You have no exercises yet',
  openExercise: 'Open exercise',
  makeCopy: 'Make copy',
  lastModified: 'Last modified',
  correctAnswerExplanation: 'Correct answer explanation',
  questions: 'Questions',
  chooseALocalFile: 'Choose a local file',
  enterProgramName: 'Enter program name',
  downloadExercise: 'Download exercise',
  nameExercise: 'Name exercise',
  reEnterYourOwnPasswordToIdentifyYourselfAsSchoolAdministrator:
    'For security reasons, please re-enter your own password to identify yourself as school administrator',
  hello: 'Hello',
  loggedInAs: 'Logged in as',
  goBackToAdminMode: 'Go back to admin mode',
  switchToTeacherMode: 'Switch to teacher mode',
  switchToSchooladminMode: 'Switch to schooladmin mode',
  profile: 'Profile',
  help_videos: 'Help videos',
  help: 'Help',
  logOut: 'Log out',
  areYouSureYouWantToLeaveThePage:
    'Are you sure you want to leave the page? All changes that have not been saved will be lost.',
  decline: 'Decline',
  yourProfile: 'Your profile',
  sad: 'Sad',
  tryAgain: 'Try again',
  wellDone: 'Well done',
  further: 'Further',
  slideInformation: 'Slide information',
  home: 'Home',
  projection: 'Projection',
  deleteFailed: 'Delete failed',
  deleteSuccess: 'Delete success',
  updateFailed: 'Failed to update',
  updateSuccess: 'Updated successfully!',
  deleteExercise: 'Delete exercise',
  deleteExerciseMessage_Title: 'Are u sure that u want to delete {title}?',
  thisFieldIsRequired: 'This field is required',
  oauth_refreshed: "You're signed in again. Go back to the other tab and try again.",
  loginInProgress: "We're trying to log you in",
  signIn: 'Sign in',
  signInSmartschool: 'Sign in with SmartSchool',
  signInTeams: 'Sign in with Teams',
  or: 'or',
  eAcademy: 'e-Academy for tinkerers and creators',
  language: 'Language',
  loading: 'Loading...',
  nameNewScratchProject: 'My Project',
  openStats: 'Open some statistics about this {content}',
  openStatsDisabled: 'Opening the statistics about this {content} is not possible until {trigger}',
  studentExamIsSubmitted: "a pupil has submitted it's exam",
  schoolInactive: 'Your school is inactive',
  schoolInactiveMessage:
    'Your school has been set to inactive, this means you will be logged out and you are no longer able to use the platform untill your school is active again.',
  whatAreYouLookingFor: 'What are you looking for?',
  noMatchFound: 'No matches found',
  lastLogin: 'Last login',
  on: 'on',
  areYouSure: 'Are you sure?',
  teacherManual: 'Teacher manual',
  manualEmpty: 'There is no info available for this slide',
  closeSlides: 'Close slides',
  connectedStudents: 'Connected students',
  connectToApp: 'Connect to app',
  searchName: 'Search name',
  guests: 'Guests',
  startQuiz: 'Start quiz',
  openForGuests: 'Open for guests',
  guestCode: 'Guest code',
  slidesOverview: 'Slides overview',
  settings: 'Settings',
  givenAnswers: 'Given answers',
  feedback: 'Feedback',
  feedbackExplanation: 'Something wrong with the slides? Send your feedback here.',
  quizConnectExplanation:
    'Open here your connection with the app and manage the participating students.',
  zoomExplanation: 'Use + and -, to zoom in and out the slides.',
  startConnectingStudents: 'Start connecting students',
  sendQuestion: 'Send question',
  sendEvaluation: 'Send evaluation',
  showGivenAnswers: 'Show given answers',
  hideGivenAnswers: 'Hide given answers',
  answered: 'Answered: ',
  noSlidesFound: "LOOKS LIKE THERE'S NOTHING HERE!",

  //  On sign in again
  successExercise: "Great, you're signed in! Please try again to save your exercise.",
  successOther: "You're signed in now. You should be able to continue working now.",
  signInAgainFailed: 'Your username and/or password was incorrect. Try again. ',
  signInAgainMessageFTRPRF: 'Please enter your credentials. On cancel you will be signed out. ',
  signInAgainMessageSmartSchool:
    'Your current session is expired. A new tab will open where you can sign in again. On cancel you will be signed out.',
  signInAgainHeader: 'Session Expired',

  // Dashboard statistics
  stats_classExam_overal_header: 'General',
  stats_classExam_overal_expl:
    'Top left: average score, bottom left: average percentage, {br}Centre: percentage pupils passed, {br}Top right: median score, bottom right: median percentage',
  stats_classExam_bestWorst_header: 'Top and bottom three pupils',
  stats_classExam_bestWorst_expl: 'Left: top three pupils, {br}Right: bottom three pupils',
  stats_classExam_byQuestion_header: 'Result per question',
  stats_classExam_byQuestion_expl:
    'X axis (horizontal): question number, y axis (vertical): number of pupils, {br}Blue bar: number of pupils with correct answer, {br}Red dot: number of pupils in class',
  stats_classExam_byStudent_header: 'Result per pupil',
  stats_classExam_byStudent_expl:
    'X axis (horizontal): pupil name, y axis (vertical): number of questions, {br}Blue bar: number of correct questions, {br}Red dot: number of answered questions',

  exams_showing_all_schoolyears: 'Exams from all schoolYears are shwown',

  // Edit multiple exams
  exams_edit_all: 'Edit selected',
  exams_edit_all_header: 'Edit selected exams',
  exams_edit_action: 'Edit',
  exams_set_active: 'Make available',
  edit_schoolYear_placeholder: 'Do not modify schoolYears',
  edit_isActive_noChange: 'Do not change availabilities',

  // Copy mutliple exams
  exams_copy_all: 'Copy selected',
  exams_copy_all_header: 'Copy selected exams',
  exams_copy_action: 'Copy',
  copy_linkedProgram: 'When no program is selected, the currently linked programs are chosen',
  copy_linkedProgram_placeholder: 'Select currently linked programs',

  // viewModes
  'view-modes.teacher_manual': 'Teacher manual',
  'view-modes.projection': 'Projection',
  'view-modes.selfstudy': 'Self study',
  'view-modes.classical': 'Classical',
};
