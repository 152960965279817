import React from 'react';
import { NextRedirect } from '../shared/ExternalLink';
import { getTokenFromLocalStorage, setTokenToLocalStorage } from '../../api/storage';

export const teamsUrl = `https://ftrprftstb2c.b2clogin.com/ftrprftstb2c.onmicrosoft.com/oauth2/v2.0/authorize?domain_hint=teams&p=B2C_1A_4A_3_2_1_RELYINGPARTY_USERNAME_SIGNIN&client_id=23aacc8c-842f-482a-9f9f-64c25bec5bbe&nonce=defaultNonce&redirect_uri=${window.location.origin}/teams/oauth&scope=openid&response_type=id_token&prompt=login`;

const TeamsLogin = () => {
  const teamsIdToken = window.location.href.match(/id_token=(.*)/m)[1];
  const isTeamsError = window.location.href.includes('error');

  if (teamsIdToken) {
    setTokenToLocalStorage(teamsIdToken);
  }

  if (!getTokenFromLocalStorage()) {
    return <>Loading...</>;
  }

  if (isTeamsError) {
    return <div>There was an error logging you in with Teams</div>;
  }

  return <NextRedirect to="/" />;
};

export default TeamsLogin;
