export const FETCH_CLASSGROUPS_START = 'FETCH_CLASSGROUPS_START';
export const FETCH_CLASSGROUPS_SUCCESS = 'FETCH_CLASSGROUPS_SUCCESS';
export const FETCH_CLASSGROUPS_ERROR = 'FETCH_CLASSGROUPS_ERROR';

export const DELETE_CLASSGROUP_START = 'DELETE_CLASSGROUP_START';
export const DELETE_CLASSGROUP_SUCCESS = 'DELETE_CLASSGROUP_SUCCESS';
export const DELETE_CLASSGROUP_ERROR = 'DELETE_CLASSGROUP_ERROR';

export const ADD_CLASSGROUP_START = 'ADD_CLASSGROUP_START';
export const ADD_CLASSGROUP_SUCCESS = 'ADD_CLASSGROUP_SUCCESS';
export const ADD_CLASSGROUP_ERROR = 'ADD_CLASSGROUP_ERROR';

export const UPDATE_CLASSGROUP_START = 'UPDATE_CLASSGROUP_START';
export const UPDATE_CLASSGROUP_SUCCESS = 'UPDATE_CLASSGROUP_SUCCESS';
export const UPDATE_CLASSGROUP_ERROR = 'UPDATE_CLASSGROUP_ERROR';

export const FETCH_CLASSGROUP_BY_ID_START = 'FETCH_CLASSGROUP_BY_ID_START';
export const FETCH_CLASSGROUP_BY_ID_SUCCESS = 'FETCH_CLASSGROUP_BY_ID_SUCCESS';
export const FETCH_CLASSGROUP_BY_ID_ERROR = 'FETCH_CLASSGROUP_BY_ID_ERROR';

export const FETCH_REPORT_START = 'FETCH_REPORT_START';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';

export const FETCH_REPORT_AND_EXAMS_START = 'FETCH_REPORT_AND_EXAMS_START';
export const FETCH_REPORT_AND_EXAMS_SUCCESS = 'FETCH_REPORT_AND_EXAMS_SUCCESS';
export const FETCH_REPORT_AND_EXAMS_ERROR = 'FETCH_REPORT_AND_EXAMS_ERROR';

export const CLEAR_CLASSGROUPS = 'CLEAR_CLASSGROUPS';
