import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { IntlProvider } from 'react-intl';
import Loading from '../shared/FullScreenLoader';
// import Maintenance from '../Maintenance';
import './App.scss';
import ModalRoot from '../shared/Modals/ModalRoot';
import withModalFunctions from '../shared/Modals/withModalFunctions';
import { ERROR, LOADED_SUCCESSFUL } from '../../constants/stateTypes';
import { ThemeConsumer } from '../../contextApi/ThemeProvider';
import ErrorPage from '../shared/ErrorPage';
import ErrorBoundary from '../shared/ErrorBoundary/ErrorBoundary';
import { isPasswordResetRequired } from '../../selectors';
import MessageExpiredTokenPage from '../shared/MessageExpiredTokenPage/MessageExpiredTokenPage';
import { supportedLanguages } from '../../translations/supportedLanguages';

const ExerciseContainer = Loadable({
  loader: () => import('../shared/Exercises/ExerciseContainer'),
  loading: Loading,
});

const Teacher = Loadable({
  loader: () => import('../Teacher'),
  loading: Loading,
});

const Student = Loadable({
  loader: () => import('../Student'),
  loading: Loading,
});

const Admin = Loadable({
  loader: () => import('../Admin'),
  loading: Loading,
});

const Editor = Loadable({
  loader: () => import('../Editor'),
  loading: Loading,
});

const SchoolAdmin = Loadable({
  loader: () => import('../SchoolAdmin'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('../Login'),
  loading: Loading,
});

const Lti = Loadable({
  loader: () => import('../Lti'),
  loading: Loading,
});

const SlideViewerContainer = Loadable({
  loader: () => import('../shared/SlideViewer'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('../shared/Logout'),
  loading: Loading,
});

const PrivateRoute = ({ path, data, state, localStorageToken, component }) => {
  if (
    !isEmpty(localStorageToken) &&
    isEmpty(data) &&
    state !== LOADED_SUCCESSFUL &&
    state !== ERROR &&
    !isPasswordResetRequired()
  ) {
    return <Loading />;
  }

  return !isEmpty(data) && data.eulaAccepted && !isPasswordResetRequired() ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/" />
  );
};

class App extends Component {
  state = {};

  buildCorrectHashUrl() {
    return `${window.location.origin}${window.location.pathname}${window.location.hash}${window.location.search}`;
  }

  render() {
    document.title = 'FTRPRF';
    const { language } = this.props;

    // This is a fix for i-Learn and other providers
    // that redirects with the query parameters before the hash.
    if (window.location.href !== this.buildCorrectHashUrl()) {
      window.location.href = this.buildCorrectHashUrl();

      return null;
    }

    return (
      <IntlProvider locale={language} messages={supportedLanguages[language]}>
        <ThemeConsumer>
          {({ theme }) => (
            <div className={classNames('full-container', theme.rootClass)}>
              <ErrorBoundary>
                <Switch>
                  {/* <Route path="*" component={Maintenance} /> */}
                  <Route exact path="/" component={Login} />
                  <Route
                    exact
                    path="/oauth_refresh"
                    render={(routeProps) => <Login {...routeProps} oauth_refreshed />}
                  />
                  <Route exact path="/oauth_refreshed" component={MessageExpiredTokenPage} />
                  <Route exact path="/oauth*" component={Login} />
                  <Route path="/auth/lti" component={Lti} />
                  <Route exact path="/logout" component={Logout} />
                  <PrivateRoute
                    path="/exercises/new/:type?"
                    component={ExerciseContainer}
                    {...this.props}
                  />
                  <PrivateRoute path="/schoolteacherprofile" component={Teacher} {...this.props} />
                  <PrivateRoute path="/schoolstudentprofile" component={Student} {...this.props} />
                  <PrivateRoute path="/editorprofile" component={Editor} {...this.props} />
                  <PrivateRoute
                    path="/schooladminprofile"
                    component={SchoolAdmin}
                    {...this.props}
                  />
                  <PrivateRoute path="/adminprofile" component={Admin} {...this.props} />
                  <PrivateRoute
                    path="/exercises/:id/new/:type?"
                    component={ExerciseContainer}
                    {...this.props}
                  />
                  <PrivateRoute
                    path="/exercises/:id/:studentId?"
                    component={ExerciseContainer}
                    {...this.props}
                  />
                  <PrivateRoute
                    path="/slideviewer/:lessonId/:type/:slideId?"
                    component={SlideViewerContainer}
                    {...this.props}
                  />
                  <Route path="/error" component={ErrorPage} />
                  <Redirect
                    to={{
                      pathname: '/error',
                    }}
                  />
                </Switch>
              </ErrorBoundary>
              <ModalRoot />
            </div>
          )}
        </ThemeConsumer>
      </IntlProvider>
    );
  }
}

export default withModalFunctions(App);
