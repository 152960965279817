export const POST_EXAMANSWER_START = 'POST_EXAMANSWER_START';
export const POST_EXAMANSWER_SUCCESS = 'POST_EXAMANSWER_SUCCESS';
export const POST_EXAMANSWER_ERROR = 'POST_EXAMANSWER_ERROR';

export const UPDATE_EXAMANSWER_START = 'UPDATE_EXAMANSWER_START';
export const UPDATE_EXAMANSWER_SUCCESS = 'UPDATE_EXAMANSWER_SUCCESS';
export const UPDATE_EXAMANSWER_ERROR = 'UPDATE_EXAMANSWER_ERROR';

export const DELETE_EXAMANSWER_START = 'DELETE_EXAMANSWER_START';
export const DELETE_EXAMANSWER_SUCCESS = 'DELETE_EXAMANSWER_SUCCESS';
export const DELETE_EXAMANSWER_ERROR = 'DELETE_EXAMANSWER_ERROR';
