export const FETCH_TEACHERS_START = 'FETCH_TEACHERS_START';
export const FETCH_TEACHERS_SUCCESS = 'FETCH_TEACHERS_SUCCES';
export const FETCH_TEACHERS_ERROR = 'FETCH_TEACHERS_ERROR';

export const DELETE_TEACHER_START = 'DELETE_TEACHER_START';
export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';
export const DELETE_TEACHER_ERROR = 'DELETE_TEACHER_ERROR';

export const ADD_TEACHER_START = 'ADD_TEACHER_START';
export const ADD_TEACHER_SUCCESS = 'ADD_TEACHER_SUCCESS';
export const ADD_TEACHER_ERROR = 'ADD_TEACHER_ERROR';

export const UPDATE_TEACHER_START = 'UPDATE_TEACHER_START';
export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export const UPDATE_TEACHER_ERROR = 'UPDATE_TEACHER_ERROR';

export const FETCH_TEACHER_BY_ID_START = 'FETCH_TEACHER_BY_ID_START';
export const FETCH_TEACHER_BY_ID_SUCCESS = 'FETCH_TEACHER_BY_ID_SUCCESS';
export const FETCH_TEACHER_BY_ID_ERROR = 'FETCH_TEACHER_BY_ID_ERROR';
