export const FETCH_CURRICULA_START = 'FETCH_CURRICULA_START';
export const FETCH_CURRICULA_SUCCESS = 'FETCH_CURRICULA_SUCCESS';
export const FETCH_CURRICULA_ERROR = 'FETCH_CURRICULA_ERROR';

export const UPDATE_CURRICULUM_START = 'UPDATE_CURRICULUM_START';
export const UPDATE_CURRICULUM_SUCCESS = 'UPDATE_CURRICULUM_SUCCESS';
export const UPDATE_CURRICULUM_ERROR = 'UPDATE_CURRICULUM_ERROR';

export const DELETE_CURRICULUM_START = 'DELETE_CURRICULUM_START';
export const DELETE_CURRICULUM_SUCCESS = 'DELETE_CURRICULUM_SUCCESS';
export const DELETE_CURRICULUM_ERROR = 'DELETE_CURRICULUM_ERROR';

export const ADD_CURRICULUM_START = 'ADD_CURRICULUM_START';
export const ADD_CURRICULUM_SUCCESS = 'ADD_CURRICULUM_SUCCESS';
export const ADD_CURRICULUM_ERROR = 'ADD_CURRICULUM_ERROR';
