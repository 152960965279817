export const FETCH_STUDENTS_BY_SCHOOL_START = 'FETCH_STUDENTS_BY_SCHOOL_START';
export const FETCH_STUDENTS_BY_SCHOOL_SUCCESS = 'FETCH_STUDENTS_BY_SCHOOL_SUCCESS';
export const FETCH_STUDENTS_BY_SCHOOL_ERROR = 'FETCH_STUDENTS_BY_SCHOOL_ERROR';

export const DELETE_STUDENT_START = 'DELETE_STUDENT_START';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_ERROR = 'DELETE_STUDENT_ERROR';

export const ADD_STUDENT_START = 'ADD_STUDENT_START';
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
export const ADD_STUDENT_ERROR = 'ADD_STUDENT_ERROR';

export const UPDATE_STUDENT_START = 'UPDATE_STUDENT_START';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_ERROR = 'UPDATE_STUDENT_ERROR';

export const FETCH_STUDENT_BY_ID_START = 'FETCH_STUDENT_BY_ID_START';
export const FETCH_STUDENT_BY_ID_SUCCESS = 'FETCH_STUDENT_BY_ID_SUCCESS';
export const FETCH_STUDENT_BY_ID_ERROR = 'FETCH_STUDENT_BY_ID_ERROR';
