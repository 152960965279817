import { merge, assign, omit } from 'lodash-es';
import {
  FETCH_SMARTSCHOOL_CONFIG_ERROR,
  FETCH_SMARTSCHOOL_CONFIG_START,
  FETCH_SMARTSCHOOL_CONFIG_SUCCESS,
  UPDATE_SMARTSCHOOL_CONFIG_ERROR,
  UPDATE_SMARTSCHOOL_CONFIG_START,
  UPDATE_SMARTSCHOOL_CONFIG_SUCCESS,
  DELETE_SMARTSCHOOL_CONFIG_START,
  DELETE_SMARTSCHOOL_CONFIG_SUCCESS,
  DELETE_SMARTSCHOOL_CONFIG_ERROR,
} from '../types/smartSchoolConfig';

import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function smartSchoolConfig(state = initialState.smartSchoolConfig, action) {
  switch (action.type) {
    case FETCH_SMARTSCHOOL_CONFIG_START:
    case UPDATE_SMARTSCHOOL_CONFIG_START:
    case DELETE_SMARTSCHOOL_CONFIG_START:
      return assign({}, { data: {}, state: stateTypes.LOADING, error: {} });

    case FETCH_SMARTSCHOOL_CONFIG_ERROR:
    case DELETE_SMARTSCHOOL_CONFIG_ERROR:
    case UPDATE_SMARTSCHOOL_CONFIG_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_SMARTSCHOOL_CONFIG_SUCCESS:
    case UPDATE_SMARTSCHOOL_CONFIG_SUCCESS:
      return merge({}, state, {
        data: action.data,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_SMARTSCHOOL_CONFIG_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    default:
      return state;
  }
}

export default smartSchoolConfig;
