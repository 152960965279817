import { merge, assign } from 'lodash-es';
import {
  FETCH_SMARTSCHOOL_IMPORT_START,
  FETCH_SMARTSCHOOL_IMPORT_SUCCESS,
  FETCH_SMARTSCHOOL_IMPORT_ERROR,
  FETCH_ALL_SMARTSCHOOL_IMPORTS_START,
  FETCH_ALL_SMARTSCHOOL_IMPORTS_SUCCESS,
  FETCH_ALL_SMARTSCHOOL_IMPORTS_ERROR,
} from '../types/smartSchoolImport';
import { FETCH_SMARTSCHOOL_CONFIG_ERROR } from '../types/smartSchoolConfig';

import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function smartSchoolImports(state = initialState.smartSchoolImports, action) {
  switch (action.type) {
    case FETCH_ALL_SMARTSCHOOL_IMPORTS_START:
      return assign({}, { data: {}, state: stateTypes.LOADING, error: {} });

    case FETCH_SMARTSCHOOL_IMPORT_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_ALL_SMARTSCHOOL_IMPORTS_ERROR:
    case FETCH_SMARTSCHOOL_IMPORT_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_ALL_SMARTSCHOOL_IMPORTS_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.smartschoolImport,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_SMARTSCHOOL_IMPORT_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.smartschoolImport,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_SMARTSCHOOL_CONFIG_ERROR:
      return assign({}, { data: {}, state: stateTypes.INITIAL_DATA, error: {} });

    default:
      return state;
  }
}

export default smartSchoolImports;
