import { merge, assign, omit } from 'lodash-es';
import {
  FETCH_STUDENTEXAM_SUCCESS,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
  POST_STUDENT_EXAM_ANSWER_START,
  POST_STUDENT_EXAM_ANSWER_SUCCESS,
  POST_STUDENT_EXAM_ANSWER_ERROR,
  DELETE_STUDENT_EXAM_ANSWER_START,
  DELETE_STUDENT_EXAM_ANSWER_SUCCESS,
  DELETE_STUDENT_EXAM_ANSWER_ERROR,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function examQuestions(state = initialState.studentExamAnswers, action) {
  switch (action.type) {
    case DELETE_STUDENT_EXAM_ANSWER_START:
    case POST_STUDENT_EXAM_ANSWER_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case POST_STUDENT_EXAM_ANSWER_ERROR:
    case DELETE_STUDENT_EXAM_ANSWER_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case POST_STUDENT_EXAM_ANSWER_SUCCESS:
      return merge({}, state, {
        data: action.data.studentExamAnswers,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_STUDENTEXAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.studentExamAnswers,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    case DELETE_STUDENT_EXAM_ANSWER_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data.studentExamAnswer),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    default:
      return state;
  }
}

export default examQuestions;
