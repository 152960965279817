import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Form, Input, Icon } from 'semantic-ui-react';
import { includes, head, values } from 'lodash-es';
import { store } from '../../redux/store/store';
import { logOut } from '../../redux/actions/auth';
import api from '../auth';
import { setUser } from '../storage';
import { supportedLanguages } from '../../translations/supportedLanguages';

const container = document.createElement('div');
let language;
let modalFtrprfUser;

const getTranslatedMessage = (messageId) => {
  return supportedLanguages[language][messageId];
};

const submitFtrprfUser = (url, credentials) => {
  const onSaveExercise = includes(url, '/exercises/');
  api
    .login(credentials)
    .then((data) => {
      ReactDOM.unmountComponentAtNode(container);
      const loggedInFromData = head(values(data.entities.loggedIn));
      const loggedIn = {
        ...loggedInFromData,
        activeRole: loggedInFromData.role,
      };
      setUser(loggedIn);
      const confirmationModal = (
        <Modal closeOnDimmerClick={false} className="BasicModal" size="tiny" open dimmer="inverted">
          <Modal.Header>{getTranslatedMessage('signInAgainHeader')}</Modal.Header>
          <Modal.Content>
            {onSaveExercise
              ? getTranslatedMessage('successExercise')
              : getTranslatedMessage('successOther')}
          </Modal.Content>
          <Modal.Actions>
            <Button
              autoFocus
              button="submit"
              positive
              icon="checkmark"
              labelPosition="right"
              content="OK"
              onClick={() => {
                ReactDOM.unmountComponentAtNode(container);
              }}
            />
          </Modal.Actions>
        </Modal>
      );
      ReactDOM.render(confirmationModal, container);
    })
    .catch(() => {
      ReactDOM.unmountComponentAtNode(container);
      ReactDOM.render(modalFtrprfUser(url, true), container);
    });
};

modalFtrprfUser = (url, loginFailed = false) => {
  const credentials = {};
  return (
    <Modal closeOnDimmerClick={false} className="BasicModal" size="tiny" open dimmer="inverted">
      <Modal.Header>{getTranslatedMessage('signInAgainHeader')}</Modal.Header>
      <Modal.Content>
        {loginFailed ? (
          <p>{getTranslatedMessage('signInAgainFailed')}</p>
        ) : (
          <p>{getTranslatedMessage('signInAgainMessageFTRPRF')}</p>
        )}
        <Form
          id="InputField__TextContainer__Form"
          onSubmit={() => {
            submitFtrprfUser(url, credentials);
          }}
        >
          <Input
            autoFocus
            icon={<Icon name="user" color="black" />}
            iconPosition="left"
            placeholder={getTranslatedMessage('username')}
            name="username"
            value={credentials.username}
            onChange={(event) => {
              credentials.username = event.target.value;
            }}
          />

          <Input
            className="Login__Form__Input"
            type="password"
            placeholder={getTranslatedMessage('password')}
            name="password"
            value={credentials.password}
            icon={<Icon name="lock" color="black" />}
            iconPosition="left"
            onChange={(event) => {
              credentials.password = event.target.value;
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          content={getTranslatedMessage('cancel')}
          onClick={() => {
            ReactDOM.unmountComponentAtNode(container);
            store.dispatch(logOut());
          }}
        />
        <Button
          type="submit"
          form="InputField__TextContainer__Form"
          positive
          icon="checkmark"
          labelPosition="right"
          content="OK"
        />
      </Modal.Actions>
    </Modal>
  );
};

const modalSmartschoolUser = () => {
  return (
    <Modal closeOnDimmerClick={false} className="BasicModal" size="tiny" open dimmer="inverted">
      <Modal.Header>{getTranslatedMessage('signInAgainHeader')}</Modal.Header>
      <Modal.Content>{getTranslatedMessage('signInAgainMessageSmartSchool')}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          content={getTranslatedMessage('cancel')}
          onClick={() => {
            ReactDOM.unmountComponentAtNode(container);
            store.dispatch(logOut());
          }}
        />
        <Button
          autoFocus
          button="submit"
          positive
          icon="checkmark"
          labelPosition="right"
          content="OK"
          onClick={() => {
            ReactDOM.unmountComponentAtNode(container);
            const newWindow = window.open(
              `https://oauth.smartschool.be/OAuth/index/platformchooser?client_id=${process.env.REACT_APP_SMARTSCHOOL_OAUTH_CLIENT_IDENTIFIER}&redirect_uri=${process.env.REACT_APP_SMARTSCHOOL_OAUTH_REFRESH_REDIRECT_URL}&scope=fulluserinfo%20groupinfo%20userinfo&response_type=code`,
              '_blank',
              '',
              true,
            );
            newWindow.focus();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default (url, idp, languageParam) => {
  language = languageParam;
  const ftrprfUser = idp === 'FTRPRF';
  document.body.appendChild(container);
  const modal = ftrprfUser ? modalFtrprfUser(url) : modalSmartschoolUser();
  ReactDOM.render(modal, container);
};
