export const FETCH_SCHOOL_YEARS_START = 'FETCH_SCHOOL_YEARS_START';
export const FETCH_SCHOOL_YEARS_SUCCESS = 'FETCH_SCHOOL_YEARS_SUCCESS';
export const FETCH_SCHOOL_YEARS_ERROR = 'FETCH_SCHOOL_YEARS_ERROR';

export const FETCH_SCHOOL_YEAR_START = 'FETCH_SCHOOL_YEAR_START';
export const FETCH_SCHOOL_YEAR_SUCCESS = 'FETCH_SCHOOL_YEAR_SUCCESS';
export const FETCH_SCHOOL_YEAR_ERROR = 'FETCH_SCHOOL_YEAR_ERROR';

export const ADD_SCHOOL_YEAR_START = 'ADD_SCHOOL_YEAR_START';
export const ADD_SCHOOL_YEAR_SUCCESS = 'ADD_SCHOOL_YEAR_SUCCESS';
export const ADD_SCHOOL_YEAR_ERROR = 'ADD_SCHOOL_YEAR_ERROR';

export const UPDATE_SCHOOL_YEAR_START = 'UPDATE_SCHOOL_YEAR_START';
export const UPDATE_SCHOOL_YEAR_SUCCESS = 'UPDATE_SCHOOL_YEAR_SUCCESS';
export const UPDATE_SCHOOL_YEAR_ERROR = 'UPDATE_SCHOOL_YEAR_ERROR';

export const DELETE_SCHOOL_YEAR_START = 'DELETE_SCHOOL_YEAR_START';
export const DELETE_SCHOOL_YEAR_SUCCESS = 'DELETE_SCHOOL_YEAR_SUCCESS';
export const DELETE_SCHOOL_YEAR_ERROR = 'DELETE_SCHOOL_YEAR_ERROR';
