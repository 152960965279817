export const FETCH_LESSON_START = 'FETCH_LESSON_START';
export const FETCH_LESSON_SUCCESS = 'FETCH_LESSON_SUCCESS';
export const FETCH_LESSON_ERROR = 'FETCH_LESSON_ERROR';

export const DELETE_LESSON_START = 'DELETE_LESSON_START';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_ERROR = 'DELETE_LESSON_ERROR';

export const ADD_LESSON_START = 'ADD_LESSON_START';
export const ADD_LESSON_SUCCESS = 'ADD_LESSON_SUCCESS';
export const ADD_LESSON_ERROR = 'ADD_LESSON_ERROR';

export const UPDATE_LESSON_BY_ID_START = 'UPDATE_LESSON_BY_ID_START';
export const UPDATE_LESSON_BY_ID_SUCCESS = 'UPDATE_LESSON_BY_ID_SUCCESS';
export const UPDATE_LESSON_BY_ID_ERROR = 'UPDATE_LESSON_BY_ID_ERROR';
