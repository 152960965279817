// Set up your root reducer here...
import { combineReducers } from 'redux';
import loggedIn from './auth';
import classGroups from './classGroups';
import exams from './exams';
import studentExams from './studentExams';
import classGroupExams from './classGroupExams';
import lessons from './lessons';
import eduLessons from './eduLessons';
import slides from './slides';
import teachers from './teachers';
import application from './application';
import initialState from './initialState';
import { LOGOUT } from '../types';
import exercises from './exercises';
import students from './students';
import programs from './programs';
import smartSchoolGroups from './smartSchoolGroups';
import schools from './schools';
import classGroupPrograms from './classGroupPrograms';
import classGroupLessons from './classGroupLessons';
import examAnswers from './examAnswers';
import examQuestions from './examQuestions';
import studentExamAnswers from './studentExamAnswers';
import schoolAdmins from './schoolAdmins';
import curricula from './curricula';
import smartschoolConfig from './smartSchoolConfig';
import smartschoolImports from './smartSchoolImports';
import documents from './documents';
import schoolYears from './schoolYears';
import startVersions from './startVersions';

const createAppReducer = (asyncReducer) =>
  combineReducers({
    application,
    classGroupExams,
    classGroupLessons,
    classGroupPrograms,
    classGroups,
    curricula,
    documents,
    eduLessons,
    exams,
    examAnswers,
    examQuestions,
    exercises,
    lessons,
    loggedIn,
    programs,
    schoolAdmins,
    schoolYears,
    schools,
    slides,
    smartSchoolGroups,
    startVersions,
    studentExams,
    studentExamAnswers,
    students,
    teachers,
    smartschoolConfig,
    smartschoolImports,
    ...asyncReducer,
  });

const createReducer = (asyncReducer) => {
  return (state, action) => {
    if (action.type === LOGOUT) {
      state = initialState;
    }

    if (action.type === 'PURGE') {
      state = initialState;
    }

    return createAppReducer(asyncReducer)(state, action);
  };
};

export default createReducer;
