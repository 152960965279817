import { merge, head, values, assign, includes, filter, concat, map } from 'lodash-es';

import {
  LOGIN_START,
  LOGIN_SUCCES,
  LOGIN_ERROR,
  LOGOUT,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
  VALIDATE_TOKEN_START,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  GET_LOGGEDIN_USER_FROM_JWT_START,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_ERROR,
  UPDATE_EULA_START,
  UPDATE_EULA_SUCCESS,
  UPDATE_EULA_ERROR,
  UPDATE_TEACHER_SUCCESS,
  SWITCH_ACTIVE_ROLE_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

import {
  setUser,
  removeUser,
  getUser,
  removeTokenFromLocalStorage,
  removeSelectedClassGroupFromLocalStorage,
  removeSelectedSchoolFromLocalStorage,
  setSelectedClassGroup,
} from '../../api/storage';

function authReducer(state = initialState.loggedIn, action) {
  switch (action.type) {
    case LOGIN_START:
    case GET_LOGGEDIN_USER_FROM_JWT_START:
    case VALIDATE_TOKEN_START:
    case UPDATE_PASSWORD_START:
    case UPDATE_EULA_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case SWITCH_ACTIVE_ROLE_SUCCESS: {
      const loggedIn = {
        ...state.data,
        activeRole: action.data.newRole,
        school: state.data.school ? state.data.school : action.data.school,
      };
      setUser(loggedIn);
      return merge(
        {},
        {
          data: {
            ...state.data,
            activeRole: action.data.newRole,
            school: state.data.school ? state.data.school : action.data.school,
          },
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
      );
    }

    case LOGIN_ERROR:
    case GET_LOGGEDIN_USER_FROM_JWT_ERROR:
    case VALIDATE_TOKEN_ERROR:
      return assign(
        {},
        {
          data: {},
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case UPDATE_PASSWORD_ERROR:
    case UPDATE_EULA_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case LOGIN_SUCCES:
    case UPDATE_EULA_SUCCESS: {
      let loggedIn = head(values(action.data.entities.loggedIn));
      loggedIn = { ...loggedIn, activeRole: loggedIn.role };

      const classGroupIds = Object.keys(action.data.entities.classGroups || {});

      if (classGroupIds.length >= 0) {
        setSelectedClassGroup(classGroupIds[0]);
      }

      setUser(loggedIn);

      return assign({}, state, {
        data: loggedIn,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case GET_LOGGEDIN_USER_FROM_JWT_SUCCES: {
      let loggedIn = head(values(action.data.entities.loggedIn));

      const currentUserInLocalStorage = getUser();

      const activeRole =
        currentUserInLocalStorage && currentUserInLocalStorage.id === loggedIn.id
          ? currentUserInLocalStorage.activeRole
          : loggedIn.role;

      loggedIn = { ...loggedIn, activeRole };
      setUser(loggedIn);
      return assign({}, state, {
        data: loggedIn,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case UPDATE_TEACHER_SUCCESS: {
      const user = getUser();
      if (action.data.result === user.id) {
        const { classGroupPrograms } = action.data.entities.teachers[action.data.result];
        const classGroupProgramIds = map(classGroupPrograms, 'id');
        return assign({}, state, {
          data: merge(action.data.entities.teachers[action.data.result], {
            classGroupPrograms: classGroupProgramIds,
            activeRole: action.data.activeRole,
          }),
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return state;
    }

    case UPDATE_CLASSGROUPPROGRAM_SUCCESS: {
      if (includes(state.data.classGroupPrograms, action.data.result)) {
        if (!action.data.entities.teachers || !action.data.entities.teachers[state.data.id]) {
          const classGroupPrograms = filter(
            state.data.classGroupPrograms,
            (val) => val !== action.data.result,
          );
          return assign({}, state, {
            data: { ...state.data, classGroupPrograms },
            state: stateTypes.LOADED_SUCCESSFUL,
            error: {},
          });
        }
      } else if (action.data.entities.teachers && action.data.entities.teachers[state.data.id]) {
        const classGroupPrograms = concat(state.data.classGroupPrograms, [action.data.result]);
        return assign({}, state, {
          data: { ...state.data, classGroupPrograms },
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return state;
    }

    case LOGOUT: {
      window.location = '#/';
      removeUser();
      removeTokenFromLocalStorage();
      removeSelectedClassGroupFromLocalStorage();
      removeSelectedSchoolFromLocalStorage();
      return state;
    }

    case VALIDATE_TOKEN_SUCCESS: {
      const user = getUser();
      user.validToken = action.data.status;
      setUser(user);
      return assign({}, state, {
        data: user,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case UPDATE_PASSWORD_SUCCESS:
      return merge({}, state, {
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    default:
      return state;
  }
}

export default authReducer;
