import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import App from './App';
import mapActionCreatorsToProps from '../../functions/mapActionCreatorsToProps';
import {
  loginSuccess,
  validateTokenStart,
  validateTokenSuccess,
  validateTokenError,
  logOut,
} from '../../redux/actions/auth';
import { isInitialData, getUser, getLanguage } from '../../selectors';
import { getTokenFromLocalStorage } from '../../api/storage';

const actionCreators = mapActionCreatorsToProps({
  loginSuccess,
  validateTokenStart,
  validateTokenSuccess,
  validateTokenError,
  logOut,
});

const mapStateToProps = (state) => {
  const token = getTokenFromLocalStorage();
  const language = getLanguage(state);
  if (
    !window.location.hash.startsWith('#/logout') &&
    !window.location.hash.startsWith('#/auth/lti') &&
    isInitialData(state.loggedIn.state)
  ) {
    getUser();
  }
  return {
    language,
    data: state.loggedIn.data,
    state: state.loggedIn.state,
    localStorageToken: token,
  };
};

export default withRouter(connect(mapStateToProps, actionCreators)(App));
