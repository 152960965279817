import React from 'react';
import { toUpper, snakeCase } from 'lodash-es';
import { ICONS } from '../../constants/icons';

const FtrprfIcon = ({ name, size = 22, className }) => {
  const convertedIconName = toUpper(snakeCase(name));
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 1024 1024">
      <path d={ICONS[convertedIconName]} />
    </svg>
  );
};
export default FtrprfIcon;
