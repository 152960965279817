export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_SUCCESS = 'FETCH_EXERCISES_SUCCESS';
export const FETCH_EXERCISES_ERROR = 'FETCH_EXERCISES_ERROR';

export const FETCH_EXERCISE_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISE_SUCCESS = 'FETCH_EXERCISES_SUCCESS';
export const FETCH_EXERCISE_ERROR = 'FETCH_EXERCISES_ERROR';

export const FETCH_CLASS_EXERCISES_START = 'FETCH_CLASS_EXERCISES_START';
export const FETCH_CLASS_EXERCISES_SUCCESS = 'FETCH_CLASS_EXERCISES_SUCCESS';
export const FETCH_CLASS_EXERCISES_ERROR = 'FETCH_CLASS_EXERCISES_ERROR';

export const CREATE_EXERCISE_START = 'CREATE_EXERCISE_START';
export const CREATE_EXERCISE_SUCCESS = 'CREATE_EXERCISE_SUCCESS';
export const CREATE_EXERCISE_ERROR = 'CREATE_EXERCISE_ERROR';

export const DELETE_EXERCISE_START = 'DELETE_EXERCISE_START';
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS';
export const DELETE_EXERCISE_ERROR = 'DELETE_EXERCISE_ERROR';
