import React, { useEffect } from 'react';
import { getTokenFromLocalStorage } from '../../api/storage';

const style = {
  color: 'inherit',
};

export const generateNextLink = (to) =>
  `${
    process.env.REACT_APP_NEXT_LINK_URL
  }/callback?redirect=${to}&token=${getTokenFromLocalStorage()}`;

export const NextRedirect = ({ to }) => {
  const href = generateNextLink(to);

  useEffect(() => {
    window.location = href;
  }, [href]);

  return null;
};

export const NextLink = ({ to, children, ...props }) => {
  const href = `${
    process.env.REACT_APP_NEXT_LINK_URL
  }/callback?redirect=${to}&token=${getTokenFromLocalStorage()}`;

  return (
    <a href={href} {...props} onClick={(e) => e.stopPropagation()} style={style}>
      {children}
    </a>
  );
};

export const StudioLink = ({ to, children, ...props }) => {
  const href = `${
    process.env.REACT_APP_STUDIO_LINK_URL
  }/callback?domain=FTRPRF&redirect=${to}&token=${getTokenFromLocalStorage()}`;

  return (
    <a href={href} {...props} onClick={(e) => e.stopPropagation()} style={style}>
      {children}
    </a>
  );
};
