import {
  LOGIN_START,
  LOGIN_SUCCES,
  LOGIN_ERROR,
  LOGOUT,
  VALIDATE_TOKEN_START,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_EULA_START,
  UPDATE_EULA_SUCCESS,
  UPDATE_EULA_ERROR,
  GET_LOGGEDIN_USER_FROM_JWT_START,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_ERROR,
  SWITCH_ACTIVE_ROLE_SUCCESS,
} from '../types';

export function loginStart() {
  return {
    type: LOGIN_START,
  };
}

export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCES,
    data,
  };
}

export function loginError(error, handled = false) {
  return {
    type: LOGIN_ERROR,
    error,
    handled,
  };
}

export function logOut() {
  return { type: LOGOUT };
}

export function validateTokenStart() {
  return {
    type: VALIDATE_TOKEN_START,
  };
}

export function validateTokenSuccess(data) {
  return {
    type: VALIDATE_TOKEN_SUCCESS,
    data,
  };
}

export function validateTokenError(error, handled = false) {
  return {
    type: VALIDATE_TOKEN_ERROR,
    error,
    handled,
  };
}

export function updatePasswordStart() {
  return { type: UPDATE_PASSWORD_START };
}

export function updatePasswordSuccess() {
  return { type: UPDATE_PASSWORD_SUCCESS };
}

export function updatePasswordError(error, handled = false) {
  return { type: UPDATE_PASSWORD_ERROR, error, handled };
}

export function updateEulaStart() {
  return { type: UPDATE_EULA_START };
}

export function updateEulaSuccess(data) {
  return { type: UPDATE_EULA_SUCCESS, data };
}

export function updateEulaError(error, handled = false) {
  return { type: UPDATE_EULA_ERROR, error, handled };
}

export function getLoggedInUserFromJWTStart() {
  return { type: GET_LOGGEDIN_USER_FROM_JWT_START };
}

export function getLoggedInUserFromJWTSuccess(data) {
  return {
    type: GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
    data,
  };
}

export function getLoggedInUserFromJWTError() {
  return { type: GET_LOGGEDIN_USER_FROM_JWT_ERROR };
}

export function switchActiveRoleSuccess(data) {
  return {
    type: SWITCH_ACTIVE_ROLE_SUCCESS,
    data,
  };
}
