export const FETCH_SCHOOLS_START = 'FETCH_SCHOOLS_START';
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const FETCH_SCHOOLS_ERROR = 'FETCH_SCHOOLS_ERROR';

export const FETCH_SCHOOL_BY_ID_START = 'FETCH_SCHOOL_BY_ID_START';
export const FETCH_SCHOOL_BY_ID_SUCCESS = 'FETCH_SCHOOL_BY_ID_SUCCESS';
export const FETCH_SCHOOL_BY_ID_ERROR = 'FETCH_SCHOOL_BY_ID_ERROR';

export const DELETE_SCHOOL_START = 'DELETE_SCHOOL_START';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const DELETE_SCHOOL_ERROR = 'DELETE_SCHOOL_ERROR';

export const ADD_SCHOOL_START = 'ADD_SCHOOL_START';
export const ADD_SCHOOL_SUCCESS = 'ADD_SCHOOL_SUCCESS';
export const ADD_SCHOOL_ERROR = 'ADD_SCHOOL_ERROR';

export const UPDATE_SCHOOL_START = 'UPDATE_SCHOOL_START';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL_ERROR = 'UPDATE_SCHOOL_ERROR';
