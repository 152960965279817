export default {
  INTRODUCTION: 'Introduction',
  EXERCISE: 'Exercise',
  THEORY: 'Theory',
  CLOSURE: 'Closure',
  INDIVIDUALLY: 'Individually',
  CLASS: 'Class',
  MANDATORY: 'Mandatory',
  OPTIONAL: 'Optional',
};
