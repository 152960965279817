export const HOME_URLS = {
  STUDENT_HOME_URL: '/schoolstudentprofile/overview',
  TEACHER_HOME_URL: '/schoolteacherprofile/overview',
  SCHOOL_ADMIN_HOME_URL: '/schooladminprofile/overview',
  ADMIN_HOME_URL: '/adminprofile/overview',

  PROFILEPLACEHOLDER_HOME_URL: '/profileplaceholder/overview',
};

export const ERROR_URLS = {
  EDITOR_ERROR_URL: '/editorprofile/error',
  STUDENT_ERROR_URL: '/schoolstudentprofile/error',
  ADMIN_ERROR_URL: '/adminprofile/error',
  WORKSHOP_STUDENT_ERROR_URL: '/workshopprofile/error',
  SCHOOL_ADMIN_ERROR_URL: '/schooladminprofile/error',
  TEACHER_ERROR_URL: '/schoolteacherprofile/error',

  PROFILEPLACEHOLDER_ERROR_URL: '/profileplaceholder/error',
};

export const PROFILE_URLS = {
  EDITOR_PROFILE_URL: '/editorprofile/profile',
  STUDENT_PROFILE_URL: '/schoolstudentprofile/profile',
  ADMIN_PROFILE_URL: '/adminprofile/profile',
  TEACHER_PROFILE_URL: '/schoolteacherprofile/profile',
  SCHOOL_ADMIN_PROFILE_URL: '/schooladminprofile/profile',
};

export const OTHER_URLS = {
  STUDENT_LESSONS_URL: '/schoolstudentprofile/lessons',
  STUDENT_HACKROOM_URL: '/schoolstudentprofile/hackroom',
  TEACHER_LESSONS_URL: '/schoolteacherprofile/lessons',
  TEACHER_HACKROOM_URL: '/schoolteacherprofile/hackroom',
  SCHOOL_ADMIN_PROGRAMS_URL: '/schooladminprofile/programs',
  ADMIN_PROGRAMS_URL: '/adminprofile/programs',
};
