import { merge, assign, pick, pickBy } from 'lodash-es';
import {
  ADD_CLASSGROUP_SUCCESS,
  UPDATE_CLASSGROUP_SUCCESS,
  FETCH_CLASSGROUPS_SUCCESS,
  CLEAR_CLASSGROUPS,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_CLASSGROUPPROGRAM_ERROR,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPPROGRAM_START,
  FETCH_CLASSGROUPPROGRAMS_SUCCESS,
  FETCH_CLASSGROUPPROGRAMS_START,
  FETCH_CLASSGROUPPROGRAMS_ERROR,
  UPDATE_CLASSGROUPPROGRAM_ERROR,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_START,
  UPDATE_CLASSGROUPPROGRAMS_ERROR,
  UPDATE_CLASSGROUPPROGRAMS_SUCCESS,
  UPDATE_CLASSGROUPPROGRAMS_START,
  LOGIN_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  DELETE_CLASSGROUP_SUCCESS,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
  FETCH_TEACHER_BY_ID_SUCCESS,
} from '../types';

import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function classGroupPrograms(state = initialState.classGroupPrograms, action) {
  switch (action.type) {
    case FETCH_CLASSGROUPPROGRAM_START:
    case UPDATE_CLASSGROUPPROGRAM_START:
    case UPDATE_CLASSGROUPPROGRAMS_START:
    case FETCH_CLASSGROUPPROGRAMS_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_CLASSGROUPPROGRAM_ERROR:
    case UPDATE_CLASSGROUPPROGRAM_ERROR:
    case UPDATE_CLASSGROUPPROGRAMS_ERROR:
    case FETCH_CLASSGROUPPROGRAMS_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case ADD_CLASSGROUP_SUCCESS:
    case FETCH_CLASSGROUPS_SUCCESS:
    case FETCH_CLASSGROUPPROGRAMS_SUCCESS:
    case LOGIN_SUCCES:
    case GET_LOGGEDIN_USER_FROM_JWT_SUCCES:
    case FETCH_TEACHER_BY_ID_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.classGroupPrograms,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    case UPDATE_CLASSGROUP_SUCCESS:
      return assign(
        {},
        {
          data: action.data.entities.classGroupPrograms,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
      );

    case UPDATE_CLASSGROUPPROGRAMS_SUCCESS:
      return assign(
        {},
        {
          data: {
            ...state.data,
            ...action.data.entities.classGroupPrograms,
          },
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
      );

    case UPDATE_CLASSGROUPPROGRAM_SUCCESS:
      return assign(
        {},
        {
          data: {
            ...state.data,
            [action.data.result]: action.data.entities.classGroupPrograms[action.data.result],
          },
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
      );

    case CLEAR_CLASSGROUPS:
      return assign({}, state, {
        data: pick(state.data, action.data.classGroupProgramIds),
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_CLASSGROUP_SUCCESS: {
      return assign({}, state, {
        data: pickBy(state.data, (classGroupProgram) => {
          return classGroupProgram.classGroup !== action.data;
        }),
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }
    default:
      return state;
  }
}

export default classGroupPrograms;
