export const POST_EXAMQUESTION_START = 'POST_EXAMQUESTION_START';
export const POST_EXAMQUESTION_SUCCESS = 'POST_EXAMQUESTION_SUCCESS';
export const POST_EXAMQUESTION_ERROR = 'POST_EXAMQUESTION_ERROR';

export const UPDATE_EXAMQUESTION_START = 'UPDATE_EXAMQUESTION_START';
export const UPDATE_EXAMQUESTION_SUCCESS = 'UPDATE_EXAMQUESTION_SUCCESS';
export const UPDATE_EXAMQUESTION_ERROR = 'UPDATE_EXAMQUESTION_ERROR';

export const DELETE_EXAMQUESTION_START = 'DELETE_EXAMQUESTION_START';
export const DELETE_EXAMQUESTION_SUCCESS = 'DELETE_EXAMQUESTION_SUCCESS';
export const DELETE_EXAMQUESTION_ERROR = 'DELETE_EXAMQUESTION_ERROR';
