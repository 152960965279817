import {
  DemoInfoBarTranslations,
  DialogTranslations,
  NotificationTranslations,
  StyledSlideViewerTranslations,
} from '@ftrprf/tailwind-components';

import slideTags_nl from './v1/slideTags.nl';
import slideTags_en from './v1/slideTags.en';
import errors_nl from './v1/errors.nl';
import errors_en from './v1/errors.en';
import app_en from './v1/app.en';
import app_nl from './v1/app.nl';
import eula_en from './v1/eula.en';
import eula_nl from './v1/eula.nl';

export const supportedLanguages = {
  nl: {
    ...app_nl,
    ...errors_nl,
    ...slideTags_nl,
    ...eula_nl,
    ...DemoInfoBarTranslations.nl,
    ...DialogTranslations.nl,
    ...NotificationTranslations.nl,
    ...StyledSlideViewerTranslations.nl,
  },
  en: {
    ...app_en,
    ...errors_en,
    ...slideTags_en,
    ...eula_en,
    ...DemoInfoBarTranslations.en,
    ...DialogTranslations.en,
    ...NotificationTranslations.en,
    ...StyledSlideViewerTranslations.en,
  },
};

export const defaultLanguage = 'nl';
