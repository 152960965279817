import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Responsive, Label } from 'semantic-ui-react';
import jwtDecode from 'jwt-decode';
import FtrprfIcon from '../FtrprfIcon';
import './ErrorPage.scss';
import { redirectToHomeUrl } from '../../../functions/redirectToUrl';
import tr from '../../../localization';
import logOut from '../../../utils/logOut';
import withModalFunctions from '../Modals/withModalFunctions';
import InformationModal from '../Modals/InformationModal';

const icons = {
  500: 'exploding-computer',
  403: 'whale',
  404: 'unicorn',
  400: 'pacman',
  JavaScript: 'exploding-computer',
};

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    document.title = 'Error';
  }

  getError = () => {
    if (this.props.message) {
      const { message, occuredError } = this.props;
      return {
        message,
        occuredError,
      };
    }
    if (window.history.state) {
      const { message, occuredError } = window.history.state;
      return {
        occuredError,
        message,
      };
    }
    return {
      occuredError: 404,
      message: null,
    };
  };

  renderContent = (type, message) => {
    const { loggedIn, showModal, hideModal } = this.props;
    const { schoolDisabled } = jwtDecode(window.localStorage.getItem('jwtToken'));
    switch (type) {
      case 'JavaScript':
        return (
          <Fragment>
            <h1>
              <b>{tr('oops')}!</b>
            </h1>
            <h2>{tr('errorSomethingWentWrong')}...</h2>
            <h2>
              <Link className="ErrorPage__Message__Link" to={redirectToHomeUrl(loggedIn)}>
                <u>{tr('goBack')}</u>
              </Link>{' '}
              {tr('toTheHomePage')}
            </h2>
            <Label className="ErrorPage__Message__Label">{message || 'JavaScript Error'}</Label>
          </Fragment>
        );
      case 500:
        return (
          <Fragment>
            <h1>
              <b>{tr('oops')}!</b>
            </h1>
            <h2>{tr('errorSomethingWentWrong')}...</h2>
            <h2>
              <Link className="ErrorPage__Message__Link" to={redirectToHomeUrl(loggedIn)}>
                <u>{tr('goBack')}</u>
              </Link>{' '}
              {tr('toTheHomePage')}
            </h2>
            {message ? (
              <Label className="ErrorPage__Message__Label">
                {500}: {message}
              </Label>
            ) : (
              <Label className="ErrorPage__Message__Label">Errorcode 500</Label>
            )}
          </Fragment>
        );
      case 400:
        return (
          <Fragment>
            <h1>
              <b>{tr('woot')}?</b>
            </h1>
            <h2>{tr('pacmanDidNotUnderstandYourMessageEither')}...</h2>
            <h2>
              <Link className="ErrorPage__Message__Link" to={redirectToHomeUrl(loggedIn)}>
                <u>{tr('goBack')}</u>
              </Link>{' '}
              {tr('toTheHomePage')}
            </h2>
            {message ? (
              <Label className="ErrorPage__Message__Label">400: {message}</Label>
            ) : (
              <Label className="ErrorPage__Message__Label">Errorcode 400</Label>
            )}
          </Fragment>
        );
      case 403:
        return (
          <Fragment>
            <h1>
              <b>Just keep swimming</b>
            </h1>
            <h2>Sorry, {tr('youAreNotAuthorizedForThisPage')}...</h2>
            <h2>
              <Link className="ErrorPage__Message__Link" to={redirectToHomeUrl(loggedIn)}>
                <u>{tr('goBack')}</u>
              </Link>{' '}
              {tr('toTheHomePage')}
            </h2>
            {message ? (
              <Label className="ErrorPage__Message__Label">403: {message}</Label>
            ) : (
              <Label className="ErrorPage__Message__Label">Errorcode 403</Label>
            )}
            {schoolDisabled &&
              showModal(InformationModal, {
                header: tr('schoolInactive'),
                message: tr('schoolInactiveMessage'),
                hideModal: () => {
                  hideModal();
                  logOut();
                },
              })}
          </Fragment>
        );
      case 404:
        return (
          <Fragment>
            <h1>
              <b>404</b>
            </h1>
            <h2>
              <i>
                Just like unicorns,
                <br />
                this page does not exist
              </i>
            </h2>
            <h2>
              <Link className="ErrorPage__Message__Link" to={redirectToHomeUrl(loggedIn)}>
                <u>{tr('goBack')}</u>
              </Link>{' '}
              {tr('toTheHomePage')}
            </h2>
          </Fragment>
        );
      default:
        return null;
    }
  };

  render() {
    if (!window.localStorage.getItem('jwtToken')) {
      return logOut();
    }
    const { occuredError, message } = this.getError();
    return (
      <div className="ErrorPage">
        <div className="ErrorPage__Message">
          <Grid divided="vertically">
            <Grid.Column
              className="ErrorPage__Message__IconWrapper"
              mobile={16}
              tablet={16}
              computer={8}
              textAlign="center"
            >
              <FtrprfIcon name={icons[occuredError]} size="28rem" />
            </Grid.Column>
            <Responsive
              as={Grid.Column}
              className="ErrorPage__Message__Text__Left"
              mobile={16}
              tablet={16}
              computer={8}
              minWidth={Responsive.onlyTablet.maxWidth}
            >
              {this.renderContent(occuredError, message)}
            </Responsive>
            <Responsive
              as={Grid.Column}
              className="ErrorPage__Message__Text__Center"
              mobile={16}
              tablet={16}
              computer={8}
              maxWidth={Responsive.onlyTablet.maxWidth}
            >
              {this.renderContent(occuredError, message)}
            </Responsive>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withModalFunctions(ErrorPage);
