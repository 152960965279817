import { merge, assign } from 'lodash-es';
import { FINISH_BOOTSTRAP, UPDATE_ACCORDION_PERSISTANCE, SELECT_LANGUAGE } from '../types';
import initialState from './initialState';

function applicationReducer(state = initialState.application, action) {
  switch (action.type) {
    case FINISH_BOOTSTRAP:
      return { ...state, bootstrapped: true };
    case SELECT_LANGUAGE:
      return merge({}, state, { language: action.language });
    case UPDATE_ACCORDION_PERSISTANCE: {
      return assign({}, state, { accordionPersistance: action.data });
    }
    default:
      return state;
  }
}

export default applicationReducer;
