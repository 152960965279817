import { merge, assign, omit } from 'lodash-es';
import initialState from './initialState';
import * as stateTypes from '../../constants/stateTypes';
import {
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_SUCCESS,
  FETCH_EXERCISES_ERROR,
  FETCH_EXERCISE_START,
  FETCH_EXERCISE_SUCCESS,
  FETCH_EXERCISE_ERROR,
  FETCH_CLASS_EXERCISES_START,
  FETCH_CLASS_EXERCISES_SUCCESS,
  FETCH_CLASS_EXERCISES_ERROR,
  CREATE_EXERCISE_START,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_ERROR,
  DELETE_EXERCISE_START,
  DELETE_EXERCISE_ERROR,
  DELETE_EXERCISE_SUCCESS,
} from '../types';

export default function ExerciseReducer(state = initialState.exercises, action) {
  switch (action.type) {
    case FETCH_EXERCISES_START:
    case CREATE_EXERCISE_START:
    case FETCH_CLASS_EXERCISES_START:
    case FETCH_EXERCISE_START:
      return merge({}, { data: {}, state: stateTypes.LOADING, error: {} });

    case DELETE_EXERCISE_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_EXERCISES_ERROR:
    case CREATE_EXERCISE_ERROR:
    case DELETE_EXERCISE_ERROR:
    case FETCH_CLASS_EXERCISES_ERROR:
    case FETCH_EXERCISE_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_EXERCISE_SUCCESS:
      return merge({
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_EXERCISES_SUCCESS:
      return merge(
        { data: {} },
        {
          data: action.data.entities.exercises,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
      );

    case FETCH_CLASS_EXERCISES_SUCCESS: {
      return merge({}, state, {
        data: action.data.entities.exercises,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case DELETE_EXERCISE_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case CREATE_EXERCISE_SUCCESS:
      return merge({}, state, {
        data: { [action.data.result]: action.data.entities.exercises },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    default:
      return state;
  }
}
