import { includes, isArray } from 'lodash-es';
import * as stateTypes from '../constants/stateTypes';

export const isInitialData = (state) => {
  if (isArray(state)) {
    return includes(state, stateTypes.INITIAL_DATA);
  }
  return state === stateTypes.INITIAL_DATA;
};

export const isLoading = (state) => {
  if (isArray(state)) {
    return includes(state, stateTypes.LOADING);
  }
  return state === stateTypes.LOADING;
};

export const hasError = (state) => {
  if (isArray(state)) {
    return includes(state, stateTypes.ERROR);
  }
  return state === stateTypes.ERROR;
};
