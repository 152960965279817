export const ADD_SCHOOLADMIN_START = 'ADD_SCHOOLADMIN_START';
export const ADD_SCHOOLADMIN_SUCCESS = 'ADD_SCHOOLADMIN_SUCCESS';
export const ADD_SCHOOLADMIN_ERROR = 'ADD_SCHOOLADMIN_ERROR';

export const FETCH_SCHOOLADMINS_START = 'FETCH_SCHOOLADMINS_START';
export const FETCH_SCHOOLADMINS_SUCCESS = 'FETCH_SCHOOLADMINS_SUCCES';
export const FETCH_SCHOOLADMINS_ERROR = 'FETCH_SCHOOLADMINS_ERROR';

export const DELETE_SCHOOLADMIN_START = 'DELETE_SCHOOLADMIN_START';
export const DELETE_SCHOOLADMIN_SUCCESS = 'DELETE_SCHOOLADMIN_SUCCESS';
export const DELETE_SCHOOLADMIN_ERROR = 'DELETE_SCHOOLADMIN_ERROR';

export const UPDATE_SCHOOLADMIN_START = 'UPDATE_SCHOOLADMIN_START';
export const UPDATE_SCHOOLADMIN_SUCCESS = 'UPDATE_SCHOOLADMIN_SUCCESS';
export const UPDATE_SCHOOLADMIN_ERROR = 'UPDATE_SCHOOLADMIN_ERROR';

export const FETCH_SCHOOLADMIN_BY_ID_START = 'FETCH_SCHOOLADMIN_BY_ID_START';
export const FETCH_SCHOOLADMIN_BY_ID_SUCCESS = 'FETCH_SCHOOLADMIN_BY_ID_SUCCESS';
export const FETCH_SCHOOLADMIN_BY_ID_ERROR = 'FETCH_SCHOOLADMIN_BY_ID_ERROR';
