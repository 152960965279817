import { merge, assign, omit } from 'lodash-es';
import initialState from './initialState';
import {
  FETCH_CURRICULA_START,
  FETCH_CURRICULA_SUCCESS,
  FETCH_CURRICULA_ERROR,
  UPDATE_CURRICULUM_START,
  UPDATE_CURRICULUM_SUCCESS,
  UPDATE_CURRICULUM_ERROR,
  DELETE_CURRICULUM_START,
  DELETE_CURRICULUM_ERROR,
  DELETE_CURRICULUM_SUCCESS,
  LOGIN_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  ADD_CURRICULUM_START,
  ADD_CURRICULUM_SUCCESS,
  ADD_CURRICULUM_ERROR,
  FETCH_PROGRAMS_SUCCESS,
  FETCH_TEACHER_BY_ID_SUCCESS,
  FETCH_CLASSGROUPPROGRAMS_SUCCESS,
  ADD_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';

function curricula(state = initialState.curricula, action) {
  switch (action.type) {
    case FETCH_CURRICULA_START:
    case ADD_CURRICULUM_START:
    case UPDATE_CURRICULUM_START:
    case DELETE_CURRICULUM_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_CURRICULA_SUCCESS:
    case LOGIN_SUCCES:
    case GET_LOGGEDIN_USER_FROM_JWT_SUCCES:
    case ADD_CURRICULUM_SUCCESS:
    case FETCH_PROGRAMS_SUCCESS:
    case FETCH_TEACHER_BY_ID_SUCCESS:
    case FETCH_CLASSGROUPPROGRAMS_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.curricula,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_CURRICULA_ERROR:
    case UPDATE_CURRICULUM_ERROR:
    case DELETE_CURRICULUM_ERROR:
    case ADD_CURRICULUM_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case UPDATE_CURRICULUM_SUCCESS:
      return merge({}, state, {
        data: {
          [action.data.result]: action.data.entities.curricula[action.data.result],
        },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_CURRICULUM_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case UPDATE_DOCUMENT_SUCCESS:
    case ADD_DOCUMENT_SUCCESS: {
      if (action.data.entities.documents[action.data.result].linkedType === 'CURRICULUM') {
        const document = action.data.entities.documents[action.data.result];
        state.data[document.linkedId].document = {
          id: document.id,
          name: document.name,
          type: document.type,
        };
        return merge({}, state, {
          data: state.data,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: action.error,
        });
      }
      return state;
    }

    case DELETE_DOCUMENT_SUCCESS: {
      if (state.data[action.data.id]) {
        state.data[action.data.id].document = null;
        return assign({}, state, {
          data: state.data,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: action.error,
        });
      }
      return merge({}, state, { state: stateTypes.LOADED_SUCCESSFUL, error: {} });
    }

    default:
      return state;
  }
}

export default curricula;
