import { normalize } from 'normalizr';
import * as schema from './schema';

export const mapToStudent = (data) => {
  return normalize(data, schema.student);
};

export const mapToStudents = (data) => {
  return normalize(data, [schema.student]);
};
