export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCES = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const VALIDATE_TOKEN_START = 'VALIDATE_TOKEN_START';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_ERROR = 'VALIDATE_TOKEN_ERROR';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const GET_LOGGEDIN_USER_FROM_JWT_START = 'GET_LOGGEDIN_USER_FROM_JWT_START';
export const GET_LOGGEDIN_USER_FROM_JWT_SUCCES = 'GET_LOGGEDIN_USER_FROM_JWT_SUCCES';
export const GET_LOGGEDIN_USER_FROM_JWT_ERROR = 'GET_LOGGEDIN_USER_FROM_JWT_ERROR';

export const UPDATE_EULA_START = 'UPDATE_EULA_START';
export const UPDATE_EULA_SUCCESS = 'UPDATE_EULA_SUCCESS';
export const UPDATE_EULA_ERROR = 'UPDATE_EULA_ERROR';
