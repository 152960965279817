import React, { Component } from 'react';
import { ModalConsumer } from '../../../contextApi/ModalProvider';

const withModalFunctions = (WrappedComponent) => {
  return class extends Component {
    render() {
      return (
        <ModalConsumer>
          {({ showModal, hideModal }) => (
            <WrappedComponent showModal={showModal} hideModal={hideModal} {...this.props} />
          )}
        </ModalConsumer>
      );
    }
  };
};

export default withModalFunctions;
