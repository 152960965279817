import { forEach, find, isArray, trimEnd } from 'lodash-es';
import api from './api/eduApi';
import { mapToLoggedIn } from './mappers/mapToLoggedIn';
import { mapToTeachers } from './mappers/mapToTeachers';
import { mapToStudents } from './mappers/mapToStudents';
import { SCHOOL_ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../constants/roles';

class UserApi {
  static getUserByIdForLoggedIn(userId) {
    return api.get(`/users/${userId}`).then(mapToLoggedIn);
  }

  static getCurrentUser() {
    return api.get('/users/me').then(mapToLoggedIn);
  }

  static resetPassword(userId, body) {
    return api.post(`/users/${userId}/resetPassword`, { body });
  }

  static getUsersByRole(roles, schoolId) {
    let url = schoolId ? `/users?schoolId=${schoolId}&` : '/users?';

    if (isArray(roles)) {
      forEach(roles, (role) => {
        url = this.addRoleIdToUrl(url, role);
      });
    } else {
      url = this.addRoleIdToUrl(url, roles);
    }
    url = trimEnd(url, '&');
    return api.get(url).then((data) => {
      if (roles === STUDENT_ROLE || find(roles, STUDENT_ROLE)) {
        return mapToStudents(data);
      }
      return mapToTeachers(data);
    });
  }

  static addRoleIdToUrl(url, role) {
    const roleId =
      role === STUDENT_ROLE ? 0 : role === TEACHER_ROLE ? 1 : role === SCHOOL_ADMIN_ROLE ? 2 : 3;
    return (url += `roleId=${roleId}&`);
  }

  static deleteUser(userId) {
    return api.delete(`/users/${userId}`);
  }

  static getUserById(id, mapper) {
    return api.get(`/users/${id}`).then((data) => (mapper ? mapper(data) : data));
  }

  static addUser(body, mapper) {
    return api.post('/users', { body }).then(mapper);
  }

  static updateUser(id, body, mapper) {
    return api.put(`/users/${id}`, { body }).then(mapper);
  }

  static updateEula(id, eulaAccepted) {
    return api
      .put(`/users/${id}`, {
        body: {
          eulaAccepted,
        },
      })
      .then((data) => {
        return mapToLoggedIn(data);
      });
  }
}
export default UserApi;
