import { keys, reduce } from 'lodash-es';

const mapActionCreatorsToProps = (actionCreators) => (dispatch) => {
  const actions = reduce(
    keys(actionCreators),
    (acc, key) => {
      acc[key] = (...args) => dispatch(actionCreators[key](...args));

      return acc;
    },
    {},
  );

  return { actions };
};

export default mapActionCreatorsToProps;
