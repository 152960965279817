import { merge, mergeWith, assign } from 'lodash-es';
import initialState from './initialState';
import * as stateTypes from '../../constants/stateTypes';
import { lessonSlidesMerge } from '../../utils/MergeWithCustomizers';

import {
  FETCH_LESSON_START,
  FETCH_LESSON_SUCCESS,
  FETCH_LESSON_ERROR,
  FETCH_LESSON_SLIDES_START,
  FETCH_LESSON_SLIDES_SUCCESS,
  FETCH_LESSON_SLIDES_ERROR,
  ADD_LESSON_START,
  ADD_LESSON_SUCCESS,
  ADD_LESSON_ERROR,
} from '../types';

export default function lessonReducer(state = initialState.lessons, action) {
  switch (action.type) {
    case FETCH_LESSON_START:
    case FETCH_LESSON_SLIDES_START:
    case ADD_LESSON_START:
      return merge({}, state, { data: {}, state: stateTypes.LOADING, error: {} });

    case FETCH_LESSON_ERROR:
    case FETCH_LESSON_SLIDES_ERROR:
    case ADD_LESSON_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_LESSON_SUCCESS:
    case ADD_LESSON_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.lessons,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_LESSON_SLIDES_SUCCESS:
      return mergeWith(
        {},
        state,
        {
          data: action.data.entities.lessons,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
        lessonSlidesMerge,
      );

    default:
      return state;
  }
}
