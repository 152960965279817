import React from 'react';
import { Dimmer, Loader as SemanticLoader } from 'semantic-ui-react';
import tr from '../../localization';

const Loader = ({ active = true, message = tr('loading') }) => {
  return (
    <Dimmer active={active}>
      <SemanticLoader size="medium">{message}</SemanticLoader>
    </Dimmer>
  );
};

export default Loader;
