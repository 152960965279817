import { merge, assign, omit } from 'lodash-es';
import {
  FETCH_STUDENTEXAM_SUCCESS,
  FETCH_EXAM_SUCCESS,
  POST_EXAMANSWER_START,
  POST_EXAMANSWER_SUCCESS,
  POST_EXAMANSWER_ERROR,
  UPDATE_EXAMANSWER_START,
  UPDATE_EXAMANSWER_SUCCESS,
  UPDATE_EXAMANSWER_ERROR,
  DELETE_EXAMANSWER_START,
  DELETE_EXAMANSWER_SUCCESS,
  DELETE_EXAMANSWER_ERROR,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function examAnswers(state = initialState.examAnswers, action) {
  switch (action.type) {
    case POST_EXAMANSWER_START:
    case UPDATE_EXAMANSWER_START:
    case DELETE_EXAMANSWER_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case POST_EXAMANSWER_ERROR:
    case UPDATE_EXAMANSWER_ERROR:
    case DELETE_EXAMANSWER_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_STUDENTEXAM_SUCCESS:
    case FETCH_EXAM_SUCCESS:
    case UPDATE_EXAMANSWER_SUCCESS:
    case POST_EXAMANSWER_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.examAnswers,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_EXAMANSWER_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data.answerId),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    default:
      return state;
  }
}

export default examAnswers;
