import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import moment from 'moment';

const tr = (id, values) => <FormattedMessage id={id} values={values} />;

export const dateTime = (date) => {
  const newDate = moment(date);
  return (
    <>
      <FormattedDate value={newDate} /> <FormattedMessage id="at" />{' '}
      <FormattedTime value={newDate} />
    </>
  );
};

export const date = (oldDate) => {
  const newDate = moment(oldDate);
  return <FormattedDate value={newDate} />;
};

export default tr;
