import { merge, omit, assign, map, mergeWith, without, pick } from 'lodash-es';
import { normalize } from 'normalizr';
import * as schema from '../../api/mappers/schema';
import {
  LOGIN_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_SUCCESS,
  FETCH_PROGRAMS_ERROR,
  DELETE_PROGRAM_START,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_ERROR,
  FETCH_PROGRAM_BY_ID_START,
  FETCH_PROGRAM_BY_ID_SUCCESS,
  FETCH_PROGRAM_BY_ID_ERROR,
  UPDATE_PROGRAM_START,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_ERROR,
  ADD_PROGRAM_START,
  ADD_PROGRAM_SUCCESS,
  ADD_PROGRAM_ERROR,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  UPDATE_CLASSGROUP_SUCCESS,
  FETCH_SCHOOLS_SUCCESS,
  FETCH_SCHOOL_BY_ID_SUCCESS,
  DELETE_LESSON_SUCCESS,
  FETCH_CLASSGROUPS_SUCCESS,
  FETCH_EXAM_SUCCESS,
  FETCH_EXAMS_SUCCESS,
  CLEAR_CLASSGROUPS,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPPROGRAMS_SUCCESS,
  ADD_CLASSGROUP_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
  REORDER_PROGRAMS_SUCCESS,
  REORDER_PROGRAMS_START,
  REORDER_PROGRAMS_ERROR,
  ADD_LESSON_SUCCESS,
  FETCH_TEACHER_BY_ID_SUCCESS,
  ADD_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function programs(state = initialState.programs, action) {
  switch (action.type) {
    case FETCH_PROGRAMS_START:
    case FETCH_PROGRAM_BY_ID_START:
    case DELETE_PROGRAM_START:
    case UPDATE_PROGRAM_START:
    case ADD_PROGRAM_START:
    case REORDER_PROGRAMS_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_PROGRAMS_ERROR:
    case FETCH_PROGRAM_BY_ID_ERROR:
    case ADD_PROGRAM_ERROR:
    case DELETE_PROGRAM_ERROR:
    case REORDER_PROGRAMS_ERROR:
    case UPDATE_PROGRAM_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case LOGIN_SUCCES:
    case GET_LOGGEDIN_USER_FROM_JWT_SUCCES:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case UPDATE_CLASSGROUP_SUCCESS:
    case ADD_PROGRAM_SUCCESS:
    case UPDATE_PROGRAM_SUCCESS:
    case FETCH_SCHOOLS_SUCCESS:
    case FETCH_SCHOOL_BY_ID_SUCCESS:
    case FETCH_PROGRAM_BY_ID_SUCCESS:
    case ADD_CLASSGROUP_SUCCESS:
    case FETCH_EXAM_SUCCESS:
    case FETCH_EXAMS_SUCCESS:
    case UPDATE_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_CLASSGROUPPROGRAMS_SUCCESS:
    case FETCH_TEACHER_BY_ID_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.programs,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_CLASSGROUPS_SUCCESS:
      return mergeWith(
        {},
        state,
        {
          data: action.data.entities.programs,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        },
        (a, b) => (b === null ? a : undefined),
      );

    case FETCH_PROGRAMS_SUCCESS:
    case REORDER_PROGRAMS_SUCCESS:
      return assign({}, state, {
        data: action.data.entities.programs,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case CLEAR_CLASSGROUPS:
      return assign({}, state, {
        data: pick(state.data, action.data.selectedPrograms),
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_PROGRAM_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case DELETE_LESSON_SUCCESS: {
      const newState = normalize(
        map(state.data, (program) => {
          if (program.id === action.data.programId) {
            return {
              ...program,
              lessons: without(program.lessons, action.data.lessonId),
              exams: without(program.exams, action.data.lessonId),
            };
          }
          return program;
        }),
        [schema.program],
      );
      return assign(
        {},
        {
          data: newState.entities.programs,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: action.error,
        },
      );
    }

    case ADD_LESSON_SUCCESS:
      if (action.data.result.type === 'LESSON') {
        state.data[action.data.programId].lessons.push(action.data.result);
      }
      return assign(
        {},
        {
          data: state.data,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: action.error,
        },
      );

    case UPDATE_DOCUMENT_SUCCESS:
    case ADD_DOCUMENT_SUCCESS: {
      if (action.data.entities.documents[action.data.result].linkedType === 'PROGRAM') {
        const document = action.data.entities.documents[action.data.result];
        state.data[document.linkedId].document = {
          id: document.id,
          name: document.name,
          type: document.type,
        };
        return merge({}, state, {
          data: state.data,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: action.error,
        });
      }
      return merge({}, state, { state: stateTypes.LOADED_SUCCESSFUL, error: {} });
    }

    case DELETE_DOCUMENT_SUCCESS: {
      if (state.data[action.data.id]) {
        state.data[action.data.id].document = null;
        return assign({}, state, {
          data: state.data,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: action.error,
        });
      }
      return merge({}, state, { state: stateTypes.LOADED_SUCCESSFUL, error: {} });
    }

    default:
      return state;
  }
}

export default programs;
