import { assign, merge, omit } from 'lodash-es';
import {
  FETCH_SCHOOL_YEARS_START,
  FETCH_SCHOOL_YEARS_SUCCESS,
  FETCH_SCHOOL_YEARS_ERROR,
  FETCH_SCHOOL_YEAR_START,
  FETCH_SCHOOL_YEAR_SUCCESS,
  FETCH_SCHOOL_YEAR_ERROR,
  ADD_SCHOOL_YEAR_START,
  ADD_SCHOOL_YEAR_SUCCESS,
  ADD_SCHOOL_YEAR_ERROR,
  UPDATE_SCHOOL_YEAR_START,
  UPDATE_SCHOOL_YEAR_SUCCESS,
  UPDATE_SCHOOL_YEAR_ERROR,
  DELETE_SCHOOL_YEAR_START,
  DELETE_SCHOOL_YEAR_SUCCESS,
  DELETE_SCHOOL_YEAR_ERROR,
} from '../types';

import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function schoolYears(state = initialState.schoolYears, action) {
  switch (action.type) {
    case FETCH_SCHOOL_YEARS_START:
      return assign({}, { data: {}, state: stateTypes.LOADING, error: {} });

    case FETCH_SCHOOL_YEAR_START:
    case ADD_SCHOOL_YEAR_START:
    case UPDATE_SCHOOL_YEAR_START:
    case DELETE_SCHOOL_YEAR_START:
      return merge({}, state, {
        data: {},
        state: stateTypes.LOADING,
        error: {},
      });

    case FETCH_SCHOOL_YEARS_SUCCESS:
    case FETCH_SCHOOL_YEAR_SUCCESS:
    case UPDATE_SCHOOL_YEAR_SUCCESS:
    case ADD_SCHOOL_YEAR_SUCCESS: {
      return merge({}, state, {
        data: action.data.entities.schoolYear,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case DELETE_SCHOOL_YEAR_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case FETCH_SCHOOL_YEARS_ERROR:
    case FETCH_SCHOOL_YEAR_ERROR:
    case ADD_SCHOOL_YEAR_ERROR:
    case UPDATE_SCHOOL_YEAR_ERROR:
    case DELETE_SCHOOL_YEAR_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    default:
      return state;
  }
}

export default schoolYears;
