import { normalize } from 'normalizr';
import * as schema from './schema';

export const mapToTeacher = (data) => {
  return normalize(data, schema.teacher);
};

export const mapToTeachers = (data) => {
  return normalize(data, [schema.teacher]);
};
