import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import ErrorPage from '../ErrorPage';

class ErrorBoundary extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error }) => (
          <ErrorPage occuredError="JavaScript" message={error && error.message} />
        )}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
