export const FETCH_PROGRAMS_START = 'FETCH_PROGRAMS_START';
export const FETCH_PROGRAMS_SUCCESS = 'FETCH_PROGRAMS_SUCCESS';
export const FETCH_PROGRAMS_ERROR = 'FETCH_PROGRAMS_ERROR';

export const DELETE_PROGRAM_START = 'DELETE_PROGRAM_START';
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_ERROR = 'DELETE_PROGRAM_ERROR';

export const FETCH_PROGRAM_BY_ID_START = 'FETCH_PROGRAM_BY_ID_START';
export const FETCH_PROGRAM_BY_ID_SUCCESS = 'FETCH_PROGRAM_BY_ID_SUCCESS';
export const FETCH_PROGRAM_BY_ID_ERROR = 'FETCH_PROGRAM_BY_ID_ERROR';

export const UPDATE_PROGRAM_START = 'UPDATE_PROGRAM_START';
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_ERROR = 'UPDATE_PROGRAM_ERROR';

export const ADD_PROGRAM_START = 'ADD_PROGRAM_START';
export const ADD_PROGRAM_SUCCESS = 'ADD_PROGRAM_SUCCESS';
export const ADD_PROGRAM_ERROR = 'ADD_PROGRAM_ERROR';

export const REORDER_PROGRAMS_START = 'REORDER_PROGRAMS_START';
export const REORDER_PROGRAMS_SUCCESS = 'REORDER_PROGRAMS_SUCCESS';
export const REORDER_PROGRAMS_ERROR = 'REORDER_PROGRAMS_ERROR';
