import { merge, assign } from 'lodash-es';
import initialState from './initialState';
import * as stateTypes from '../../constants/stateTypes';
import {
  FETCH_STARTVERSIONS_START,
  FETCH_STARTVERSIONS_SUCCESS,
  FETCH_STARTVERSIONS_ERROR,
} from '../types';

export default function ExerciseReducer(state = initialState.startVersions, action) {
  switch (action.type) {
    case FETCH_STARTVERSIONS_START:
      return merge({}, { data: {}, state: stateTypes.LOADING, error: {} });

    case FETCH_STARTVERSIONS_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.startVersions,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_STARTVERSIONS_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    default:
      return state;
  }
}
