import axios from 'axios';
import { merge, replace, includes } from 'lodash-es';
import jwtDecode from 'jwt-decode';
import { store } from '../../redux/store/store';
import { logOut } from '../../redux/actions/auth';
import { getTokenFromLocalStorage, setTokenToLocalStorage, getUser } from '../storage';
import { redirectToErrorUrl } from '../../functions/redirectToUrl';

const TIMEOUT = 20000; // 20s
export default (url, endpoint, { headers = {}, body, ...options } = {}, method) => {
  let allHeaders = {
    'Content-Type': 'application/json;charset=UTF-8',
  };

  const token = getTokenFromLocalStorage();

  if (token) {
    allHeaders.Authorization = `Bearer ${token}`;
  }

  allHeaders = merge(allHeaders, headers);

  return axios(`${url}${endpoint}`, {
    ...options,
    headers: allHeaders,
    timeout: options.timeout ? options.timeout : TIMEOUT,
    data: body ? JSON.stringify(body) : undefined,
    method,
  })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return undefined;
    })
    .catch((error) => {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    });
};

const requestNonStringified = (url, endpoint, { headers = {}, body, ...options }, method) => {
  let allHeaders = {
    'Content-Type': 'application/json;charset=UTF-8',
  };

  const token = getTokenFromLocalStorage();

  if (token) {
    allHeaders.Authorization = `Bearer ${token}`;
  }

  allHeaders = merge(allHeaders, headers);

  return axios(`${url}${endpoint}`, {
    ...options,
    headers: allHeaders,
    timeout: options.timeout ? options.timeout : TIMEOUT,
    data: body,
    method,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    });
};
export { requestNonStringified };

export const oAuthRequest = (url, endpoint, { headers = {}, body, ...options }, method) => {
  headers = {
    'Access-Control-Allow-Origin': '*',
    ...headers,
  };

  return axios(`${replace(url, '/api', '')}${endpoint}`, {
    headers,
    timeout: options.timeout ? options.timeout : TIMEOUT,
    data: body,
    method,
  });
};
// Set token to storage if present &&  Global exception handler on requestError
axios.interceptors.response.use(
  (res) => {
    const { authorization } = res.headers;
    if (authorization) {
      setTokenToLocalStorage(authorization);
    }
    return res;
  },
  (err) => {
    if (err.response) {
      const user = getUser();
      const message = err.response.data.message ? err.response.data.message : null;
      const { authorization } = err.response.headers;
      let url;
      if (authorization) {
        setTokenToLocalStorage(authorization);
      }
      if (includes([403, 500], err.response.status)) {
        url = redirectToErrorUrl(user);
        window.location.href = url;
        window.history.pushState({ occuredError: err.response.status, message }, null);
      } else {
        throw err.response.data;
      }
    } else {
      throw err;
    }
  },
);

axios.interceptors.request.use((config) => {
  const token = getTokenFromLocalStorage();
  if (token && !includes(config.url, 'authenticate')) {
    const decodedToken = jwtDecode(token);
    const timeInSeconds = new Date().getTime() / 1000;
    if (decodedToken && decodedToken.exp < timeInSeconds) {
      store.dispatch(logOut());
    }
  }
  return config;
});
