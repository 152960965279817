/* eslint-disable max-len */
// ===========================================================================
// This file contains all translation id's for the application
// Naming convention: camelCase combined with snake_case when using a parameter"
// Example: helloWorld_Name: "Hello, world {name}" translates to "Hello, world Eric" when provided with the name parameter
//
// All translations should be lowercase for concistency and fallback messages when language is not found
// Case conversion should be done in code
//
// For more detailed examples visit https://formatjs.io/docs/react-intl
//
// Usage is possible in 2 ways
// 1. tr() function in localization/index.js can be used to return a component that can be used in JSX
// 2. Wrap component with the 'injectIntl()' gives it a 'intl' variable in the props,
//    this can be used as intl.formatMessage('helloWorld') and returns a string
// ===========================================================================

export default {
  AUTHENTICATION_USER_DOES_NOT_EXIST_WITH_GIVEN_CREDENTIALS:
    'Geen juiste gebruiker/wachtwoord combinatie',
  AUTHENTICATION_USER_NOT_ACTIVE: 'Gebruiker is niet actief',
  AUTHENTICATION_USER_IMPERSONATE_CHILD_NOT_FOUND: 'Impersonate account werd niet gevonden',
  AUTHENTICATION_USER_IMPERSONATE_WORKSHOPUSER_NOT_ALLOWED:
    'Impersonate op workshop account niet toegestaan',
  SESSION_USER_EXPIRED: 'Sessie verlopen',
  SESSION_INVALID_ROLE: 'Geen rechten om deze actie uit te voeren',
  APPLICATION_COURSE_NOT_FOUND: 'Lessenreeks niet gevonden',
  APPLICATION_CHILD_NOT_FOUND: 'Kind niet gevonden',
  UNKNOWN: 'Kind of leraar niet gevonden',
  APPLICATION_INVALID_REQUEST: 'Invalid request',
  APPLICATION_SERVICE_UNAVAILABLE: 'Service not available',
  APPLICATION_NOT_ALLOWED_TO_ACCESS: 'Niet toegestaan om deze content te zien',
  BLOCKED_USER_FORBIDDEN: 'Je account is niet actief',
  BYPASSING_WORKSHOP_USERS: 'Impersonate van een workshop gebruiker is niet toegelaten',
  BYPASS_ONLY_ON_LESSON_DAY: 'Impersonate is niet toegelaten',
  CHILD_NOT_FOUND: 'Gebruiker niet gevonden',
  BYPASSED_USER_NOT_FOUND: 'Impersonate niet toegelaten voor deze gebruiker',
  BYPASS_FORBIDDEN: 'Impersonate niet toegelaten',
  USER_WITHOUT_ACTIVE_COURSE_FORBIDDEN: 'Gebruiker is niet actief',
  USER_DOES_NOT_EXIST: 'Geen juiste gebruiker/wachtwoord combinatie',
  TEACHER_WITH_CREDENTIALS_NOT_FOUND: 'Gebruiker niet gevonden',
  USERNAME_MISSING: 'Gebruikersnaam ontbreekt',
  FAILED_DESTROYING_SESSION_TOKEN: 'Failed to destroy session token',
  PASSWORD_MISSING: 'Wachtwoord ontbreekt',
  EDITING_ONLY_OWN_PROFILE_ALLOWED: 'Actie niet toegelaten',
  TEACHER_NOT_FOUND: 'Gebruiker niet gevonden',
  NOT_TEACHERS_TOKEN: 'Token is niet geldig',
  SIGNED_PDF_MISSING: 'Parameter ontbreekt',
  DATA_MISSING: 'Data ontbreekt',
  FILE_NAME_MISSING: 'Bestandnaam ontbreekt',
  CHANGING_VALIDATED_ATTENDANCE_FORBIDDEN:
    'Aanwezigheid kan niet meer aangepast worden, de betaling ervan is al gevalideerd',
  TEACHER_ID_MISSING: 'Parameter ontbreekt',
  LESSON_ID_MISSING: 'Parameter ontbreekt',
  ARRIVAL_TIME_PARAMETER_INVALID: 'Aankomstuur is ongeldig',
  DEPARTURE_TIME_PARAMETER_INVALID: 'Vertrekuur is ongeldig',
  LESSON_NOT_FOUND: 'Les is niet gevonden',
  TEACHER_NOT_ASSIGNED_TO_LESSON: 'Leraar is niet toegekend aan deze les',
  CONTENT_MISSING: 'Inhoud ontbreekt',
  NOTE_NOT_FOUND: 'Nota niet gevonden',
  EDITING_ONLY_OWN_NOTES_ALLOWED: 'Nota mag niet aangepast worden ',
  EDITING_NOTE_ALLOWED_FIXED_TIME: 'Nota mag niet meer aangepast worden',
  OBJECT_ID_MISSING: 'Parameter object_id ontbreekt',
  OBJECT_TYPE_MISSING: 'Parameter object_type ontbreekt',
  OBJECT_TYPE_INVALID: 'Parameter object_type is niet geldig',
  OBJECT_NOT_FOUND: 'Object ontbreekt',
  CHILD_ID_MISSING: 'Parameter child_id ontbreekt',
  VALIDATION_INVALIDBANKACCOUNT: 'Incorrect bankaccount',
  SESSION_UNALLOWED_TO_OPEN_LESSON: 'Niet toegestaan om les te openen',
  APPLICATION_LESSON_CLOSED: 'Les niet beschikbaar',
  NOT_ALLOWED_TO_DELETE_ANSWER: 'Antwoord mag niet verwijderd worden',
  EXERCISE_NOT_FOUND: 'Oefening niet gevonden',
  NOT_ABLE_TO_PUBLISH_LESSON:
    'Les kan niet gepubliceerd worden, er is al een andere les gepubliceerd',
  EXERCISE_LINKED_WITH_LESSON: 'Deze oefening is nog gekoppeld aan een les',
  EXERCISE_HAS_START_VERSION: 'Er is nog een startversie gekoppeld aan deze oplossing',
  DATABASE_ERROR: 'Onbekende database error',
  ABSENCE_CANNOT_BE_CHANGED_BECAUSE_REPLACEMENT_IS_FORESEEN:
    'Afwezigheid kan niet worden gewijzigd omdat vervanging is voorzien',
  USERNAME_NOT_AVAILABLE: 'Er is al een gebruiker met dezelfde gebruikersnaam in de database',
  ENTITY_NOT_FOUND_USER: 'Gebruiker niet gevonden',
  ENTITY_NOT_FOUND_SCHOOL: 'School niet gevonden',
  ENTITY_NOT_FOUND_CLASSGROUP: 'Klas niet gevonden',
  ENTITY_NOT_FOUND_LESSON: 'Les niet gevonden',
  ENTITY_NOT_FOUND_PROGRAM: 'Programma niet gevonden',
  ENTITY_NOT_FOUND_CURRICULUM: 'Curriculum niet gevonden',
  ENTITY_NOT_FOUND_DOCUMENT: 'Document niet gevonden',
  ENTITY_NOT_FOUND_EXAM: 'Toets niet gevonden',
  ENTITY_NOT_FOUND_EXAMQUESTION: 'Vraag niet gevonden',
  ENTITY_NOT_FOUND_EXAMANSWER: 'Antwoord niet gevonden',
  ENTITY_NOT_FOUND_CLASSHASPROGRAM: 'Klas met dit programma niet gevonden',

  PROGRAM_HAS_EXAM: 'Dit programma is gelinkt aan 1 of meerdere examens',
  PROGRAM_STILL_IN_USE: 'Dit programma is in gebruik',
  CLASS_GROUP_HAS_NO_HACKROOM: 'Elke klas moet gelinkt zijn aan minstens 1 Hack Room',
  ILLEGAL_CHARACTER_IN_FILE_NAME: 'Bestandsnaam bevat een ongeldig teken.',
  authenticationErrorHeader: 'Aanmelden mislukt',
  fileTooBig_maxSize: 'Kies een bestand met een grootte kleiner dan {maxSize}',
  wrongFileType: 'Dit type bestand is niet ondersteund',
  somethingWentWrongDocument: 'Er ging iets mis tijdens het toevoegen van je document',
  illegalCharacter: 'Er zit een verboden teken in de naam',
};
