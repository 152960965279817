import { merge, assign } from 'lodash-es';
import {
  FETCH_CLASSGROUPEXAM_START,
  FETCH_CLASSGROUPEXAM_ERROR,
  FETCH_CLASSGROUPEXAM_SUCCESS,
  UPDATE_CLASSGROUPEXAM_START,
  UPDATE_CLASSGROUPEXAM_ERROR,
  UPDATE_CLASSGROUPEXAM_SUCCESS,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  UPDATE_CLASSGROUP_LESSON_SUCCESS,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
  FETCH_STUDENTEXAM_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function classGroupExams(state = initialState.classGroupExams, action) {
  switch (action.type) {
    case FETCH_CLASSGROUPEXAM_START:
    case UPDATE_CLASSGROUPEXAM_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_CLASSGROUPEXAM_ERROR:
    case UPDATE_CLASSGROUPEXAM_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case FETCH_CLASSGROUPEXAM_SUCCESS:
    case UPDATE_CLASSGROUP_LESSON_SUCCESS:
    case UPDATE_CLASSGROUPEXAM_SUCCESS:
    case UPDATE_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_STUDENTEXAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.classGroupExams,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    default:
      return state;
  }
}

export default classGroupExams;
