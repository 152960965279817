import { isEmpty } from 'lodash-es';
import * as roles from '../constants/roles';
import * as urls from '../constants/userUrls';
import * as navigationParts from '../constants/navigationParts';

export const redirectToHomeUrl = (loggedIn) => {
  const redirectTo = {
    [roles.STUDENT_ROLE]: urls.HOME_URLS.STUDENT_HOME_URL,
    [roles.TEACHER_ROLE]: urls.HOME_URLS.TEACHER_HOME_URL,
    [roles.ADMIN_ROLE]: urls.HOME_URLS.ADMIN_HOME_URL,
    [roles.SCHOOL_ADMIN_ROLE]: !isEmpty(loggedIn.classGroups)
      ? urls.HOME_URLS.TEACHER_HOME_URL
      : urls.HOME_URLS.SCHOOL_ADMIN_HOME_URL,
  };

  if (redirectTo[loggedIn.activeRole]) {
    return redirectTo[loggedIn.activeRole];
  }
  return '/';
};

export const redirectToErrorUrl = (loggedIn) => {
  const error = {
    [roles.STUDENT_ROLE]: `/#${urls.ERROR_URLS.STUDENT_ERROR_URL}`,
    [roles.TEACHER_ROLE]: `/#${urls.ERROR_URLS.TEACHER_ERROR_URL}`,
    [roles.ADMIN_ROLE]: `/#${urls.ERROR_URLS.ADMIN_ERROR_URL}`,
    [roles.SCHOOL_ADMIN_ROLE]: !isEmpty(loggedIn.classGroups)
      ? `/#${urls.ERROR_URLS.TEACHER_ERROR_URL}`
      : `/#${urls.ERROR_URLS.SCHOOL_ADMIN_ERROR_URL}`,
  };
  if (error[loggedIn.role]) {
    return error[loggedIn.role];
  }
  return '/';
};

export const redirectToProfileUrl = (loggedIn) => {
  const redirectTo = {
    [roles.ADMIN_ROLE]: urls.PROFILE_URLS.ADMIN_PROFILE_URL,
    [roles.STUDENT_ROLE]: urls.PROFILE_URLS.STUDENT_PROFILE_URL,
    [roles.TEACHER_ROLE]: urls.PROFILE_URLS.TEACHER_PROFILE_URL,
    [roles.SCHOOL_ADMIN_ROLE]: !isEmpty(loggedIn.classGroups)
      ? urls.PROFILE_URLS.TEACHER_PROFILE_URL
      : urls.PROFILE_URLS.SCHOOL_ADMIN_PROFILE_URL,
  };

  if (redirectTo[loggedIn.activeRole]) {
    return redirectTo[loggedIn.activeRole];
  }
  return '/';
};

export const redirectToParentUrl = (loggedIn, parent) => {
  const redirectTo = {
    // Default redirects to home
    [roles.STUDENT_ROLE]: urls.HOME_URLS.STUDENT_HOME_URL,
    [roles.TEACHER_ROLE]: urls.HOME_URLS.TEACHER_HOME_URL,
    [roles.ADMIN_ROLE]: urls.HOME_URLS.ADMIN_HOME_URL,
    [roles.SCHOOL_ADMIN_ROLE]: !isEmpty(loggedIn.classGroups)
      ? urls.HOME_URLS.TEACHER_HOME_URL
      : urls.HOME_URLS.SCHOOL_ADMIN_HOME_URL,
    // Redirects to lessons
    [`${roles.STUDENT_ROLE}${navigationParts.LESSONS}`]: urls.OTHER_URLS.STUDENT_LESSONS_URL,
    [`${roles.TEACHER_ROLE}${navigationParts.LESSONS}`]: urls.OTHER_URLS.TEACHER_LESSONS_URL,
    [`${roles.SCHOOL_ADMIN_ROLE}${navigationParts.LESSONS}`]: !isEmpty(loggedIn.classGroups)
      ? urls.OTHER_URLS.TEACHER_LESSONS_URL
      : urls.OTHER_URLS.SCHOOL_ADMIN_PROGRAMS_URL,
    // Redirects to programs
    [`${roles.ADMIN_ROLE}${navigationParts.PROGRAMS}`]: urls.OTHER_URLS.ADMIN_PROGRAMS_URL,
    [`${roles.SCHOOL_ADMIN_ROLE}${navigationParts.PROGRAMS}`]: !isEmpty(loggedIn.classGroups)
      ? urls.OTHER_URLS.TEACHER_LESSONS_URL
      : urls.OTHER_URLS.SCHOOL_ADMIN_PROGRAMS_URL,
    // Redirects to hackrooms
    [`${roles.STUDENT_ROLE}${navigationParts.HACKROOM}`]: urls.OTHER_URLS.STUDENT_HACKROOM_URL,
    [`${roles.TEACHER_ROLE}${navigationParts.HACKROOM}`]: urls.OTHER_URLS.TEACHER_HACKROOM_URL,
    [`${roles.SCHOOL_ADMIN_ROLE}${navigationParts.HACKROOM}`]: !isEmpty(loggedIn.classGroups)
      ? urls.OTHER_URLS.TEACHER_HACKROOM_URL
      : urls.OTHER_URLS.SCHOOL_ADMIN_PROGRAMS_URL,
  };

  if (redirectTo[`${loggedIn.activeRole}${parent}`]) {
    return redirectTo[`${loggedIn.activeRole}${parent}`];
  }
  return '/';
};
