/* eslint no-underscore-dangle : 0 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logoutOnSessionExpired from './logoutSessionExpired';
import createReducer from '../reducers';

const middleware = [thunk, logoutOnSessionExpired];

const reducer = createReducer();

function configureStoreProd(initialState) {
  const store = createStore(reducer, initialState, applyMiddleware(...middleware));
  return { store };
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStoreDev(initialState) {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
  return { store };
}

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
