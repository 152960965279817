export const FETCH_SMARTSCHOOL_CONFIG_ERROR = 'FETCH_SMARTSCHOOL_CONFIG_ERROR';
export const FETCH_SMARTSCHOOL_CONFIG_SUCCESS = 'FETCH_SMARTSCHOOL_CONFIG_SUCCESS';
export const FETCH_SMARTSCHOOL_CONFIG_START = 'FETCH_SMARTSCHOOL_CONFIG_START';

export const UPDATE_SMARTSCHOOL_CONFIG_ERROR = 'UPDATE_SMARTSCHOOL_CONFIG_ERROR';
export const UPDATE_SMARTSCHOOL_CONFIG_SUCCESS = 'UPDATE_SMARTSCHOOL_CONFIG_SUCCESS';
export const UPDATE_SMARTSCHOOL_CONFIG_START = 'UPDATE_SMARTSCHOOL_CONFIG_START';

export const DELETE_SMARTSCHOOL_CONFIG_ERROR = 'DELETE_SMARTSCHOOL_CONFIG_ERROR';
export const DELETE_SMARTSCHOOL_CONFIG_SUCCESS = 'DELETE_SMARTSCHOOL_CONFIG_SUCCESS';
export const DELETE_SMARTSCHOOL_CONFIG_START = 'DELETE_SMARTSCHOOL_CONFIG_START';
