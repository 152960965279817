import { merge, assign, omit, filter, map, keyBy } from 'lodash-es';
import initialState from './initialState';
import {
  ADD_DOCUMENT_START,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_ERROR,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_ERROR,
  DELETE_DOCUMENT_START,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  FETCH_PROGRAMS_SUCCESS,
  FETCH_PROGRAM_BY_ID_SUCCESS,
  FETCH_CURRICULA_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';

function documents(state = initialState.documents, action) {
  switch (action.type) {
    case ADD_DOCUMENT_START:
    case UPDATE_DOCUMENT_START:
    case DELETE_DOCUMENT_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case ADD_DOCUMENT_ERROR:
    case UPDATE_DOCUMENT_ERROR:
    case DELETE_DOCUMENT_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case ADD_DOCUMENT_SUCCESS:
    case UPDATE_DOCUMENT_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.documents,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_DOCUMENT_SUCCESS:
      return assign(
        {},
        {
          data: omit(state.data, action.data),
          state: stateTypes.DELETE_SUCCESSFUL,
          error: {},
        },
      );

    case FETCH_PROGRAM_BY_ID_SUCCESS: {
      let newState = state;
      if (action.data.entities.programs[action.data.result].document) {
        const programDoc = action.data.entities.programs[action.data.result].document;
        const doc = {
          [programDoc.id]: { ...programDoc, linkedId: action.data.result, linkedType: 'PROGRAM' },
        };
        newState = merge({}, newState, {
          data: doc,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      if (action.data.entities.programs[action.data.result].lessons) {
        const lessonDocs = keyBy(
          map(filter(action.data.entities.eduLessons, 'document'), (lesson) => {
            const {
              document: { id, name, type },
              id: linkedId,
            } = lesson;
            return { id, name, type, linkedId, linkedType: 'LESSON' };
          }),
          'id',
        );
        newState = merge({}, newState, {
          data: lessonDocs,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return merge({}, newState, { state: stateTypes.LOADED_SUCCESSFUL, error: {} });
    }

    case FETCH_CURRICULA_SUCCESS: {
      const docsFromCurricula = keyBy(
        map(filter(action.data.entities.curricula, 'document'), (curriculum) => {
          const {
            document: { id, name, type },
            id: linkedId,
          } = curriculum;
          return {
            id,
            name,
            type,
            linkedId,
            linkedType: 'CURRICULUM',
          };
        }),
        'id',
      );
      return merge({}, state, {
        data: docsFromCurricula,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    case FETCH_PROGRAMS_SUCCESS: {
      const docsFromPrograms = keyBy(
        map(filter(action.data.entities.programs, 'document'), (program) => {
          const {
            document: { id, name, type },
            id: linkedId,
          } = program;
          return {
            id,
            name,
            type,
            linkedId,
            linkedType: 'PROGRAM',
          };
        }),
        'id',
      );
      return merge({}, state, {
        data: docsFromPrograms,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    default:
      return state;
  }
}

export default documents;
