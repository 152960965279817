import {
  removeUser,
  removeTokenFromLocalStorage,
  removeSelectedClassGroupFromLocalStorage,
  removeSelectedSchoolFromLocalStorage,
} from '../api/storage';

const logOut = (event) => {
  if (event) event.preventDefault();
  removeUser();
  removeTokenFromLocalStorage();
  removeSelectedClassGroupFromLocalStorage();
  removeSelectedSchoolFromLocalStorage();
  window.location = process.env.REACT_APP_LOG_OUT_URL || '/';
};

export default logOut;
