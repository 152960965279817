import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import withModalFunctions from './withModalFunctions';

const InformationModal = ({ header, message, hideModal, size = 'mini' }) => {
  return (
    <Modal
      size={size}
      open
      onClose={() => {
        hideModal();
      }}
      closeOnDimmerClick
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button autoFocus type="submit" content="Ok" onClick={hideModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default withModalFunctions(InformationModal);
