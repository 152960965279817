import { merge, assign } from 'lodash-es';
import {
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_STUDENTEXAM_ERROR,
  FETCH_STUDENTEXAM_START,
  FETCH_STUDENTEXAM_SUCCESS,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPEXAM_SUCCESS,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
  SUBMIT_STUDENT_EXAM_ERROR,
  SUBMIT_STUDENT_EXAM_START,
  SUBMIT_STUDENT_EXAM_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function studentExams(state = initialState.studentExams, action) {
  switch (action.type) {
    case SUBMIT_STUDENT_EXAM_START:
    case FETCH_STUDENTEXAM_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case SUBMIT_STUDENT_EXAM_ERROR:
    case FETCH_STUDENTEXAM_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case FETCH_STUDENTEXAM_SUCCESS:
    case SUBMIT_STUDENT_EXAM_SUCCESS:
    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_CLASSGROUPEXAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.studentExams,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    default:
      return state;
  }
}

export default studentExams;
