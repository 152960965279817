import api from './api/eduApi';
import { mapToLoggedIn } from './mappers/mapToLoggedIn';

class AuthApi {
  static login(body) {
    return api
      .post('/users/authenticate', {
        body,
      })
      .then(mapToLoggedIn);
  }

  static async OAuth(code) {
    return api.getOAuth(`/oauth?=code${code}`);
  }
}

export default AuthApi;
