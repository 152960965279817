/* eslint-disable max-len */
// ===========================================================================
// This file contains all translation id's for the application
// Naming convention: camelCase combined with snake_case when using a parameter"
// Example: helloWorld_Name: "Hello, world {name}" translates to "Hello, world Eric" when provided with the name parameter
//
// All translations should be lowercase for concistency and fallback messages when language is not found
// Case conversion should be done in code
//
// For more detailed examples visit https://formatjs.io/docs/react-intl
//
// Usage is possible in 2 ways
// 1. tr() function in localization/index.js can be used to return a component that can be used in JSX
// 2. Wrap component with the 'injectIntl()' gives it a 'intl' variable in the props,
//    this can be used as intl.formatMessage('helloWorld') and returns a string
// ===========================================================================

export default {
  AUTHENTICATION_USER_DOES_NOT_EXIST_WITH_GIVEN_CREDENTIALS: 'Invalid credentials',
  AUTHENTICATION_USER_NOT_ACTIVE: 'User inactive',
  AUTHENTICATION_USER_IMPERSONATE_CHILD_NOT_FOUND: 'Impersonate user not found',
  AUTHENTICATION_USER_IMPERSONATE_WORKSHOPUSER_NOT_ALLOWED:
    'Impersonate not allowed for workshop account',
  SESSION_USER_EXPIRED: 'Session expired',
  SESSION_INVALID_ROLE: 'Incorrect access right to execute this action',
  APPLICATION_COURSE_NOT_FOUND: 'Lessenreeks niet gevonden',
  APPLICATION_CHILD_NOT_FOUND: 'Child not found',
  UNKNOWN: 'Child or teacher not found',
  APPLICATION_INVALID_REQUEST: 'Invalid request',
  APPLICATION_SERVICE_UNAVAILABLE: 'Service not available',
  APPLICATION_NOT_ALLOWED_TO_ACCESS: 'Not allowed to access this content',

  BLOCKED_USER_FORBIDDEN: 'Blocked users are not allowed',
  BYPASSING_WORKSHOP_USERS: 'Bypassing workshop users is not allowed',
  BYPASS_ONLY_ON_LESSON_DAY: 'Bypassing only allowed on lesson day',
  CHILD_NOT_FOUND: 'Child is not found',
  BYPASSED_USER_NOT_FOUND: 'Bypassed user is not found',
  BYPASS_FORBIDDEN: 'Bypass is not allowed',
  USER_WITHOUT_ACTIVE_COURSE_FORBIDDEN: 'Users without active courses are not allowed',
  USER_DOES_NOT_EXIST: 'User does not exist with given credentials',
  TEACHER_WITH_CREDENTIALS_NOT_FOUND: 'Teacher does not exist with given credentials',
  USERNAME_MISSING: 'No username provided',
  FAILED_DESTROYING_SESSION_TOKEN: 'Failed to destroy session token',
  PASSWORD_MISSING: 'Password parameter missing or invalid',
  EDITING_ONLY_OWN_PROFILE_ALLOWED: 'Editing only your profile is allowed',
  TEACHER_NOT_FOUND: 'Teacher not found',
  NOT_TEACHERS_TOKEN: 'Provided token does not belong to a teacher',
  SIGNED_PDF_MISSING: 'signed_pdf parameter missing or invalid',
  DATA_MISSING: 'Data parameter missing or invalid',
  FILE_NAME_MISSING: 'File_name parameter missing or invalid',
  CHANGING_VALIDATED_ATTENDANCE_FORBIDDEN:
    'Aanwezigheid kan niet meer aangepast worden, de betaling voor deze aanwezigheid is al gevalideerd',
  TEACHER_ID_MISSING: 'Teacher_id parameter missing or invalid',
  LESSON_ID_MISSING: 'Lesson_id parameter missing or invalid',
  ARRIVAL_TIME_PARAMETER_INVALID: 'Arrival_time parameter invalid',
  DEPARTURE_TIME_PARAMETER_INVALID: 'Departure_time parameter invalid',
  LESSON_NOT_FOUND: 'Lesson not found',
  TEACHER_NOT_ASSIGNED_TO_LESSON: 'Defined teacher has no assignment for defined lesson',
  CONTENT_MISSING: 'Content parameter missing or invalid',
  NOTE_NOT_FOUND: 'Note not found',
  EDITING_ONLY_OWN_NOTES_ALLOWED: 'Editing only your own notes is allowed',
  EDITING_NOTE_ALLOWED_FIXED_TIME:
    'Editing a note is only allowed for fixed time since its creation',
  OBJECT_ID_MISSING: 'Object_id parameter missing',
  OBJECT_TYPE_MISSING: 'Object_type parameter missing',
  OBJECT_TYPE_INVALID: 'Object_type parameter invalid',
  OBJECT_NOT_FOUND: 'Object not found',
  CHILD_ID_MISSING: 'Child_id parameter missing or invalid',
  VALIDATION_INVALIDBANKACCOUNT: 'Invalid bankaccount',
  SESSION_UNALLOWED_TO_OPEN_LESSON: 'Not allowed to view lesson',
  APPLICATION_LESSON_CLOSED: 'Lesson unavailable',
  NOT_ALLLOWED_TO_DELETE_ANSWER: "Answer can't be deleted",
  EXERCISE_NOT_FOUND: 'Exercise not found',
  NOT_ABLE_TO_PUBLISH_LESSON:
    "Lesson couldn't be published, another lesson has already been published",
  EXERCISE_LINKED_WITH_LESSON: 'This exercise is still linked to a lesson',
  EXERCISE_HAS_START_VERSION: 'This solution still has a start version linked',
  DATABASE_ERROR: 'Not recognized database error',
  ABSENCE_CANNOT_BE_CHANGED_BECAUSE_REPLACEMENT_IS_FORESEEN:
    'Absence cannot be changed because replacement is foreseen',
  USERNAME_NOT_AVAILABLE: 'There already is a user with the same username in the database',
  ENTITY_NOT_FOUND_USER: 'User not found',
  ENTITY_NOT_FOUND_SCHOOL: 'School not found',
  ENTITY_NOT_FOUND_CLASSGROUP: 'Classgroup not found',
  ENTITY_NOT_FOUND_LESSON: 'Lesson not found',
  ENTITY_NOT_FOUND_PROGRAM: 'Program not found',
  ENTITY_NOT_FOUND_CURRICULUM: 'Curriculum not found',
  ENTITY_NOT_FOUND_DOCUMENT: 'Document not found',
  ENTITY_NOT_FOUND_EXAM: 'Exam not found',
  ENTITY_NOT_FOUND_EXAMQUESTION: 'Question not found',
  ENTITY_NOT_FOUND_EXAMANSWER: 'Answer not found',
  ENTITY_NOT_FOUND_CLASSHASPROGRAM: 'Class with program not found',
  EXAM_PDF_DOESNT_EXIST: 'Exam pdf does not exist',

  PROGRAM_HAS_EXAM: 'There are exam(s) linked to this program',
  PROGRAM_STILL_IN_USE: 'This program is still in use',
  CLASS_GROUP_HAS_NO_HACKROOM: 'A class group needs at least one Hack Room.',
  ILLEGAL_CHARACTER_IN_FILE_NAME: 'There is an illegal character in your file name.',
  authenticationErrorHeader: 'Sign in failed',
  fileTooBig_maxSize: 'This file is too large, choose a file with size smaller than {maxSize}',
  wrongFileType: 'This type of file is not supported',
  somethingWentWrongDocument: 'Something went wrong with uploading the document',
  illegalCharacter: "There's an illegal character in the name",
};
