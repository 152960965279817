import { connect } from 'react-redux';
import ErrorPage from './ErrorPage';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.loggedIn.data,
  };
};

export default connect(mapStateToProps)(ErrorPage);
