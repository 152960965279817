import request, { requestNonStringified, oAuthRequest } from './baseRequest';
import { eduUrl as url } from './baseUrl';

const eduApi = {
  get(endpoint, options = {}) {
    return request(url, endpoint, options, 'get');
  },
  getOAuth(endpoint, options = {}) {
    return oAuthRequest(url, endpoint, options, 'get');
  },
  post(endpoint, options = {}) {
    return request(url, endpoint, options, 'post');
  },
  postNonStringified(endpoint, options = {}) {
    return requestNonStringified(url, endpoint, options, 'post');
  },
  put(endpoint, options = {}) {
    return request(url, endpoint, options, 'put');
  },
  putNonStringified(endpoint, options = {}) {
    return requestNonStringified(url, endpoint, options, 'put');
  },
  delete(endpoint, options = {}) {
    return request(url, endpoint, options, 'delete');
  },
};

export default eduApi;
