export const ADD_DOCUMENT_START = 'ADD_DOCUMENT_START';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_ERROR = 'ADD_DOCUMENT_ERROR';

export const GET_DOCUMENT_START = 'GET_DOCUMENT_START';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR';

export const UPDATE_DOCUMENT_START = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR';

export const DELETE_DOCUMENT_START = 'DELETE_DOCUMENT_START';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';
