/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */

import React, { Component, createContext } from 'react';
import { noop, merge } from 'lodash-es';

const ThemeContext = createContext({
  setTheme: noop,
  theme: {},
});

export class ThemeProvider extends Component {
  setTheme = (theme) => {
    this.setState((prevState) => ({
      theme: merge(prevState.theme, theme),
    }));
  };

  state = {
    theme: {
      rootClass: 'default',
    },
    setTheme: this.setTheme,
  };

  render() {
    return <ThemeContext.Provider value={this.state}>{this.props.children}</ThemeContext.Provider>;
  }
}

export const ThemeConsumer = ThemeContext.Consumer;
