import { assign, merge, set } from 'lodash-es';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';
import {
  FETCH_LESSON_SLIDES_START,
  FETCH_LESSON_SLIDES_SUCCESS,
  FETCH_LESSON_SLIDES_ERROR,
  INSERT_QUESTION_ANSWER_START,
  INSERT_QUESTION_ANSWER_SUCCESS,
  INSERT_QUESTION_ANSWER_ERROR,
  DELETE_QUESTION_ANSWER_START,
  DELETE_QUESTION_ANSWER_SUCCESS,
  DELETE_QUESTION_ANSWER_DENIED,
} from '../types';

export default function slidesReducer(state = initialState.slides, action) {
  switch (action.type) {
    case FETCH_LESSON_SLIDES_START:
    case INSERT_QUESTION_ANSWER_START:
    case DELETE_QUESTION_ANSWER_START:
      return merge({}, state, { data: {}, state: stateTypes.LOADING, error: {} });

    case FETCH_LESSON_SLIDES_ERROR:
    case INSERT_QUESTION_ANSWER_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_LESSON_SLIDES_SUCCESS:
      return assign({}, state, {
        data: action.data.entities.slides,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case INSERT_QUESTION_ANSWER_SUCCESS:
      return merge({}, state, {
        data: { [action.data.id]: action.data },
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_QUESTION_ANSWER_SUCCESS:
      set(state, `data${action.data.id}`, action.data);
      return merge({}, state, {
        data: {},
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case DELETE_QUESTION_ANSWER_DENIED:
      return merge({}, state, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    default:
      return state;
  }
}
