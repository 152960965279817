/* eslint-disable max-len */
// ===========================================================================
// This file contains all translation id's for the application
// Naming convention: camelCase combined with snake_case when using a parameter"
// Example: helloWorld_Name: "Hello, world {name}" translates to "Hello, world Eric" when provided with the name parameter
//
// All translations should be lowercase for concistency and fallback messages when language is not found
// Case conversion should be done in code
//
// For more detailed examples visit https://formatjs.io/docs/react-intl
//
// Usage is possible in 2 ways
// 1. tr() function in localization/index.js can be used to return a component that can be used in JSX
// 2. Wrap component with the 'injectIntl()' gives it a 'intl' variable in the props,
//    this can be used as intl.formatMessage('helloWorld') and returns a string
// ===========================================================================

export default {
  // admin
  deleteAnswer: 'Verwijder antwoord',
  correctAnswer: 'Juist antwoord',
  allAnswersRequired: 'Alle antwoorden vereist',
  question: 'Vraag',
  answers: 'Antwoorden',
  errorsOccured: 'Er zijn fouten opgetreden',
  editQuestion: 'Wijzig vraag',
  addNewQuestion: 'Voeg nieuwe vraag toe',
  questionAndAnswersUpdated: 'Vraag en antwoorden bijgewerkt',
  questionAndAnswersAdded: 'Vraag en antwoorden toegevoegd',
  youNeedToFillInTheQuestion: 'U moet de vraag invullen',
  everyAnswerNeedsContent: 'Elk antwoord heeft inhoud nodig',
  youNeedAtleastAnswers_Count: 'U heeft minimaal {count} antwoorden nodig',
  youNeedAtleastCorrectAnswers_Count: 'U moet minimaal {count} correct antwoord hebben',
  amountOfAnswers: 'Aantal antwoorden',
  amountOfCorrectAnswers: 'Aantal juiste antwoorden',
  actions: 'Acties',
  addQuestion: 'Vraag toevoegen',
  deleteQuestion: 'Verwijder vraag',
  areYouSureYouWantToDeleteThisQuestion: 'Weet je zeker dat je deze vraag wilt verwijderen?',
  saveExamAndBackToOverview: 'Toets opslaan en terug naar overzicht',
  addExamAndBackToOverview: 'Toets toevoegen en terug naar overzicht',
  saveExamAndMakeQuestion: 'Toets opslaan en vraag maken',
  addExamAndMakeQuestion: 'Toets toevoegen en vraag maken',
  examTitle: 'Titel van de toets',
  chooseLinkedProgram: 'Kies gekoppelde programma',
  chooseLinkedSchoolYear: 'Kies schooljaar',
  filterOnSchoolyear: 'Filter op schooljaar',
  editExam: 'Toets bewerken',
  addExam: 'Toets toevoegen',
  copyExam: 'Toets kopiëren',
  linkedProgram: 'Gelinkt programma',
  schoolYear: 'Schooljaar',
  maxScore: 'Maximum score',
  examInUse: 'Reeds in gebruik',
  previewExam: 'Bekijk toets',
  deleteExam: 'Verwijder toets',
  cannotDeleteWhileLinkedToPrograms:
    "Je kan de toets niet verwijderen terwijl het gekoppeld is aan programma's",
  editQuestions: 'Vragen bewerken',
  areYouSureYouWantToDeleteThisExam: 'Weet je zeker dat je deze toets wilt verwijderen?',
  questionsOf: 'Vragen van {name}',
  clickOnAnIconToCopyTheIconname: 'Klik op een pictogram om de naam te kopiëren',
  searchIcons: 'Zoek pictogrammen',
  search: 'Zoeken',
  addCurriculum: 'Curriculum toevoegen',
  theNameOfTheNewCurriculum: 'De naam van het nieuwe curriculum',
  theNameOfTheNewProgram: 'De naam van het nieuwe programma',
  addSchool: 'School toevoegen',
  theNameOfTheNewSchool: 'De naam van de nieuwe school',
  nameOfTheSchool: 'Naam van de school',
  theUsernamePrefixOfTheNewSchool: 'Het voorvoegsel van de gebruikersnaam van de nieuwe school',
  firstNameOfTheSchoolAdmin: 'Voornaam van de schoolbeheerder',
  lastNameOfTheSchoolAdmin: 'Achternaam van de schoolbeheerder',
  schooladminAddedToNewSchool: 'Schooladmin toegevoegd aan nieuwe school',
  usernameAndPassword_Username_Password: 'Gebruikersnaam: {username} en wachtwoord: {password}',
  usernameAndPassword_Username:
    'De gebruiker is aangemaakt en kan zich aanmelden met zijn Smartschool-logingegevens.{br}{br}De gebruikersnaam is: {username}{br}{br}Controleer of deze FTRPRF-gebruikersnaam exact dezelfde is als die in SmartSchool, anders zal de gebruiker niet kunnen inloggen.{br}{br}Of om het in programmeertermen te zeggen:{br}ALS usernameFTRPRF NIET dezelfde is als usernameSmartSchool DAN kan gebruiker NIET inloggen.',
  ex: 'vb. {name}',
  defaultPublishState: 'Standaard status',
  youCannotDeleteACurriculumThatHasPrograms: "Je kan geen curriculum met programma's verwijderen",
  reorderPrograms: "Programma's herordenen",
  unusedCurricula: 'Ongebruikte curricula',
  unorderedPrograms: "Ongeordende programma's",
  orderedPrograms: "Geordende programma's",
  reorder: 'Herordenen',
  areYouSureYouWantToDelete_Name: 'Weet je zeker dat je {name} wilt verwijderen?',
  programIsStillInUse_Name:
    'Programma {name} is nog steeds in gebruik door één of meerdere klassen',
  examIsStillInUse_Name: 'Toets { name } is nog steeds in gebruik door één of meerdere klassen',
  examIsStillInUseDescription:
    'Leerlingen hebben deze toets reeds gebruikt. Let op dat de aanpassingen niets veranderen aan de essentie van deze toets. Enkel taal correcties en dergelijke.',
  examIsInUseAndCannotBeDeleted: 'Deze toets is in gebruik en kan niet verwijderd worden.',
  examIsInUseSoThisQuestionCannotBeDeleted:
    'Deze toets is in gebruik dus deze vraag kan niet verwijderd worden.',
  examIsInUseSoThisAnswerCannotBeDeleted:
    'Deze toets is in gebruik dus dit antwoord kan niet verwijderd worden.',
  examIsInUseSoThisFieldCannotBeChanged:
    'Deze toets is in gebruik dus dit veld kan niet gewijzigd worden.',
  succesfullyReorderedPrograms: "Programma's succesvol hergeordend",
  saveSchoolAdmin: 'Schoolbeheerder opslaan',
  addSchoolAdmin: 'Schoolbeheerder toevoegen',
  schoolAdminAdded: 'Schoolbeheerder toegevoegd',
  editSchoolAdmin: 'Schoolbeheerder bewerken',
  chooseThePrograms: "Kies de programma's",
  addProgramsToSchool: "Voeg de programma's toe aan de school",
  deleteSchoolAdmin: 'Schoolbeheerder verwijderen',
  details: 'Gegevens',
  detailsOfSchool: 'Gegevens van {school} ({prefix})',
  schoolAdmins: 'Schoolbeheerders',
  schoolName: 'School naam',
  areYouSureYouWantToDeleteTheProgram_Name:
    'Weet je zeker dat je het programma {name} wilt verwijderen? Toetsen en lessen zijn niet meer toegankelijk.',
  areYouSureYouWantToChangeProgramToHackroom_Name:
    "Ben je zeker dat je het type van het programma {name} wil veranderen naar 'Standard'? Er zijn mogelijk klassen gelinkt aan deze Hack Room, deze links zullen verwijderd worden.",
  usernamePrefix: 'Voorvoegsel',
  idp: 'idp',
  stats: 'Statistieken',
  nrOfPrograms: "Aantal programma's",
  nrOfTeachers: 'Aantal leraren',
  nrOfStudents: 'Aantal leerlingen',
  editSchool: 'School bewerken',
  manageSchool: 'School beheren',
  deleteSchool: 'School verwijderen',
  icons: 'Iconen',
  copiedToClipboard: 'Gekopieerd naar het klembord',
  unableToWriteToClipboard: 'Kan niet naar het klembord schrijven',
  nameOfProgram: 'Naam van het programma',
  nameOfDocument: 'Naam van het bestand',
  currentAttachedDocument: 'Huidige bestand',
  uploadAFile: 'Voeg een bestand toe. Enkel deze types zijn ondersteund: {docs}',
  noDocumentAttached: 'Er is geen bestand toegevoegd',
  thisLessonImmediatelyDisappearsFromAllClassListsWhereThisProgramIsUsedAreYouSureYouWantToDelete_Lesson:
    'Deze les zal onmiddelijk verdwijnen van alle klaslijsten waar deze les gebruikt wordt, ben je zeker dat je {lesson} wil verwijderen?',
  addFile: 'Voeg bestand toe',
  propagateStatusChanges: 'Veranderingen les status doorvoeren',
  doYouWantToPropagateTheseStatusChanges:
    'Wil je deze veranderingen aan de les status doorvoeren naar alle bestaande versies van deze les? Let op: indien de leraar de status van de les al veranderd heeft zal de status van de les terug gedefault worden naar je huidige keuze.',
  downloadExamPdf: 'Download toets overzicht',
  cannotDownloadWhenNotAvailable:
    'Je kan geen pdf downloaden van een toets dat niet beschikbaar is',
  active: 'Actief',
  activate: 'Activeren',
  deactivate: 'Deactiveren',
  areYouSureActivate:
    'Ben je zeker dat je deze school wil activeren? Gebruikers kunnen zich aanmelden en het platform gebruiken.',
  areYouSureDeactivate:
    'Ben je zeker dat je deze school wil deactiveren? Gebruikers kunnen zich niet meer aanmelden en het platform gebruiken.',
  schoolYears: 'Schooljaren',
  startDate: 'Begindatum',
  endDate: 'Einddatum',
  addSchoolYear: 'Voeg een schooljaar toe',
  editSchoolYearInfo: 'Pas dit schooljaar aan',
  deleteSchoolYearInfo: 'Verwijder dit schooljaar',
  updateSchoolYear: 'Pas schooljaar aan',
  setActive: 'Zet als actief',
  startDateEmpty: 'Voeg een startdatum toe.',
  endDateEmpty: 'Voeg een einddatum toe.',
  areYouSureDeleteSchoolYear:
    'Ben je zeker dat je dit schooljaar wil verwijderen? Dit kan enkel als niets gekoppeld is aan dit schooljaar.',
  areYouSureUpdateSchoolYear:
    'Ben je zeker dat je dit schooljaar wil aanpassen? Dit schooljaar actief zetten zal een ander schooljaar deactiveren.',
  current: 'huidig',

  // schoolAdmin
  addClass: 'Klas toevoegen',
  giveClassName: 'Geef de klasnaam',
  noProgramsAvailableForThisClass: "Geen programma's beschikbaar voor deze klas",
  saveProgram: 'Programma opslaan',
  chooseTheTeachers: 'Kies de leraren',
  teachers: 'Leerkrachten',
  editProgramOfClass: 'Programma bewerken van de klas',
  addProgramOfClass: 'Programma toevoegen aan klas',
  class: 'Klas',
  classGroupName: 'Naam van de klasgroep',
  className: 'Naam van de klas',
  deleteStudent: 'Verwijder leerling',
  editProgram: 'Programma bewerken',
  editPrograms: "Programma's voor deze klas bewerken",
  deleteProgram: 'Verwijder programma',
  numberOfStudents: 'Aantal leerlingen',
  numberOfAssignedPrograms: "Aantal toegewezen programma's",
  editClass: 'Klas bewerken',
  deleteClass: 'Klas verwijderen',
  classGroups: 'Klasgroepen',
  editClassGroups: 'Klasgroepen bewerken',
  classesOf: 'Klassen van {name}',
  smartSchoolURLShouldBeInTheFollowingFormat: 'Smartschool-URL moet de volgende indeling hebben',
  webservicePassword: 'Webservice wachtwoord',
  add: 'Toevoegen',
  editConfiguration: 'Configuratie bewerken',
  addConfiguration: 'Configuratie toevoegen',
  generaSettingsOf: 'Algemene instellingen van {name}',
  importStudentsTeachersClasses: 'leerlingen/docenten/klassen importeren',
  disableSmartSchoolIntegration: 'Schakel Smartschool integratie uit',
  enableSmartSchoolIntegration: 'Schakel Smartschool integratie in',
  deleteSmartSchoolIntegration: 'Verwijder Smartschool integratie',
  allStudentsAndTeachersWhoPreviouslySigned:
    'Alle leerlingen en docenten die zich eerder hebben aangemeld via Smartschool kunnen niet meer inloggen op FTRPRF. Weet je het zeker?',
  notAllowedToDoSmartSchoolIntegration:
    'Je kan de Smartschool-integratie niet uitvoeren omdat je bent ingelogd met een Smartschool-account.{br}Log in met een account van FTRPRF om de integratie te (her)starten.',
  homeVersionOpen: 'Thuis versie open',
  viewLesson: 'Bekijk les',
  noProgramsAvailableYet: "Nog geen programma's beschikbaar",
  programsOf: "Programma's van {name}",
  import: 'Importeer',
  andAllHisUnderlyingGroups: 'en al zijn onderliggende groepen',
  parentnodeIsOfTypeStudent: 'Ouderknoop is van het type leerling, dus dit kan geen leraar zijn',
  parentnodeIsOfTypeTeacher: 'Ouderknoop is van het type leraar dus dit kan geen leerling zijn',
  thereAreGroupsThatHaveNoCode:
    'Er zijn groepen die geen code hebben op Smartschool, dus we kunnen uw Smartschool niet importeren. Voeg een code toe aan de volgende groepen of klassen',
  chooseGroupsYouWantToImport: 'Kies groepen die je wilt importeren',
  addAllProgramsToAllImportedClasses: "Voeg alle programma's toe aan alle geïmporteerde klassen",
  importClassgroups: 'Importeer klasgroepen',
  warningExistingTeachersStudentsClasses:
    'Waarschuwing: bestaande docenten, leerlingen en klassen worden verwijderd. Wil je doorgaan?',
  editStudent: 'Leerling bewerken',
  addstudent: 'Leerling toevoegen',
  warningUseTheUsernameFromSmartschool:
    'Opgelet: gebruik dezelfde gebruikersnaam van Smartschool om dit account te kunnen integreren.',
  classes: 'Klassen',
  chooseTheClasses: 'Kies de klassen',
  studentAdded: 'Leerling toegevoegd',
  studentsAdded: 'Leerlingen toevgevoegd',
  studentsOf: 'Leerlingen van {name}',
  saveTeacher: 'Leerkracht opslaan',
  addTeacher: 'Leerkracht toevoegen',
  teacherAdded: 'Leerkracht toegevoegd',
  teachersAdded: 'Leerkrachten toegevoegd',
  createdOn: 'Geïmporteerd op',
  schoolAdmin: 'Schoolbeheerder',
  chooseTheClassGroupPrograms: "Kies de klasgroepprogramma's",
  classGroupPrograms: "Klasgroepprogramma's",
  newTeacher: 'Nieuwe leerkracht',
  teachersOf: 'Leerkrachten van {name}',
  deleteTeacher: 'Verwijder leerkracht',
  editTeacher: 'Leerkracht bewerken',
  editTeachers: 'Leerkrachten bewerken',
  youNeedToChangeYourPasswordInYourProfile: 'Je moet jouw wachtwoord in je profiel wijzigen',
  youCantDeleteYourself: 'Je kunt jezelf niet verwijderen',
  general: 'Algemeen',
  generalSettingsOf: 'Algemene instellingen van {name}',
  importUsersAs: 'Importeer gebruikers als',
  usersImportedAsTeacher: 'Gebruikers zijn geïmporteerd als leraren',
  usersImportedAsStudent: 'Gebruikers zijn geïmporteerd als leerlingen',
  SubgroupsMaybeImported: 'Gebruikers in subgroepen kunnen geïmporteerd zijn',
  noUsersImported: 'Gebruikers zijn niet geïmporteerd',
  overviewOfImported: 'Dit is een overzicht van een vorige import van Smartschool',
  inSmartSchoolTheFieldCodeShouldBeFilledInForThisGroup:
    'In Smartschool dient het veld "code" ingevuld te worden voor deze groep',
  andAllUnderlyingGroups: 'en alle onderliggende groepen',
  uniqueCode: 'Unieke smartschool code',
  cannotImport: 'Importeren niet mogelijk',
  none: 'geen',
  thisCouldTakeAFewMinutes: 'Dit kan enkele minuten duren',
  noTeachersAssigned: 'geen leerkrachten toegewezen',
  cannotLinkTeacherToHackroom: 'leerkrachten linken aan Hack Rooms gebeurt automatisch',
  userData: 'Gegevens',
  usernameAndPassword: 'Gebruikersnaam: "{username}" en wachtwoord: "{password}".',
  youCanAddProgramsLater: "Programma's kunnen worden toegekend na het aanmaken van de leerkracht.",
  editLinkedClassgroupprograms: "Wijzig gelinkte classgroupprogramma's",
  editTeacherdetails: 'Wijzig gegevens',
  noProgramsForClassGroupLinked:
    "Geen programma's gelinkt aan deze klas. Wijzig om er toe te voegen.",
  statusImportInProgress: 'Het importeren is nog steeds bezig, dit kan enkele minuten duren.',
  statusImportError: 'Er ging iets mis tijdens het importeren.',
  statusImportSuccess: 'Het importeren is gelukt.',
  importHistory: 'Import geschiedenis',
  dataExport: 'Download gegevens',
  downloadSubmittedExams: 'Download ingediende examens',
  selectClassesForReportTitle: 'Selecteer klassen',
  selectClassesForReportDesc:
    'Selecteer alle klassen die je in het verslag wil zien. Wanneer je een klas selecteert dat nog geen ingediende examens heeft, zal het niet getoond worden in het verslag.',
  selectClassesLabel: 'Selecteer klassen voor het verslag',
  selectAll: 'Selecteer alles',
  warningUpdateExistingTeachersStudentsClasses:
    'Dit zal de aangepaste klasgroepen/leerkrachten/leerlingen veranderen, ben je zeker dat je verder wil gaan?',
  updateLastSmartschoolImport: 'Update laatste import',
  update: 'Update',
  importIsUpdate: 'Deze import is een aanpassing van een vorige import',
  importIsNotUpdate: 'Dit is een nieuwe import',
  externalId: 'Smartschool code',
  includeExamPdfs: 'Voeg toetsen toe',

  // teacher
  thereAreNoLessonsInThisProgram: 'Er zijn geen lessen beschikbaar in dit programma',
  statusChanged: 'Status gewijzigd op',
  changeStatus: 'Wijzig status',
  view: 'Bekijk',
  lessonClosed: 'Les gesloten',
  classVersionPublished: 'Klasversie open',
  homeVersionPublished: 'Thuisversie open',
  chooseStatus: 'Kies status',
  chooseVersion: 'Kies versie',
  classVersion: 'Klasversie',
  homeVersion: 'Thuisversie',
  projectionVersion: 'Projectieversie',
  preparationVersion: 'Handleiding leraar',
  lessonsOf: 'Lessen van {name}',
  hackroomLessonsOf: 'Lessen Hack Room van {name}',
  exam: 'Toets',
  resultsPublished: 'Resultaten gepubliceerd',
  viewMode: 'Weergavemodus',
  submissionDate: 'Inleverdatum',
  questionsAnswered: 'Vragen beantwoord',
  showingResultsOfClass: 'Resultaten van klas',
  openExam: 'Open toets',
  openExamDisabled: 'Student niet ingediend',
  notSubmitted: 'Niet ingezonden',
  examsOf: 'Toetsen van {name}',
  thereAreNoStudentsInThisClassgroup: 'Er zijn geen leerlingen in deze klasgroep',
  youAreNotAssignedToAnyClass:
    'Je bent aan geen enkele klas gekoppeld. Neem contact op met de administrator van je school.',
  availabilityExamInfo: 'Mogen de leerlingen deze toets zien?',
  resultsExamInfo: 'Mogen de leerlingen de resultaten van deze toets zien?',
  classOverviewInfo: 'Open een overzicht van de toetsen van leerlingen',
  examOverviewInfo: 'Open een overzicht van vragen en antwoorden voor deze toets',
  downloadMultipleDocuments: 'Bewaar meerdere bestanden',
  selectMultipleDocuments: 'Selecteer de lessen waarvan je de documenten wil bewaren.',
  download: 'Download',
  file: 'Lesdoc',
  filterStartExercise: 'Is gestart van:',
  noStartExercise: 'Geen startproject geselecteerd',
  ownProject: 'Eigen Project',
  examClosed: 'Toets gesloten',
  examOpened: 'Toets geopend',
  trialExam: 'Oefentoets',
  trialExamInfo:
    'Dit opent de toets en toont meteen de resultaten. Wanneer een leerling deze toets invult kan die meteen de antwoorden controleren.',
  publishResults: 'Publiceer resultaten',
  areYouSureCloseResults: 'Ben je zeker?',
  areYouSureCloseResultsInfo:
    'Ben je zeker dat je de resultaten wil verbergen en de toets niet beschikbaar wil houden? Studenten zullen hun score of antwoorden op de vragen niet meer kunnen raadplegen.',
  givenBy: 'Gegeven door',
  willBeGivenBy: 'Zal gegeven worden door',
  teachOn: 'Geef les op',
  teacherNotFound: 'Leerkracht niet gevonden',
  teacherSubmittedAnswersWarning: 'Als leraar worden je antwoorden op vragen niet opgeslaan',
  showResults: 'Toon resultaten',

  // student
  overview: 'Overzicht',
  submitExam: 'Toets indienen',
  nextQuestion: 'Volgende vraag',
  viewSummary: 'Bekijk samenvatting',
  youHaveNotSumbittedTheExam: 'Je hebt de toets niet ingediend, weet je zeker dat je wilt weggaan?',
  warning: 'Waarschuwing',
  notAllQuestionsAreAnswered:
    'Niet alle vragen worden beantwoord. weet je zeker dat je verder wilt gaan?',
  trialExamYouCanFillItInAndDirectlyViewTheResults:
    'Je kan de toets invullen en direct je resultaten bekijken.',
  resultsArePublishedViewYourResults:
    'Resultaten zijn gepubliceerd. Open de toets en bekijk jouw resultaten.',
  examIsOpenForEditing: 'Toets kan worden ingevuld en ingediend.',
  submittedAt: 'Ingediend op',
  thereAreNoExamsOpenForYou: 'Er zijn nog geen toetsen open voor jou',
  thereAreNoLessonsOpenForYou: 'Er zijn nog geen lessen open voor jou',
  scratchExercise: 'Scratch oefening',
  submit: 'Indienen',
  submitted: 'Ingediend',
  notYetSubmittedHeader: 'Je antwoorden zijn nog niet ingediend',
  notYetSubmittedContent:
    'Je antwoorden zijn nog niet ingediend, wil je deze nu indienen of verwijderen?',

  // editor
  thisIsAnExamplePresentationalComponent: 'Dit is een voorbeeld van een presentatiecomponent',
  thisIsJustAPlaceholderForTheProjectPage:
    'Dit is slechts een tijdelijke aanduiding voor de projectpagina. Je moet de aangemelde gebruiker omleiden naar het juiste onderdeel',

  // error
  somethingWentWrongWhileUpdatingAQuestion: 'Er is iets misgegaan bij het bijwerken van een vraag',
  somethingWentWrongWhileDeletingAQuestion:
    'Er is iets misgegaan bij het verwijderen van een vraag',
  somethingWentWrongWhileUpdatingAnAnswer:
    'Er is iets misgegaan bij het bijwerken van een antwoord',
  somethingWentWrongWhileCreatingAnAnswer: 'Er is iets misgegaan bij het creëren van een antwoord',
  somethingWentWrongWhileCreatingAQuestion: 'Er is iets misgegaan bij het bijwerken van een vraag',
  thereAlreadyIsASchoolWithThisSchoolname:
    'Er is al een school met deze schoolnaam, kies een unieke schoolnaam',
  thereAlreadyIsASchoolWithThisUsernamePrefix:
    'Er is al een school met dit voorvoegsel, kies een uniek voorvoegsel',
  thereAlreadyIsASchooladminWithThisUsername:
    'Er is al een schoolbeheerder met deze gebruikersnaam, kies een unieke gebruikersnaam',
  thestudioContentIdShouldOnlyContainNumbers: 'Het studio id mag alleen cijfers bevatten',
  aLessonShouldAlwaysHaveATitle: 'Een les moet altijd een titel hebben',
  aLessonShouldAlwaysBeLinkedToAnLPFLesson: 'Een les moet altijd worden gekoppeld aan een LPF-les',
  thereShouldBeDefaultPublishStatusSelected:
    'Er moet een standaard publicatiestatus zijn geselecteerd',
  thereAlreadyIsAUserWithThisUsername:
    'Er is al een gebruiker met deze gebruikersnaam, kies een unieke gebruikersnaam',
  selectAtLeastOneProgram: 'Selecteer minimaal één programma',
  thereAreNoMoreProgramsAvailable: "Er zijn geen programma's beschikbaar",
  thereAlreadyIsASchoolWithThisName: 'Er is al een school met deze naam, kies een unieke naam',
  thereAlreadyIsAClassWithThisName: 'Er is al een klas met deze naam, kies een unieke klassenaam',
  thereIsAnUnknownError: 'Er is een onbekende fout, probeer het opnieuw',
  somethingWentWrong: 'Er is iets mis gegaan, probeer het opnieuw',
  somethingWentWrongGoBack: 'Er is iets misgegaan. Ga terug en probeer het opnieuw',
  thisSmartschoolURLIsAlreadyUsed: 'Deze Smartschool-URL is al in gebruik, kies een unieke URL',
  somethingWentWrongWhileShowingTheExercises:
    'Er is iets misgegaan bij het tonen van de oefeningen, probeer het opnieuw',
  theFileCannotBeOpened_Name: 'Het bestand {name} kan niet worden geopend',
  theSmartSchoolImportFailedBecauseThereWereDuplicatedClassgroupNames:
    'Het importeren van Smartschool is mislukt omdat er dubbele klassengroepen waren. Zorg ervoor dat alle namen van de klassengroepen uniek zijn',
  theSmartSchoolImportFailedBecauseNoStudentsWereImported:
    'Het importeren van Smartschool is mislukt omdat er geen leerlingen werden geselecteerd.',
  theSmartSchoolImportFailedBecauseNoTeachersWereImported:
    'Het importeren van Smartschool is mislukt omdat er geen leerkrachten werden geselecteerd.',
  smartschoolAddTeacherError:
    'U hoeft geen nieuwe docenten meer toe te voegen in het FTRPRF-platform. Wanneer een gebruiker wordt toegevoegd in Smartschool, wordt deze de volgende dag toegevoegd aan het FTRPRF-platform.',
  smartschoolAddStudentError:
    'U hoeft geen nieuwe studenten meer toe te voegen in het FTRPRF-platform. Wanneer een gebruiker wordt toegevoegd in Smartschool, wordt deze de volgende dag toegevoegd aan het FTRPRF-platform.',

  // errorpage
  oops: 'Oeps',
  errorSomethingWentWrong: 'Er ging iets mis',
  goBack: 'Ga terug',
  toTheHomePage: 'naar de homepagina',
  youAreNotAuthorizedForThisPage: 'je hebt geen toegang tot deze pagina',
  pacmanDidNotUnderstandYourMessageEither: 'Pacman heeft jouw bericht ook niet begrepen',
  woot: 'Wat',

  // header
  headerAdminSchools: 'Scholen',
  headerAdminPrograms: "Programma's",
  headerAdminExams: 'Toetsen',
  headerAdminSchoolYears: 'Schooljaren',
  headerSchoolAdminClasses: 'Klassen',
  headerSchoolAdminStudents: 'Leerlingen',
  headerSchoolAdminStudentsTeachers: 'Leerkrachten',
  headerSchoolAdminStudentsPrograms: "Programma's",
  headerSchoolAdminStudentsGeneral: 'Algemeen',
  headerSchoolAdminExercises: 'Codeer',
  headerTeacherStudents: 'Leerlingen',
  headerTeacherLessons: 'Leer',
  headerTeacherExams: 'Test',
  headerTeacherExercises: 'Codeer',
  headerStudentLessons: 'Leer',
  headerStudentExams: 'Test',
  headerStudentExercises: 'Codeer',
  headerHackroom: 'Hack Room',

  // shared
  FTRPRF: 'FTRPRF',
  yes: 'Ja',
  no: 'Nee',
  students: 'Leerlingen',
  schools: 'Scholen',
  resetPassword: 'Wachtwoord opnieuw instellen',
  delete: 'Verwijder',
  edit: 'Wijzig',
  program: 'Programma',
  sureYouWantToDelete_Lesson: 'Zeker dat je {lesson} wilt verwijderen?',
  lessons: 'Lessen',
  content: 'Content',
  lessonsHackroom: 'Hack Room',
  programName: 'Programma naam',
  deleteLesson: 'Les verwijderen',
  editLesson: 'Les wijzigen',
  addLesson: 'Les toevoegen',
  addContent: 'Content toevoegen',
  editContent: 'Content wijzigen',
  deleteContent: 'Content verwijderen',
  contentType: 'Content type',
  addProgram: 'Programma toevoegen',
  programType: 'Het programmatype:',
  standard: 'Standaard',
  hackroom: 'Hack Room',
  back: 'Terug',
  name: 'Naam',
  exams: 'Toetsen',
  exercises: 'Oefeningen',
  availability: 'Beschikbaarheid',
  results: 'Resultaten',
  programs: "Programma's",
  title: 'Titel',
  sequence: 'Volgorde',
  cancel: 'Annuleren',
  save: 'Opslaan',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  username: 'Gebruikersnaam',
  accept: 'Accepteren',
  eulaDecline: 'Nee, ik ga niet akkoord',
  eulaAccept: 'Ja, ik ga akkoord',
  endUserlicenseAgreement: 'Gebruikersvoorwaarden',
  endUserlicenseAgreementAccepted: 'Gebruikersvoorwaarden geaccepteerd',
  changePassword: 'Wijzig wachtwoord',
  changePasswordInfo: 'Je zal je opnieuw moeten aanmelden hierna.',
  changePasswordRequired: 'Je moet eerst je wachtwoord wijzigen om toegang te krijgen.',
  admin: 'Beheerder',
  schooladmin: 'Beheerder',
  schoolteacher: 'Leerkracht',
  teacher: 'Leerkracht',
  student: 'Leerling',
  schoolstudent: 'Leerling',
  role: 'Rol',
  at: 'om',
  inTotal: 'in totaal',
  confirmwithYourOwnPasswordToChangeThis:
    'Bevestig met je eigen wachtwoord om dit te kunnen wijzigen',
  yourPasswordIsIncorrect: 'Jouw wachtwoord is onjuist',
  yourPassword: 'Jouw wachtwoord',
  password: 'Wachtwoord',
  confirm: 'Bevestigen',
  passwordReset: 'Wachtwoord opnieuw ingesteld',
  theNewPasswordForIs_Name_Password: 'Het nieuwe wachtwoord voor {name} is: {password}',
  forUserWithUsername_Name_Username: 'Voor gebruiker {name} met gebruikersnaam {username}',
  resetStudentPassword: 'Wijzig wachtwoord leerling',
  currentPassword: 'Huidig wachtwoord',
  newPassword: 'Nieuw wachtwoord',
  confirmPassword: 'Wachtwoord bevestigen',
  confirmNewPassword: 'Nieuw wachtwoord bevestigen',
  currentPasswordAndNewPasswordCannotBeEqual:
    'Huidig wachtwoord en nieuw wachtwoord kunnen niet gelijk zijn',
  newPasswordAndConfirmPasswordAreNotEqual:
    'Nieuw wachtwoord en wachtwoord bevestigen zijn niet gelijk',
  wrongCurrentPassword: 'Fout huidig wachtwoord',
  revokeEULA: 'Akkoord intrekken',
  revokeEULAWillDisableAccess:
    'Als u niet meer akkoord gaat met de gebruikersvoorwaarden, dan zijn we verplicht toegang tot het platform te weigeren totdat deze opnieuw wordt geaccepteerd. Bent u zeker dat u wil doorgaan?',
  thereAreNoOpenForYou_Name: 'Er staan nog ​​geen {name} open voor jou',
  continueWithPreviouslyStartedExercise: 'Verder gaan met eerder gestarte oefening',
  continueWhereYouLastEnded_Exercise:
    'Wil je verder gaan waar je de laatste keer bent geëindigd met oefening: {exercise}, of wil je opnieuw beginnen?',
  ifYouStartAgainYourPreviousProgressWillBeSaved:
    'Als je opnieuw begint wordt je vorige voortgang behouden en kan je deze altijd via je profielpagina openen.',
  startOver: 'Begin opnieuw',
  continue: 'Ga verder',
  exercise: 'Oefening',
  myExercises: 'Mijn oefeningen',
  addExercise: 'Oefening toevoegen',
  youHaveNoExercisesYet: 'Je hebt nog geen codeeroefeningen gemaakt',
  openExercise: 'Oefening openen',
  makeCopy: 'Maak kopie',
  lastModified: 'Laatst gewijzigd',
  correctAnswerExplanation: 'Uitleg van het juiste antwoord',
  questions: 'Vragen',
  chooseALocalFile: 'Kies een lokaal bestand',
  enterProgramName: 'Geef naam programma',
  downloadExercise: 'Download oefening',
  nameExercise: 'Naam oefening',
  reEnterYourOwnPasswordToIdentifyYourselfAsSchoolAdministrator:
    'Voer om veiligheidsredenen jouw eigen wachtwoord opnieuw in om jezelf als schoolbeheerder te identificeren',
  hello: 'Hallo',
  loggedInAs: 'Ingelogd als',
  goBackToAdminMode: 'Ga terug naar de beheerdersmodus',
  switchToTeacherMode: 'Schakel naar de docentmodus',
  switchToSchooladminMode: 'Schakel naar de Schooladminmodus',
  profile: 'Profiel',
  help_videos: "Hulpvideo's",
  help: 'Hulp',
  logOut: 'Afmelden',
  areYouSureYouWantToLeaveThePage:
    'Weet je zeker dat je de pagina wil verlaten? Alle wijzigingen die niet zijn opgeslagen zullen verloren gaan.',
  decline: 'Afwijzen',
  yourProfile: 'Jouw profiel',
  sad: 'Jammer',
  tryAgain: 'Probeer opnieuw',
  wellDone: 'Goed zo',
  further: 'Verder',
  slideInformation: 'Slide informatie',
  home: 'Thuis',
  projection: 'Projectie',
  deleteFailed: 'Verwijderen mislukt',
  deleteSuccess: 'Verwijderen geslaagd',
  updateFailed: 'Aanpassen mislukt',
  updateSuccess: 'Wijzigingen succesvol doorgevoerd!',
  deleteExercise: 'Oefening verwijderen',
  deleteExerciseMessage_Title: 'Weet u zeker dat u {title} wilt verwijderen?',
  thisFieldIsRequired: 'Dit veld is vereist',
  oauth_refreshed:
    'Je bent opnieuw ingelogd. Ga terug naar het vorige tabblad en ga daar verder met je werk.',
  loginInProgress: 'We proberen je in te loggen...',
  signIn: 'Aanmelden',
  signInSmartschool: 'Aanmelden met SmartSchool',
  signInTeams: 'Aanmelden met Teams',
  codecosmos: 'CodeCosmos',
  or: 'of',
  eAcademy: 'e-Academy voor vooruitkijkers',
  language: 'Taal',
  loading: 'Laden...',
  nameNewScratchProject: 'Mijn Project',
  openStats: 'Bekijk statistieken over deze {content}',
  openStatsDisabled: 'Statistieken over deze {content} bekijken, is niet mogelijk tot {trigger}',
  studentExamIsSubmitted: 'een leerling deze toets heeft ingediend.',
  schoolInactive: 'Je school is niet actief',
  schoolInactiveMessage:
    'Je school staat niet op actief, dit wil zeggen dat je zal uitgelogd worden en dat je het platform niet meer kan gebruiken tot je school weer actief staat.',
  whatAreYouLookingFor: 'Wat zoek je?',
  noMatchFound: 'Geen resultaten gevonden',
  lastLogin: 'Laatst ingelogd',
  on: 'op',
  areYouSure: 'Ben je zeker?',
  teacherManual: 'Leraren handleiding',
  manualEmpty: 'Er is geen info beschikbaar voor deze slide',
  closeSlides: 'Slides afsluiten',
  connectedStudents: 'Verbonden leerlingen',
  connectToApp: 'Verbind met de app',
  searchName: 'Zoek op naam',
  guests: 'Gasten',
  startQuiz: 'Start quiz',
  openForGuests: 'Open voor gasten',
  guestCode: 'Gast code',
  slidesOverview: 'Slides overzicht',
  settings: 'Instellingen',
  givenAnswers: 'Gegeven antwoorden',
  feedback: 'Feedback',
  feedbackExplanation: 'Iets mis in de slide? Laat je feedback hier achter.',
  quizConnectExplanation:
    'Open hier je verbinding met de app en beheer de leerlingen die deelnemen aan de quiz.',
  zoomExplanation: 'Gebruik + en - , om de slides in en uit te zoomen.',
  startConnectingStudents: 'Start verbinden leerlingen',
  sendQuestion: 'Verstuur vraag',
  sendEvaluation: 'Verstuur oplossing',
  showGivenAnswers: 'Laat gegeven antwoorden zien',
  hideGivenAnswers: 'Verberg gegeven antwoorden',
  answered: 'Geantwoord: ',
  noSlidesFound: 'OEPS, ER ZIJN GEEN SLIDES GEVONDEN!',

  //  On sign in again
  successExercise: 'Perfect, je bent opnieuw ingelogd. Probeer je oefening nog eens op te slaan!',
  successOther: 'Je bent opnieuw ingelogd. Nu kan je verder werken.',
  signInAgainFailed: 'Je gebruikersnaam en/of wachtwoord was fout! Probeer opnieuw ...',
  signInAgainMessageFTRPRF:
    'Je bent al uitgelogd! Om je werk niet te verliezen, kan je hier opnieuw inloggen. Als je op Cancel drukt, blijf je uitgelogd en ben je je oefening kwijt!',
  signInAgainMessageSmartSchool:
    'Je bent al uitgelogd! Er zal een nieuw tabblad openen waarin je opnieuw kan inloggen. Als je op Cancel drukt, blijf je uitgelogd en ben je je oefening kwijt!',
  signInAgainHeader: 'Opnieuw inloggen',

  // Dashboard statistics
  stats_classExam_overal_header: 'Algemeen',
  stats_classExam_overal_expl:
    'Linksboven: gemiddeld aantal punten, linksonder: gemiddeld percentage, {br}Midden: percentage geslaagde leerlingen, {br}Rechtsboven: mediaan van de punten, rechtsonder: mediaan van het percentage.',
  stats_classExam_bestWorst_header: 'Eerste en laatste drie leerlingen',
  stats_classExam_bestWorst_expl:
    'Links: eerste drie leerlingen, {br}Rechts: laatste drie leerlingen',
  stats_classExam_byQuestion_header: 'Resultaat per vraag',
  stats_classExam_byQuestion_expl:
    'X-as (horizontaal): nummer van de vraag, y-as (verticaal): aantal leerlingen, {br}Blauwe balk: aantal leerlingen met correct antwoord, {br}Rode bol: aantal leerlingen in de klas',
  stats_classExam_byStudent_header: 'Resultaat per leerling',
  stats_classExam_byStudent_expl:
    'X-as (horizontaal): naam van de leerling, y-as (verticaal): aantal vragen, {br}Blauwe balk: aantal correcte vragen, {br}Rode bol: aantal beantwoorde vragen',

  exams_showing_all_schoolyears: 'Examens van alle schooljaren worden getoond',

  // Edit multiple exams
  exams_edit_all: 'Pas selectie aan',
  exams_edit_all_header: 'Pas geselecteerde examens aan',
  exams_edit_action: 'Pas aan',
  exams_set_active: 'Maak beschikbaar',
  edit_schoolYear_placeholder: 'Pas de schooljaren niet aan',
  edit_isActive_noChange: 'Pas de beschikbaarheden niet aan',

  // Copy mutliple exams
  exams_copy_all: 'Kopieer selectie',
  exams_copy_all_header: 'Kopieer geselecteerde examens',
  exams_copy_action: 'Kopieer',
  copy_linkedProgram:
    "Wanneer geen programma gespecifieerd is, worden de huidige gelinkte programma's overgenomen",
  copy_linkedProgram_placeholder: "Selecteer huidig gelinkte programma's",

  // viewModes
  'view-modes.teacher_manual': 'Handleiding leraar',
  'view-modes.projection': 'Projectie',
  'view-modes.selfstudy': 'Zelfstudie',
  'view-modes.classical': 'Klassikaal',
};
