import { logOut } from '../actions/auth';

const logoutSessionExpired = (store) => (next) => (action) => {
  if (
    action.error &&
    (action.error.code === 'SESSION_USER_EXPIRED' ||
      action.error.code === 'SESSION_TOKEN_NOT_FOUND' ||
      action.error.code === 'SESSION_TOKEN_EXPIRED' ||
      action.error.code === 'SESSION_TOKEN_MISSING_OR_INVALID')
  ) {
    store.dispatch(logOut());
  } else {
    next(action);
  }
};

export default logoutSessionExpired;
