/* eslint-disable no-case-declarations */
import { merge, omit, assign, pickBy, some, includes, without } from 'lodash-es';
import {
  FETCH_CLASSGROUPS_SUCCESS,
  FETCH_CLASSGROUPS_START,
  FETCH_CLASSGROUPS_ERROR,
  DELETE_CLASSGROUP_START,
  DELETE_CLASSGROUP_SUCCESS,
  DELETE_CLASSGROUP_ERROR,
  UPDATE_CLASSGROUP_START,
  UPDATE_CLASSGROUP_SUCCESS,
  UPDATE_CLASSGROUP_ERROR,
  FETCH_CLASSGROUP_BY_ID_START,
  FETCH_CLASSGROUP_BY_ID_SUCCESS,
  FETCH_CLASSGROUP_BY_ID_ERROR,
  LOGIN_SUCCES,
  GET_LOGGEDIN_USER_FROM_JWT_SUCCES,
  FETCH_STUDENTS_BY_SCHOOL_SUCCESS,
  CLEAR_CLASSGROUPS,
  FETCH_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPPROGRAMS_SUCCESS,
  UPDATE_CLASSGROUPPROGRAM_SUCCESS,
  FETCH_CLASSGROUPEXAM_SUCCESS,
  ADD_STUDENT_SUCCESS,
  DELETE_STUDENT_SUCCESS,
  ADD_CLASSGROUP_START,
  ADD_CLASSGROUP_SUCCESS,
  ADD_CLASSGROUP_ERROR,
  IMPORT_SMARTSCHOOL_GROUPS_SUCCESS,
} from '../types';
import * as stateTypes from '../../constants/stateTypes';
import initialState from './initialState';

function classGroups(state = initialState.classGroups, action) {
  switch (action.type) {
    case FETCH_CLASSGROUPS_START:
    case DELETE_CLASSGROUP_START:
    case UPDATE_CLASSGROUP_START:
    case FETCH_CLASSGROUP_BY_ID_START:
    case ADD_CLASSGROUP_START:
      return merge({}, state, { state: stateTypes.LOADING, error: {} });

    case FETCH_CLASSGROUPS_ERROR:
    case DELETE_CLASSGROUP_ERROR:
    case FETCH_CLASSGROUP_BY_ID_ERROR:
    case ADD_CLASSGROUP_ERROR:
    case UPDATE_CLASSGROUP_ERROR:
      return assign(
        {},
        {
          data: state.data,
          state: action.handled ? stateTypes.LOADED_SUCCESSFUL : stateTypes.ERROR,
          error: action.handled ? {} : action.error,
        },
      );

    case FETCH_CLASSGROUPS_SUCCESS:
      return assign({}, state, {
        data: action.data.entities.classGroups,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case FETCH_STUDENTS_BY_SCHOOL_SUCCESS:
    case FETCH_CLASSGROUP_BY_ID_SUCCESS:
    case UPDATE_CLASSGROUPPROGRAM_SUCCESS:
    case FETCH_CLASSGROUPPROGRAMS_SUCCESS:
    case FETCH_CLASSGROUPPROGRAM_SUCCESS:
    case ADD_CLASSGROUP_SUCCESS:
    case FETCH_CLASSGROUPEXAM_SUCCESS:
      return merge({}, state, {
        data: action.data.entities.classGroups,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_CLASSGROUP_SUCCESS:
      return assign({}, state, {
        data: omit(state.data, action.data),
        state: stateTypes.DELETE_SUCCESSFUL,
        error: {},
      });

    case ADD_STUDENT_SUCCESS:
      if (action.data.classGroupId) {
        const newAddStudentData = state.data;
        newAddStudentData[action.data.classGroupId].students.push(action.data.result);
        return assign({}, state, {
          data: newAddStudentData,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return merge({}, state, {
        data: state.data,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case DELETE_STUDENT_SUCCESS:
      if (action.data.classGroupId) {
        const newDeleteStudentData = state.data;
        newDeleteStudentData[action.data.classGroupId].students = without(
          newDeleteStudentData[action.data.classGroupId].students,
          action.data.userId,
        );
        return assign({}, state, {
          data: newDeleteStudentData,
          state: stateTypes.LOADED_SUCCESSFUL,
          error: {},
        });
      }
      return merge({}, state, {
        data: state.data,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case IMPORT_SMARTSCHOOL_GROUPS_SUCCESS:
      return assign({}, { data: {}, state: stateTypes.LOADED_SUCCESSFUL, error: {} });

    case CLEAR_CLASSGROUPS:
      return assign({}, state, {
        data: pickBy(state.data, (classGroup) => {
          return some(classGroup.classGroupPrograms, (program) => {
            return includes(action.data.classGroupProgramIds, program);
          });
        }),
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });

    case LOGIN_SUCCES:
    case GET_LOGGEDIN_USER_FROM_JWT_SUCCES:
    case UPDATE_CLASSGROUP_SUCCESS: {
      return assign({}, state, {
        data: action.data.entities.classGroups,
        state: stateTypes.LOADED_SUCCESSFUL,
        error: {},
      });
    }

    default:
      return state;
  }
}

export default classGroups;
