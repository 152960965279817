export const FETCH_EXAM_START = 'FETCH_EXAM_START';
export const FETCH_EXAM_SUCCESS = 'FETCH_EXAM_SUCCESS';
export const FETCH_EXAM_ERROR = 'FETCH_EXAM_ERROR';

export const POST_EXAM_START = 'POST_EXAM_START';
export const POST_EXAM_SUCCESS = 'POST_EXAM_SUCCESS';
export const POST_EXAM_ERROR = 'POST_EXAM_ERROR';

export const UPDATE_EXAM_START = 'UPDATE_EXAM_START';
export const UPDATE_EXAM_SUCCESS = 'UPDATE_EXAM_SUCCESS';
export const UPDATE_EXAM_ERROR = 'UPDATE_EXAM_ERROR';

export const UPDATE_EXAMS_START = 'UPDATE_EXAMS_START';
export const UPDATE_EXAMS_SUCCESS = 'UPDATE_EXAMS_SUCCESS';
export const UPDATE_EXAMS_ERROR = 'UPDATE_EXAMS_ERROR';

export const FETCH_EXAMS_START = 'FETCH_EXAMS_START';
export const FETCH_EXAMS_SUCCESS = 'FETCH_EXAMS_SUCCESS';
export const FETCH_EXAMS_ERROR = 'FETCH_EXAMS_ERROR';

export const DELETE_EXAM_START = 'DELETE_EXAM_START';
export const DELETE_EXAM_SUCCESS = 'DELETE_EXAM_SUCCESS';
export const DELETE_EXAM_ERROR = 'DELETE_EXAM_ERROR';

export const COPY_EXAM_START = 'COPY_EXAM_START';
export const COPY_EXAM_SUCCESS = 'COPY_EXAM_SUCCESS';
export const COPY_EXAM_ERROR = 'COPY_EXAM_ERROR';

export const COPY_EXAMS_START = 'COPY_EXAMS_START';
export const COPY_EXAMS_SUCCESS = 'COPY_EXAMS_SUCCESS';
export const COPY_EXAMS_ERROR = 'COPY_EXAMS_ERROR';

export const GET_EXAM_OVERVIEW_START = 'GET_EXAM_OVERVIEW_START';
export const GET_EXAM_OVERVIEW_SUCCESS = 'GET_EXAM_OVERVIEW_SUCCESS';
export const GET_EXAM_OVERVIEW_ERROR = 'GET_EXAM_OVERVIEW_ERROR';
